export const allowedExtensions = [
  ".pdf",
  ".txt",
  ".jpg",
  ".jpeg",
  ".jpe",
  ".png",
  ".heif",
  ".heic",
];

export const defaultUscisAddresses = {
  O_1A: "USCIS Texas Service Center\nAttn: I-129 O Premium Processing\n6046 N Belt Line Rd. STE 907\nIrving, TX 75038-0001",
  EB_1A:
    "USCIS\nAttn: I-140 (P.O. Box 660128)\n2501 S. State Hwy. 121 Business\nSuite 400\nLewisville, TX 75067-8003",
  EB_2_NIW:
    "USCIS\nAttn: I-140 (P.O. Box 660128)\n2501 S. State Hwy. 121 Business\nSuite 400\nLewisville, TX 75067-8003",
  O_1A_RFE:
    "USCIS TSC\nAttn: I-129 PP O RFE/NOIT/NOIR/NOID RESPONSE \n6046 N Belt Line Rd. STE 907\nIrving, TX 75038-0022",
  TN: "U.S Department of Homeland Security\nUSMCA Specialist\nPort of Entry / Preclearance Inspections Station",
  H_1B_TRANSFER:
    "USCIS\nAttn: Premium I-129 H-1B (Box 21509)\n2108 E. Elliot Rd.\nTempe, AZ 85284-1806",
};

export const formatEnum = (enumValue: string) => {
  return enumValue
    .split("_")
    .map((word) => word[0].toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");
};

export const isMissingOrEmpty = (value?: unknown) => {
  return value == null || value.toString().trim() == "";
};

const hasElementType = typeof Element !== "undefined";
const hasMap = typeof Map === "function";
const hasSet = typeof Set === "function";
const hasArrayBuffer =
  typeof ArrayBuffer === "function" && !!ArrayBuffer.isView;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const deepEquals = (a: any, b: any) => {
  if (a === b) return true;

  if (a && b && typeof a == "object" && typeof b == "object") {
    if (a.constructor !== b.constructor) return false;

    let length, i;
    if (Array.isArray(a)) {
      length = a.length;
      if (length != b.length) return false;
      for (i = length; i-- !== 0; ) if (!deepEquals(a[i], b[i])) return false;
      return true;
    }

    let it;
    if (hasMap && a instanceof Map && b instanceof Map) {
      if (a.size !== b.size) return false;
      it = a.entries();
      while (!(i = it.next()).done) if (!b.has(i.value[0])) return false;
      it = a.entries();
      while (!(i = it.next()).done)
        if (!deepEquals(i.value[1], b.get(i.value[0]))) return false;
      return true;
    }

    if (hasSet && a instanceof Set && b instanceof Set) {
      if (a.size !== b.size) return false;
      it = a.entries();
      while (!(i = it.next()).done) if (!b.has(i.value[0])) return false;
      return true;
    }

    if (hasArrayBuffer && ArrayBuffer.isView(a) && ArrayBuffer.isView(b)) {
      length = a.byteLength;
      if (length != b.byteLength) return false;
      const dv1 = new Int8Array(a.buffer);
      const dv2 = new Int8Array(b.buffer);
      for (let i = 0; i != a.byteLength; i++)
        if (dv1[i] != dv2[i]) return false;
      return true;
    }

    if (a.constructor === RegExp)
      return a.source === b.source && a.flags === b.flags;
    if (
      a.valueOf !== Object.prototype.valueOf &&
      typeof a.valueOf === "function" &&
      typeof b.valueOf === "function"
    )
      return a.valueOf() === b.valueOf();
    if (
      a.toString !== Object.prototype.toString &&
      typeof a.toString === "function" &&
      typeof b.toString === "function"
    )
      return a.toString() === b.toString();

    const keys = Object.keys(a);
    length = keys.length;
    if (length !== Object.keys(b).length) return false;

    for (i = length; i-- !== 0; )
      if (!Object.prototype.hasOwnProperty.call(b, keys[i])) return false;

    if (hasElementType && a instanceof Element) return false;

    for (i = length; i-- !== 0; ) {
      if (
        (keys[i] === "_owner" || keys[i] === "__v" || keys[i] === "__o") &&
        a.$$typeof
      ) {
        continue;
      }

      if (!deepEquals(a[keys[i]], b[keys[i]])) return false;
    }
    return true;
  }

  return a !== a && b !== b;
};

export const isEqual = (a: unknown, b: unknown) => {
  try {
    return deepEquals(a, b);
  } catch (error: unknown) {
    if (
      ((error as { message?: string }).message || "").match(/stack|recursion/i)
    ) {
      console.warn("react-fast-compare cannot handle circular refs");
      return false;
    }
    throw error;
  }
};
