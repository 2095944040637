import { OnboardingRouteProvider } from "@/providers/onboardingProvider";
import { Spinner } from "@radix-ui/themes";
import { CustomRightSideModal } from "./modal";
import { useMutation, useQuery } from "urql";
import {
  getCompanyMembers,
  getOnboarding,
  submitGroupForReview,
} from "@/lib/queries";
import { Field, SubkeyUploadArea } from "./form";
import {
  CountryAddressInput,
  DateInput,
  Input,
  PhoneNumberInput,
} from "./inputs";
import {
  useOnboardingNodeData,
  useUserContext,
  useWorkspaces,
} from "@/lib/hooks";
import { InputText } from "./typography";
import { VisaClassBadge } from "./visaClassBadge";
import { cn } from "@/lib/cn";
import { Avatar } from "./avatar";
import { CheckCircledIcon, CheckIcon } from "@radix-ui/react-icons";
import { customToast } from "./toast";
import { WarningIcon } from "./icons/warning";
import { Select } from "./select";
import { PhoneNumber } from "react-phone-number-input";
import {
  OnboardingDataProvider,
  useOnboardingData,
} from "@/providers/onboardingDataProvider";
import { CaseStatusTimeline } from "./caseStatusTimeline";
import { CompanyApplicantDashboardContent } from "./dashboard";

const PetitionerRepresentativeSelect = (props: {
  value?: number;
  onChange: (value?: number) => void;
}) => {
  const { value, onChange } = props;

  const { loggedInUserId, userEntity, doUpdateUserEntity } = useUserContext();
  const { selectedWorkspace } = useWorkspaces();

  const parsedWorkspaceId = parseInt(
    selectedWorkspace?.id.split("-")[1] ?? "-1"
  );

  const [{ data }] = useQuery({
    query: getCompanyMembers,
    variables: {
      id: parsedWorkspaceId,
    },
    pause:
      selectedWorkspace?.id == null ||
      parsedWorkspaceId == -1 ||
      selectedWorkspace?.id.startsWith("user"),
  });

  return (
    <div className="p-3 bg-grey-700 rounded-lg">
      <div className="flex flex-col items-center gap-2">
        {data?.getCompanyWorkspace.members.members.map((member) => (
          <div
            key={member.id}
            className={cn(
              "w-full flex flex-col items-center gap-1 py-2 px-3 hover:bg-grey-600 text-sm rounded-md",
              member.id === value && "bg-grey-600",
              member.id !== value && "cursor-pointer",
              "transition-all duration-150"
            )}
            onClick={() => {
              if (member.id === value) return;
              onChange(member.id);
            }}
          >
            <div className="w-full flex flex-row items-center gap-2 text-grey-100">
              <Avatar
                username={member.email.split("@")[0]}
                className="w-4 h-4 rounded-full"
              />
              {member.email}

              <CheckIcon
                className={cn(
                  "w-4 h-4 text-positive ml-auto hidden",
                  "transition-all duration-150",
                  member.id === value && "block"
                )}
              />
            </div>

            <div
              className={cn(
                "flex flex-col w-full hidden pb-1",
                "transition-all duration-150",
                member.id === value && "block"
              )}
            >
              <Field
                label="Legal Name"
                description="We need the legal name of the representative to include in the immigration forms."
                className="pr-0"
              >
                <Input
                  type="text"
                  placeholder={
                    member.id == loggedInUserId
                      ? "Enter your legal name"
                      : "Pending user registration"
                  }
                  onValueChange={(val) => {
                    if (member.id != loggedInUserId) {
                      return;
                    }
                    doUpdateUserEntity("legalName", val);
                  }}
                  disabled={member.id != loggedInUserId}
                  className="w-full"
                  value={
                    member.id == loggedInUserId
                      ? (userEntity?.legalName ?? "")
                      : (member.userEntity?.legalName ?? "")
                  }
                />
              </Field>

              <Field
                label="Role at the company"
                description="We need the role of the representative at the company to include in the immigration forms."
                className="pr-0"
              >
                <Input
                  type="text"
                  placeholder={
                    member.id === loggedInUserId
                      ? "Enter your role"
                      : "Pending user registration"
                  }
                  className="w-full"
                  value={
                    member.id === loggedInUserId
                      ? (userEntity?.role ?? "")
                      : (member.userEntity?.role ?? "")
                  }
                  disabled={member.id != loggedInUserId}
                  onValueChange={(val) => {
                    if (member.id != loggedInUserId) {
                      return;
                    }
                    doUpdateUserEntity("role", val);
                  }}
                />
              </Field>
              <Field
                label="Phone number of the representative"
                description="We need the phone number of the representative to include in the immigration forms."
                className="pr-0"
              >
                {member.id === loggedInUserId && (
                  <PhoneNumberInput
                    value={userEntity?.phoneNumber as PhoneNumber | undefined}
                    onValueChange={(val) =>
                      doUpdateUserEntity("phoneNumber", val)
                    }
                    placeholder="Enter phone number"
                    className="w-full"
                  />
                )}
                {member.id !== loggedInUserId && (
                  <Input
                    type="text"
                    placeholder="Users cannot view/enter other users' phone numbers"
                    className="w-full"
                    disabled
                  />
                )}
              </Field>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

const usStates = [
  { name: "ALABAMA", abbreviation: "AL" },
  { name: "ALASKA", abbreviation: "AK" },
  { name: "AMERICAN SAMOA", abbreviation: "AS" },
  { name: "ARIZONA", abbreviation: "AZ" },
  { name: "ARKANSAS", abbreviation: "AR" },
  { name: "CALIFORNIA", abbreviation: "CA" },
  { name: "COLORADO", abbreviation: "CO" },
  { name: "CONNECTICUT", abbreviation: "CT" },
  { name: "DELAWARE", abbreviation: "DE" },
  { name: "DISTRICT OF COLUMBIA", abbreviation: "DC" },
  { name: "FEDERATED STATES OF MICRONESIA", abbreviation: "FM" },
  { name: "FLORIDA", abbreviation: "FL" },
  { name: "GEORGIA", abbreviation: "GA" },
  { name: "GUAM", abbreviation: "GU" },
  { name: "HAWAII", abbreviation: "HI" },
  { name: "IDAHO", abbreviation: "ID" },
  { name: "ILLINOIS", abbreviation: "IL" },
  { name: "INDIANA", abbreviation: "IN" },
  { name: "IOWA", abbreviation: "IA" },
  { name: "KANSAS", abbreviation: "KS" },
  { name: "KENTUCKY", abbreviation: "KY" },
  { name: "LOUISIANA", abbreviation: "LA" },
  { name: "MAINE", abbreviation: "ME" },
  { name: "MARSHALL ISLANDS", abbreviation: "MH" },
  { name: "MARYLAND", abbreviation: "MD" },
  { name: "MASSACHUSETTS", abbreviation: "MA" },
  { name: "MICHIGAN", abbreviation: "MI" },
  { name: "MINNESOTA", abbreviation: "MN" },
  { name: "MISSISSIPPI", abbreviation: "MS" },
  { name: "MISSOURI", abbreviation: "MO" },
  { name: "MONTANA", abbreviation: "MT" },
  { name: "NEBRASKA", abbreviation: "NE" },
  { name: "NEVADA", abbreviation: "NV" },
  { name: "NEW HAMPSHIRE", abbreviation: "NH" },
  { name: "NEW JERSEY", abbreviation: "NJ" },
  { name: "NEW MEXICO", abbreviation: "NM" },
  { name: "NEW YORK", abbreviation: "NY" },
  { name: "NORTH CAROLINA", abbreviation: "NC" },
  { name: "NORTH DAKOTA", abbreviation: "ND" },
  { name: "NORTHERN MARIANA ISLANDS", abbreviation: "MP" },
  { name: "OHIO", abbreviation: "OH" },
  { name: "OKLAHOMA", abbreviation: "OK" },
  { name: "OREGON", abbreviation: "OR" },
  { name: "PALAU", abbreviation: "PW" },
  { name: "PENNSYLVANIA", abbreviation: "PA" },
  { name: "PUERTO RICO", abbreviation: "PR" },
  { name: "RHODE ISLAND", abbreviation: "RI" },
  { name: "SOUTH CAROLINA", abbreviation: "SC" },
  { name: "SOUTH DAKOTA", abbreviation: "SD" },
  { name: "TENNESSEE", abbreviation: "TN" },
  { name: "TEXAS", abbreviation: "TX" },
  { name: "UTAH", abbreviation: "UT" },
  { name: "VERMONT", abbreviation: "VT" },
  { name: "VIRGIN ISLANDS", abbreviation: "VI" },
  { name: "VIRGINIA", abbreviation: "VA" },
  { name: "WASHINGTON", abbreviation: "WA" },
  { name: "WEST VIRGINIA", abbreviation: "WV" },
  { name: "WISCONSIN", abbreviation: "WI" },
  { name: "WYOMING", abbreviation: "WY" },
];

export const USStateSelect = (props: {
  value?: string;
  onValueChange: (value?: string) => void;
}) => {
  const { value, onValueChange } = props;

  return (
    <Select
      value={value}
      onValueChange={onValueChange}
      placeholder="Select a state"
      options={usStates.map((state) => ({
        value: state.abbreviation,
        label: state.abbreviation,
      }))}
      className="bg-white rounded-lg"
    />
  );
};

const CompanyModalInner = (props: {
  currentOnboardingNodeId: number;
  onboardingId: number;
}) => {
  const { data, doSetData, fetching } = useOnboardingNodeData(
    props.currentOnboardingNodeId
  );
  const worksiteAddress = data?.worksiteAddress?.value ?? {};

  return (
    <div className="flex flex-col gap-4">
      {fetching && <Spinner />}
      {!fetching && data != null && (
        <>
          <Field
            id="signedEmploymentOfferLetter"
            label="Signed Employment Offer Letter"
            description="This document will help us prepare the Employment Verification letter. You can review, comment, and collaborate to ensure alignment on the role title, salary, and other details."
          >
            <SubkeyUploadArea
              onboardingNodeId={props.currentOnboardingNodeId}
              subKey="signedEmploymentOfferLetter"
            />
          </Field>

          <Field
            id="intendedRoleTitle"
            label="Intended Role Title"
            description="This is the title the beneficiary will be assigned upon approval."
          >
            <Input
              type="text"
              placeholder="e.g. Software Engineer"
              value={data.intendedRoleTitle?.value as string}
              onValueChange={(val) => doSetData("intendedRoleTitle", val)}
            />
          </Field>

          <Field
            id="intendedStartDate"
            label="Intended Start Date"
            description="This is the start date the beneficiary will be assigned upon approval."
          >
            <DateInput
              value={
                data.intendedStartDate?.value != null
                  ? new Date(data.intendedStartDate.value)
                  : undefined
              }
              onValueChange={(val) =>
                doSetData("intendedStartDate", val?.toISOString())
              }
            />
          </Field>

          <div className="flex flex-col bg-grey-700 rounded-lg pt-1.5 pb-4 pl-3 mr-12">
            <div className="flex flex-col gap-1 pt-3 pb-3 pl-1.5">
              <InputText className="text-grey-300 text-[12px]">
                What is the worksite address?
              </InputText>
              <div id="field-worksiteAddress" className="flex flex-col gap-2">
                <Field label="Address" className="pr-4">
                  <Input
                    placeholder="123 Main St"
                    type="text"
                    value={worksiteAddress.street ?? ""}
                    onValueChange={(val) =>
                      doSetData("worksiteAddress", {
                        ...worksiteAddress,
                        street: val,
                      })
                    }
                    autoComplete={"street-address"}
                  />
                </Field>
                <Field label="City" className="pr-4">
                  <Input
                    placeholder="San Francisco"
                    type="text"
                    value={worksiteAddress.city ?? ""}
                    onValueChange={(val) =>
                      doSetData("worksiteAddress", {
                        ...worksiteAddress,
                        city: val,
                      })
                    }
                    autoComplete={"address-level2"}
                  />
                </Field>
                <div className="flex flex-row gap-2 w-full">
                  <Field label="State" className="pr-4">
                    <USStateSelect
                      value={worksiteAddress.state ?? ""}
                      onValueChange={(val) =>
                        doSetData("worksiteAddress", {
                          ...worksiteAddress,
                          state: val,
                        })
                      }
                    />
                  </Field>
                  <Field label="ZIP / Postal Code" className="pr-4">
                    <Input
                      placeholder="94102"
                      type="text"
                      value={worksiteAddress.zip ?? ""}
                      onValueChange={(val) =>
                        doSetData("worksiteAddress", {
                          ...worksiteAddress,
                          zip: val,
                        })
                      }
                      autoComplete={"postal-code"}
                    />
                  </Field>
                </div>
                <Field label="Country" className="pr-4">
                  <CountryAddressInput
                    value={worksiteAddress.country}
                    onValueChange={(val) =>
                      doSetData("worksiteAddress", {
                        ...worksiteAddress,
                        country: val as string,
                      })
                    }
                  />
                </Field>
              </div>
            </div>
          </div>

          <Field
            id="petitionerRepresentative"
            label="Petitioner Representative"
            description="This is the representative who will be signing the government forms on behalf of the company."
          >
            <PetitionerRepresentativeSelect
              value={data.petitionerRepresentative?.value}
              onChange={(val) => {
                doSetData("petitionerRepresentative", val);
              }}
            />
          </Field>
        </>
      )}
    </div>
  );
};

const CompanyDashboardInner = () => {
  const { caseStatus } = useOnboardingData();
  return (
    <div className="w-full flex flex-col pr-12 gap-2">
      <div className="bg-grey-700 rounded-lg pt-4 pl-4">
        <CaseStatusTimeline
          currentCaseStatus={caseStatus}
          classname="mr-1"
          scrollable
        />
      </div>
      <CompanyApplicantDashboardContent />
    </div>
  );
};

const CompanyDashboard = (props: { onboardingId: number }) => {
  return (
    <OnboardingDataProvider onboardingId={props.onboardingId}>
      <CompanyDashboardInner />
    </OnboardingDataProvider>
  );
};

export const CompanyOnboardingModal = (props: {
  open: boolean;
  setOpen: (value: boolean) => void;
  onNext?: () => void;
  onPrevious?: () => void;
  nextAllowed?: boolean;
  previousAllowed?: boolean;

  companyOnboardingId: number;
  onboardingNodeId?: number;
  applicantName: string;
}) => {
  const [{ data, fetching, error }] = useQuery({
    query: getOnboarding,
    variables: {
      id: props.companyOnboardingId,
    },
    requestPolicy: "cache-and-network",
  });

  const submitGroupForReviewMutation = useMutation(submitGroupForReview)[1];

  const doSubmitGroupForReview = async () => {
    if (data == null) return;

    const { error } = await submitGroupForReviewMutation({
      input: {
        onboardingId: data.onboarding.id,
        group: "employer_documents",
      },
    });

    if (error != null) {
      customToast(
        "Failed to submit for review",
        <WarningIcon />,
        error.message
      );
      return;
    }

    customToast(
      "This step has been submitted",
      <CheckCircledIcon className="w-[16px] h-[16px] text-positive" />,
      "The Lighthouse team will review it and either add it to your visa application or inform you if any changes are needed."
    );
  };

  return (
    <CustomRightSideModal
      open={props.open}
      onOpenChange={props.setOpen}
      onNext={props.onNext}
      onPrevious={props.onPrevious}
      title={
        <div className="flex flex-row items-center gap-2">
          {data == null && !fetching && <span>Could not load onboarding</span>}
          {data != null && (
            <>
              <Avatar
                username={props.applicantName.split("@")[0]}
                className="w-4 h-4 rounded-full"
              />
              {props.applicantName}
              <VisaClassBadge visaClass={data.onboarding.visaClass} />
            </>
          )}
        </div>
      }
      nextAllowed={props.nextAllowed}
      previousAllowed={props.previousAllowed}
      onSubmit={doSubmitGroupForReview}
      submissionStatus={
        data?.onboarding.groups.find((x) => x.id === "employer_documents")
          ?.status
      }
    >
      {fetching && <Spinner />}
      {(!fetching && data == null) ||
        (error != null && (
          <div className="w-full h-full flex flex-col items-center justify-center">
            Error loading onboarding data for node {props.companyOnboardingId}.
            Please contact support@lighthousehq.com for assistance.
          </div>
        ))}
      {!fetching && data != null && props.onboardingNodeId != null && (
        <>
          <CompanyDashboard onboardingId={props.companyOnboardingId} />

          {/* Onboarding Node  */}
          <OnboardingRouteProvider
            onboardingId={props.companyOnboardingId}
            currentGroup="employer_documents"
            currentNodeId={props.onboardingNodeId}
          >
            <CompanyModalInner
              currentOnboardingNodeId={props.onboardingNodeId}
              onboardingId={props.companyOnboardingId}
              key={`company-onboarding-${props.companyOnboardingId}-${props.onboardingNodeId}`}
            />
          </OnboardingRouteProvider>
        </>
      )}
    </CustomRightSideModal>
  );
};
