import { useAuth } from "@clerk/clerk-react";
import { createRootRouteWithContext, Outlet } from "@tanstack/react-router";
import { Client } from "urql";

export const Route = createRootRouteWithContext<{
  auth: ReturnType<typeof useAuth>;
  client: Client;
}>()({
  component: () => (
      <Outlet />
  ),
});
