import { Field, OnboardingForm } from "@/components/form";
import { Checkbox, ListTextInput, TextArea } from "@/components/inputs";
import { useOnboardingNodeData } from "@/lib/hooks";
import { useOnboarding } from "@/providers/onboardingProvider";
import { URLReview } from "@/components/urlReview";
import { CreateAdditionalWorkExperienceNodeOption } from "@/components/CreateAdditionalWorkExperienceNodeOption";

export const Authorship = () => {
  const { currentOnboardingNodeId, groupStatus } = useOnboarding();
  const { data, doSetData } = useOnboardingNodeData(
    currentOnboardingNodeId ?? -1
  );

  return (
    <OnboardingForm heading="Your work experience">
      <p className="text-[12px] text-grey-300 mr-12 mt-2">
        This is evidence that you have published{" "}
        <span className="text-grey-200">
          peer-reviewed scholarly articles or authored an article
        </span>{" "}
        in a major media or trade publication.
      </p>
      {(data.publications?.value ?? []).length > 0 && (
        <Field>
          <URLReview
            title="Good news! We've found that you've published some scholarly articles."
            description="Review and let us know if these are correct."
            links={data.publications?.value ?? []}
            updateLinks={(links) => doSetData("publications", links)}
          />
        </Field>
      )}
      <Checkbox
        label="I have other profiles with publications I've authored that I'd like Lighthouse to review."
        checked={data.hasOtherPublications?.value ?? false}
        onCheckedChange={(value) => {
          doSetData("hasOtherPublications", value);
        }}
        disabled={groupStatus === "submitted_for_review"}
        className="mr-12"
      />

      {data.hasOtherPublications?.value && (
        <ListTextInput
          value={data.otherPublications?.value}
          onValueChange={(value) => doSetData("otherPublications", value)}
          placeholder="https://scholar.google.com/article"
          disabled={groupStatus === "submitted_for_review"}
        />
      )}

      <Field
        id="additionalComments"
        label="Additional comments"
        description="If you have any additional comments, that you think will be useful for us working on this criteria, please add them here."
      >
        <TextArea
          value={data.additionalComments?.value as string}
          onChange={(val) => doSetData("additionalComments", val)}
          className="w-full"
        />
      </Field>

      {currentOnboardingNodeId != null && (
        <CreateAdditionalWorkExperienceNodeOption
          onboardingNodeId={currentOnboardingNodeId}
          type="authorship"
          name="authorship"
          description="TODO: add copy"
        />
      )}
    </OnboardingForm>
  );
};
