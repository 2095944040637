export const SidebarProgressNotStartedIcon = () => (
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 4.5L12 12.5"
      stroke="#CDCFD3"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M7.5 4.5L7.5 12.5"
      stroke="#CDCFD3"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M3 4.5L3 12.5"
      stroke="#CDCFD3"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
);

export const SidebarProgressInProgress1Icon = () => (
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 4.5L12 12.5"
      stroke="#CDCFD3"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M7.5 4.5L7.5 12.5"
      stroke="#CDCFD3"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M3 4.5L3 12.5"
      stroke="#F7B213"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
);

export const SidebarProgressInProgress2Icon = () => (
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 4.5L12 12.5"
      stroke="#CDCFD3"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M7.5 4.5L7.5 12.5"
      stroke="#F7B213"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M3 4.5L3 12.5"
      stroke="#F7B213"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
);

export const SidebarProgressCompleteIcon = () => (
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 4.5L12 12.5"
      stroke="#329D21"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M7.5 4.5L7.5 12.5"
      stroke="#329D21"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M3 4.5L3 12.5"
      stroke="#329D21"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
);
