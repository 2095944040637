import { Heading } from "@radix-ui/themes";
import { createFileRoute } from "@tanstack/react-router";

const NotFound = () => {
  return (
    <div className="w-full h-full flex flex-col items-center justify-center">
      <div>
        <Heading as="h1" size="4">
          We could not find on active onboarding for you. <br />
        </Heading>
        <Heading as="h2" size="4">
          Please contact{" "}
          <a
            href="mailto:support@lighthousehq.com"
            className="text-blue underline"
          >
            support@lighthousehq.com
          </a>{" "}
          for assistance.
        </Heading>
      </div>
    </div>
  );
};

export const Route = createFileRoute("/not-found")({
  component: () => <NotFound />,
});
