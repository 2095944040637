import { useState } from "react";
import { Button } from "./button";
import { Mark } from "./mark";
import { customerApi } from "@lighthouse/api";

export const ExternalTokenExpiredPage = (props: {
  letterActionId: number;
  token: string;
}) => {
  const { letterActionId, token } = props;

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [email, setEmail] = useState("");

  const requestLink = async () => {
    if (loading) return;

    setLoading(true);

    const res = await customerApi.resendExternalUrl({
      letterActionId,
      token,
    });

    if (!res.success || res.data == null) {
      setError(true);
      setLoading(false);
      return;
    }

    setEmail(res.data.email);
    setSuccess(true);
  };

  return (
    <div
      className="w-screen h-screen flex items-center justify-center fixed top-0 left-0 z-50 opacity-100"
      style={{
        background: "url('/backgrounds/external.png')",
        backgroundBlendMode: "color-burn",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundColor: "#E7E7E8",
      }}
    >
      <div className="absolute top-7 left-7">
        <Mark />
      </div>

      {!success && (
        <div className="flex flex-col w-[500px] max-w-[80%] items-start gap-6">
          <h1 className="text-3xl text-grey-200 font-semibold font-serif">
            This link has expired
          </h1>

          <p className="text-grey-200 font-normal">
            It looks like the link you clicked on has expired. You can create a
            new link by clicking on the button below.
          </p>

          <p className="text-grey-200 font-semibold">
            Thank you for supporting exceptional talent.
          </p>

          {error && (
            <p className="text-negative font-normal">
              Something went wrong, please try again later.
            </p>
          )}

          <Button variant="primary" onClick={requestLink} disabled={loading}>
            Request another link
          </Button>
        </div>
      )}

      {success && (
        <div className="flex flex-col w-[500px] max-w-[80%] items-start gap-6">
          <h1 className="text-3xl text-grey-200 font-semibold font-serif">
            Link sent
          </h1>

          <p className="text-grey-200 font-normal">
            We have sent you an email at{" "}
            <span className="text-blue">{email}</span> with a new link. Please
            check your inbox and spam folder. If this email is not correct,
            please contact us at{" "}
            <a href="mailto:support@lighthousehq.com" className="text-blue">
              support@lighthousehq.com
            </a>
            .
          </p>

          <p className="text-grey-200 font-semibold">
            Thank you for supporting exceptional talent.
          </p>
        </div>
      )}
    </div>
  );
};
