import { OnboardingForm } from "@/components/form";
import { LetterSigner, LetterSigners } from "@/components/letterSigners";
import { useOnboardingNodeData } from "@/lib/hooks";
import { useOnboarding } from "@/providers/onboardingProvider";

export const SupervisorLetter = () => {
  const { currentOnboardingNodeId } = useOnboarding();
  const { data, doSetData } = useOnboardingNodeData(
    currentOnboardingNodeId ?? -1
  );

  return (
    <OnboardingForm heading="Support Letters">
      <span className="text-grey-300 text-[12px] mt-4 pr-12">
        Like all of the content in your application,{" "}
        <span className="text-grey-200">
          the USCIS will want evidence to prove our claims, and a support letter
          signed by someone familiar with your work.
        </span>{" "}
        This letter is connected with your{" "}
        <span className="bg-blue-light text-blue px-0.5 rounded-sm">
          Critical Role
        </span>
      </span>

      <LetterSigners
        value={(data.signers?.value ?? []) as LetterSigner[]}
        onChange={(signers) => doSetData("signers", signers)}
      />
    </OnboardingForm>
  );
};
