import { Outlet, createFileRoute } from "@tanstack/react-router";

export const Route = createFileRoute("/_login")({
  component: () => {
    return (
      <div className="flex w-full h-screen items-center justify-center">
        <Outlet />
      </div>
    );
  },
});
