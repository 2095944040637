export const DesktopIcon = () => (
  <svg
    width="13"
    height="12"
    viewBox="0 0 13 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_482_6460)">
      <path
        d="M2.75 9L10.25 9C10.6642 9 11 8.66421 11 8.25V3C11 2.58579 10.6642 2.25 10.25 2.25L2.75 2.25C2.33579 2.25 2 2.58579 2 3V8.25C2 8.66421 2.33579 9 2.75 9Z"

        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 10.5H5"

        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2 7.125H11"

        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.5 9V10.5"

        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_482_6460">
        <rect width="12" height="12" fill="white" transform="translate(0.5)" />
      </clipPath>
    </defs>
  </svg>
);
