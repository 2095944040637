import { ArrowRightIcon } from "@radix-ui/react-icons";
import { ChatText, HighlightedText } from "@/components/typography";
import { ChatBubble } from "@/components/chat/bubble";
import { AboutIcon } from "@/components/icons/about";
import { WorkExperienceIcon } from "@/components/icons/workExperience";
import { DocumentsIcon } from "@/components/icons/documents";
import { ReviewIcon } from "@/components/icons/review";
import {
  SidebarProgressCompleteIcon,
  SidebarProgressInProgress1Icon,
  SidebarProgressInProgress2Icon,
  SidebarProgressNotStartedIcon,
} from "@/components/icons/sidebarProgress";
import { Button } from "@/components/button";

import { ChatMessages, isDateRangeValid, useO1StartContext } from "./store";
import { Fragment } from "react/jsx-runtime";
import { Checkbox, DateInput, TextArea } from "@/components/inputs";
import { useRef } from "react";
import { cn } from "@/lib/cn";
import moment from "moment";
import { useOnboardingData } from "@/providers/onboardingDataProvider";

const StatusExplainer = (props: { children: React.ReactNode }) => {
  return (
    <div className="bg-grey-800 rounded-full px-2 py-1 flex flex-row gap-[6px] text-[#111111B8]/70 items-center text-[14px] w-fit shadow-bubble">
      {props.children}
    </div>
  );
};

const SectionExplainer = (props: { children: React.ReactNode }) => {
  return (
    <div className="bg-[#111111B8] bg-opacity-[72%] rounded-md px-2 py-1 flex flex-row gap-[6px] text-grey-800 items-center text-[14px] w-fit text-nowrap border border-blue border-opacity-[8%]">
      {props.children}
    </div>
  );
};

const Goal = () => (
  <ChatBubble>
    <ChatText className="text-base">
      At Lighthouse, our goal is to make preparing your{" "}
      <HighlightedText>O-1A Visa</HighlightedText> application{" "}
      <strong>fast and simple</strong>. The quicker you provide information and
      feedback, the faster your application is prepared.
    </ChatText>
  </ChatBubble>
);

const Timeline = () => (
  <ChatBubble>
    <ChatText className="text-base">
      Filling out the form should take you 4 hours over 3 weeks, you will need
      additional time to completing documents and revise the{" "}
      <HighlightedText>support letters </HighlightedText>
      that we will write for you. The quicker you provide information and
      feedback, the faster your application is prepared.
      <br />
      <br />
      Upon submission, expedited USCIS processing (included in your fees)
      ensures an outcome within{" "}
      <strong>
        15 business days, while standard processing takes 2+ months.
      </strong>
    </ChatText>
  </ChatBubble>
);

const Sections = () => (
  <ChatBubble className="flex flex-col gap-1">
    <ChatText className="text-base">
      You'll need to complete four main sections, each containing information
      about you to confirm or share:
    </ChatText>

    <div className="flex flex-row gap-1 my-1 w-full flex-wrap">
      <SectionExplainer>
        <AboutIcon />
        About your background
      </SectionExplainer>
      <SectionExplainer>
        <WorkExperienceIcon />
        Your work experience
      </SectionExplainer>
      <SectionExplainer>
        <DocumentsIcon />
        Employer documents
      </SectionExplainer>
      <SectionExplainer>
        <ReviewIcon />
        Review information
      </SectionExplainer>
    </div>

    <ChatText className="text-base">
      Your progress through each step will be marked by these indicators:
    </ChatText>
    <div className="flex flex-row gap-1 m-1 flex-wrap">
      <StatusExplainer>
        <SidebarProgressNotStartedIcon />- Not Started
      </StatusExplainer>
      <StatusExplainer>
        <div className="flex flex-row gap-0.5 items-center">
          <SidebarProgressInProgress1Icon />, <SidebarProgressInProgress2Icon />
        </div>
        - In Progress
      </StatusExplainer>
      <StatusExplainer>
        <SidebarProgressCompleteIcon />- Complete
      </StatusExplainer>
    </div>
  </ChatBubble>
);

const Autosave = () => (
  <ChatBubble>
    <ChatText className="text-base">
      If you need a break during the process, don't worry,{" "}
      <strong>your information will be saved as you go.</strong> You can return
      to your onboarding at any time and pick up where you left off.
      <br />
      <br />
      Before we dive in, please answer some questions:
    </ChatText>
  </ChatBubble>
);

const TravelPlan = (props: { doAddMessage: () => void }) => {
  const { doAddMessage } = props;

  const {
    travelWithin2Weeks,
    setTravelWithin2Weeks,
    messages,
    setDisableScroll,
    travelDetails,
    setTravelDetails,
  } = useO1StartContext();

  const bottomRef = useRef<HTMLDivElement>(null);

  const scrollToBottom = () => {
    bottomRef.current?.scrollIntoView({
      behavior: "smooth",
      inline: "nearest",
    });
  };

  const doSetTravelWithin2Weeks = async (value: boolean) => {
    setTravelWithin2Weeks(value);
    await new Promise((resolve) => setTimeout(resolve, 5));
    scrollToBottom();
    if (!value && messages[messages.length - 1] === "travel-plan") {
      doAddMessage();
    }
  };

  return (
    <div className="flex flex-col">
      <ChatBubble>
        <ChatText className="text-base text-grey-100">
          Do you have any international travel planned within two weeks before
          or one month after your visa submission date?
        </ChatText>

        <div className="flex flex-row gap-2 mt-4">
          <Button
            variant="action"
            onClick={() => {
              doSetTravelWithin2Weeks(true);
            }}
            className={cn(
              travelWithin2Weeks === false && "opacity-50 hover:opacity-100"
            )}
          >
            Yes, I do
          </Button>
          <Button
            variant="action"
            onClick={() => {
              doSetTravelWithin2Weeks(false);
            }}
            className={cn(
              travelWithin2Weeks === true && "opacity-50 hover:opacity-100"
            )}
          >
            No, I don't
          </Button>
        </div>
      </ChatBubble>

      <ChatBubble
        className={cn(
          "mt-4 hidden transition-all ease-in duration-300",
          travelWithin2Weeks && "block"
        )}
      >
        <ChatText className="text-md text-grey-100 font-normal">
          Please describe your upcoming travel plans for optimal scheduling of
          your case preparation; your Lighthouse Case Concierge will contact you
          with strategic options.
        </ChatText>
        <TextArea
          className="min-h-[80px] w-full mt-2"
          placeholder="Enter your message here..."
          value={travelDetails}
          onChange={(e) => setTravelDetails(e)}
          onFocus={() => setDisableScroll(true)}
          onBlur={() => setDisableScroll(false)}
        />
      </ChatBubble>

      <div ref={bottomRef} />
    </div>
  );
};

const CanBeResponsive = (props: { doAddMessage: () => void }) => {
  const { doAddMessage } = props;

  const {
    canBeResponsive,
    setCanBeResponsive,
    messages,
    setBestDates,
    bestDates,
  } = useO1StartContext();

  const bottomRef = useRef<HTMLDivElement>(null);

  const scrollToBottom = () => {
    bottomRef.current?.scrollIntoView({
      behavior: "smooth",
      inline: "nearest",
    });
  };

  const doSetCanBeResponsive = async (value: boolean) => {
    setCanBeResponsive(value);
    await new Promise((resolve) => setTimeout(resolve, 5));
    scrollToBottom();

    if (value && messages[messages.length - 1] === "can-be-responsive") {
      doAddMessage();
    }
  };

  return (
    <div className="flex flex-col">
      <ChatBubble>
        <ChatText className="text-base text-grey-100">
          Can you commit to being responsive and available for about 4 hours of
          case preparation over the next two weeks?
        </ChatText>

        <div className="flex flex-row gap-2 mt-4">
          <Button
            variant="action"
            onClick={() => doSetCanBeResponsive(true)}
            className={cn(
              canBeResponsive === false && "opacity-50 hover:opacity-100"
            )}
          >
            Yes, I can
          </Button>
          <Button
            variant="action"
            onClick={() => doSetCanBeResponsive(false)}
            className={cn(
              canBeResponsive === true && "opacity-50 hover:opacity-100"
            )}
          >
            No, I can't
          </Button>
        </div>
      </ChatBubble>
      <ChatBubble
        className={cn(
          "flex flex-col mt-4 hidden transition-all ease-in duration-300",
          canBeResponsive === false && "block"
        )}
      >
        <ChatText className="text-base text-grey-100">
          What two week window will you be available to support the preparation
          of your case?
        </ChatText>

        <div className="flex flex-row gap-2 mt-4 items-center">
          <DateInput
            value={moment(bestDates?.startDate).toDate()}
            onValueChange={(value) =>
              setBestDates({
                startDate: value?.toISOString(),
                endDate: bestDates?.endDate,
              })
            }
          />
          -
          <DateInput
            value={moment(bestDates?.endDate).toDate()}
            onValueChange={(value) =>
              setBestDates({
                endDate: value?.toISOString(),
                startDate: bestDates?.startDate,
              })
            }
          />
        </div>

        {bestDates?.endDate != null &&
          bestDates.startDate != null &&
          !isDateRangeValid(bestDates.startDate, bestDates.endDate) && (
            <ChatText className="text-grey-400 mt-4 text-xs">
              End date must be after start date, and add up to at least 2 weeks.
            </ChatText>
          )}
      </ChatBubble>
      <div ref={bottomRef} />
    </div>
  );
};

const Documents = () => (
  <ChatBubble>
    <ChatText className="text-base text-grey-100">
      Great, that's all you need to know before you start! For the first part
      you will want to gather:
    </ChatText>

    <ol className="flex flex-col gap-1 mt-4">
      <li className="flex flex-row">
        <div className="p-1 pl-0">
          <ArrowRightIcon />
        </div>
        <ChatText className="text-base text-grey-100">
          <strong>Passport</strong> for photos of your passport ID page and US
          visa stamps inside.
        </ChatText>
      </li>
      <li className="flex flex-row">
        <div className="p-1 pl-0">
          <ArrowRightIcon />
        </div>
        <ChatText className="text-base text-grey-100">
          <strong>Copies of any of your prior visa documentation.</strong> It
          can be I-797, I-20, DS-2019, EAD cards, etc.
        </ChatText>
      </li>
    </ol>
  </ChatBubble>
);

const CaseStrategy = () => {
  const { allOnboardingGroups } = useOnboardingData();
  const strategy =
    allOnboardingGroups.find((x) => x.id === "work_experience")?.nodes ?? [];

  return (
    <ChatBubble>
      <ChatText className="text-base">
        We have prepared a case strategy for you based on the information we
        know about you, which we think matches the USCIS criteria.
      </ChatText>

      <div className="my-4 flex flex-col gap-1">
        {strategy.map((node, index) => {
          return (
            <div key={index} className="flex flex-row items-center gap-2 px-1">
              <Checkbox
                checked={true}
                disabled
                onCheckedChange={() => {}}
                className="disabled:opacity-100"
              />
              <div className="text-grey-100 flex flex-row gap-1">
                <span>
                  {node.type
                    .split("-")
                    .map((word) => word[0].toUpperCase() + word.slice(1))
                    .join(" ")}
                </span>
                {node.name.trim().length > 0 && <>({node.name})</>}
              </div>
            </div>
          );
        })}
      </div>

      <ChatText className="text-base">
        If you think we missed something you think is important, you can add it
        later in the “work experience section”.
      </ChatText>
    </ChatBubble>
  );
};

export const O1ChatLogs = (props: { doAddMessage: () => void }) => {
  const { doAddMessage } = props;

  const { messages } = useO1StartContext();

  const chatMessageComponentMap: Record<ChatMessages, React.ReactNode> = {
    goal: <Goal />,
    timeline: <Timeline />,
    sections: <Sections />,
    autosave: <Autosave />,
    "travel-plan": <TravelPlan doAddMessage={doAddMessage} />,
    "can-be-responsive": <CanBeResponsive doAddMessage={doAddMessage} />,
    "case-strategy": <CaseStrategy />,
    documents: <Documents />,
  };

  return (
    <>
      {messages.map((message, index) => (
        <Fragment key={`${message}-${index}`}>
          {chatMessageComponentMap[message]}
        </Fragment>
      ))}
    </>
  );
};
