import { Spinner } from "@radix-ui/themes";
import { useRef, useState } from "react";
import Dropzone from "react-dropzone";
import { UploadIcon } from "./icons/upload";
import { InputText, LabelText } from "./typography";
import { TrashIcon } from "./icons/trash";
import { cn } from "@/lib/cn";
import { FileViewerModal } from "./fileViewer";

const getFileTypeHint = (mimeType?: string) => {
  if (mimeType === "application/pdf") return "PDF";
  if (mimeType === "image/jpeg") return "JPG";
  if (mimeType === "image/png") return "PNG";
  if (mimeType === "application/vnd.ms-excel") return "XLS";
  if (
    mimeType ===
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
  )
    return "DOC";
};

export const FileCard = (props: {
  file: {
    id: number;
    name: string;
    size?: number | null;
    mimeType?: string;
  };
  onRemove?: () => void;
  uploading?: boolean;
}) => {
  const { file, onRemove, uploading } = props;

  const doRemove = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    onRemove?.();
  };

  const [fileViewerOpen, setFileViewerOpen] = useState(false);

  const fileTypeHint = getFileTypeHint(file.mimeType);

  return (
    <>
      <FileViewerModal
        open={fileViewerOpen}
        setOpen={(v) => setFileViewerOpen(v)}
        fileId={file.id}
      />
      <div
        className="flex flex-col gap-2 w-[167px] max-w-[167px] min-w-[167px] min-h-[206px] bg-grey-200 rounded-lg p-2 cursor-pointer"
        onClick={() => setFileViewerOpen(true)}
      >
        <div className="relative rounded-sm h-[150px] bg-grey-400 group z-10">
          <div
            className={cn(
              "rounded-sm h-[150px] bg-grey-400 group",
              uploading && "opacity-50 pointer-events-none"
            )}
          >
            <div className="w-full h-full bg-grey-800 rounded-sm shadow-lg items-center">
              <div className="flex flex-col px-3 gap-2 h-full py-2 justify-center">
                <div className="w-3/4 h-2 bg-grey-500 rounded-full"></div>
                <div className="w-full h-2 bg-grey-500 rounded-full"></div>
                <div className="w-5/6 h-2 bg-grey-500 rounded-full"></div>
                <div className="w-2/3 h-2 bg-grey-500 rounded-full"></div>
                <div className="w-3/4 h-2 bg-grey-500 rounded-full"></div>
                <div className="w-full h-2 bg-grey-500 rounded-full"></div>
                <div className="w-5/6 h-2 bg-grey-500 rounded-full"></div>
                <div className="w-5/6 h-2 bg-grey-500 rounded-full"></div>
              </div>
            </div>
          </div>
          <div className="relative h-[150px] z-20 -my-[150px]">
            <div className="w-full flex flex-col items-end p-1 invisible group-hover:visible top-0 left-0">
              <button
                className="p-2 bg-grey-150 rounded-md backdrop-blur-lg"
                onClick={doRemove}
              >
                <TrashIcon className="fill-negative" />
              </button>
            </div>

            {uploading && (
              <div className="flex w-full items-center justify-center mt-7">
                <Spinner />
              </div>
            )}

            {fileTypeHint != null && (
              <div className="h-full flex flex-col">
                <div className="py-0.5 px-1.5 bg-grey-150 rounded-md backdrop-blur-lg mr-auto mt-auto mb-10 ml-1 text-grey-500 text-sm">
                  {fileTypeHint}
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="px-1 flex flex-col gap-1">
          <LabelText className="text-grey-500 text-sm truncate">
            {file.name}
          </LabelText>

          <LabelText className="text-grey-400">
            {file.size != null && `${Math.round(file.size / 1024)} KB`}
            {file.size == null && "Unknown size"}
          </LabelText>
        </div>
      </div>
    </>
  );
};

export const FileUploadArea = (props: {
  files?: {
    id: number;
    name: string;
    size?: number | null;
    mimeType: string;
  }[];
  onUpload: (files: File[]) => Promise<void>;
  disabled?: boolean;
  multiple?: boolean;
  onRemove?: (id: number) => void;
}) => {
  const { files, onUpload, disabled, multiple, onRemove } = props;

  // const [uploadingFiles, setUploadingFiles] = useState<
  //   {
  //     id: number;
  //     name: string;
  //     size: number;
  //     date: string;
  //     owner: string;
  //     uploading: boolean;
  //     mimeType: string;
  //   }[]
  // >([]);

  const inputRef = useRef<HTMLInputElement>(null);

  const doUpload = async (acceptedFiles: File[]) => {
    if (acceptedFiles.length === 0) return;
    await onUpload(acceptedFiles);
  };

  const filesToRender = [...(files ?? [])];

  return (
    <div
      className={cn(
        "flex flex-row gap-[10px] h-full w-full border border-dashed border-grey-500 rounded-lg flex-wrap overflow-scroll",
        filesToRender.length > 0 && "bg-grey-700 p-2",
        filesToRender.length < 3 && "flex-nowrap"
      )}
    >
      <Dropzone
        onDrop={doUpload}
        accept={{
          "application/pdf": [".pdf"],
          "image/jpeg": [".jpg", ".jpeg"],
          "image/png": [".png"],
        }}
        disabled={disabled || (!multiple && (files?.length ?? 0) > 0)}
        multiple={multiple}
      >
        {({ getRootProps, getInputProps }) => (
          <>
            <div
              className={cn(
                "flex flex-col min-w-[167px] min-h-[206px] items-center justify-center bg-white rounded-lg",
                filesToRender.length === 0 && "w-full",
                filesToRender.length === 1 && "w-full gap-1",
                filesToRender.length > 2 && "w-[167px] gap-1",
                "sm:px-1 md:px-2 lg:px-3"
              )}
              {...getRootProps()}
            >
              <input ref={inputRef} {...getInputProps()} />

              {!multiple &&
                filesToRender.length > 0 &&
                filesToRender[0].id != null && (
                  <div className="px-1 flex flex-col items-center text-center">
                    Delete existing file to upload a new one.
                    <LabelText className="text-center text-grey-400">
                      Minimum 1600px width recommended. Max 10MB each.
                      <br />
                      Acceptable file types include jpg, png, pdf.
                    </LabelText>
                  </div>
                )}

              {((!multiple && filesToRender.length === 0) || multiple) && (
                <>
                  <div className="rounded-full p-1 bg-blue-light">
                    <UploadIcon />
                  </div>
                  <div className="px-1 flex flex-col items-center text-center">
                    <InputText>
                      Drag and drop or{" "}
                      <button
                        className="text-blue"
                        onClick={() => inputRef.current?.click()}
                      >
                        Browse
                      </button>
                    </InputText>
                    <LabelText className="text-center text-grey-400">
                      Minimum 1600px width recommended. Max 10MB each.
                      <br />
                      Acceptable file types include jpg, png, pdf.
                    </LabelText>
                  </div>
                </>
              )}
            </div>

            {filesToRender.length > 0 && (
              <>
                {filesToRender.map((file) => (
                  <FileCard
                    key={file.id}
                    file={{
                      id: file.id,
                      name: file.name,
                      size: file.size,
                      mimeType: file.mimeType,
                    }}
                    onRemove={() => onRemove?.(file.id)}
                  />
                ))}
              </>
            )}
          </>
        )}
      </Dropzone>
    </div>
  );
};
