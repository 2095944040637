import {
  ArrowLeftIcon,
  ArrowRightIcon,
  CheckCircledIcon,
  ChevronRightIcon,
} from "@radix-ui/react-icons";
import { Modal } from "./modal";
import { useState } from "react";
import { ChevronDownIcon } from "@radix-ui/themes";
import { cn } from "@/lib/cn";
import { Input, TextArea } from "./inputs";
import { Button } from "./button";
import { useMutation } from "urql";
import { createSupportRequest } from "@/lib/queries";

const O1Questions = [
  {
    title: "How long will the O-1A process take?",
    content: (
      <ol className="list-disc list-outside ml-5 pr-10">
        <li>Your onboarding will take 30-45 minutes to complete.</li>
        <li>
          Once you submit your onboarding, the Lighthouse team will review your
          submission and get started on drafting your forms, letters, and
          supporting documentation.
        </li>
        <li>
          The end-to-end preparation process will be about 30 days or fewer,
          with your collaboration, and with premium processing there is a 15
          business day turnaround time from USCIS.
        </li>
        <li>
          Overall, the O-1A process will be about 5-8 weeks including government
          time.
        </li>
      </ol>
    ),
  },
  {
    title: "How long is an O-1A visa good for?",
    content:
      "The visa has an initial validity period of 3 years and can be renewed annually in perpetuity.",
  },
  {
    title: "I have a change in my employer? What should I do?",
    content:
      "If you are changing your employer on your O-1A, reach out to success@lighthousehq.com to discuss how to pursue a refiling",
  },
  {
    title: "Can an O-1A visa holder apply for a Green Card?",
    content:
      "Yes! The O-1A can be a great stepping stone to a permanent residency pathway. At Lighthouse, we support the EB-1A and EB-2 National Interest Waiver green card pathways. To discuss your eligibility and timeline, reach out to success@lighthousehq.com.",
  },
  {
    title: "May a spouse of an O-1A visa holder work?",
    content:
      "No, the O-1A has a dependent visa available called the O-3. However, O-3 holders do not qualify for work authorization with this dependent visa.",
  },
];

const FAQDropdown = (props: { title: string; content: React.ReactNode }) => {
  const [open, setOpen] = useState(false);

  return (
    <div className="flex flex-col">
      <div
        className="flex flex-row items-center px-2 py-1 cursor-pointer"
        onClick={() => setOpen((prev) => !prev)}
      >
        <span className="text-grey-200 font-medium text-sm">{props.title}</span>
        {open ? (
          <ChevronDownIcon className="ml-auto" />
        ) : (
          <ChevronRightIcon className="ml-auto" />
        )}
      </div>
      {
        <span
          className={cn(
            "text-grey-300 text-sm pl-2 pr-4 py-1 break-words",
            !open && "hidden"
          )}
        >
          {props.content}
        </span>
      }
    </div>
  );
};

const FAQ = (props: {
  visaClass?: string;
  setPage: (page: "faq" | "message") => void;
}) => {
  return (
    <div className="w-full flex flex-col gap-4 overflow-hidden">
      <div
        className="bg-grey-200 rounded-[8px] flex flex-col gap-2 py-3 px-4 w-full cursor-pointer"
        onClick={() => props.setPage("message")}
      >
        <div className="w-full flex flex-row items-center">
          <p className="text-grey-800 font-[525] text-[14px]">
            Send us a message
          </p>
          <div className="ml-auto text-grey-800">
            <ArrowRightIcon />
          </div>
        </div>
        <p className="text-grey-500 text-[14px]">
          We typically reply in under 3 days.
        </p>
      </div>

      {props.visaClass === "o-1a" && (
        <div className="flex flex-col overflow-scroll pb-4 gap-2 overflow-scroll">
          {O1Questions.map((question) => (
            <FAQDropdown
              key={question.title}
              title={question.title}
              content={question.content}
            />
          ))}
        </div>
      )}
    </div>
  );
};

const Message = (props: { setPage: (page: "faq" | "message") => void }) => {
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");

  const [status, setStatus] = useState<
    "idle" | "loading" | "success" | "error"
  >("idle");

  const createSupportRequestMutation = useMutation(createSupportRequest)[1];

  const doSendMessage = async () => {
    if (subject.trim().length === 0 || message.trim().length === 0) return;

    setStatus("loading");
    const res = await createSupportRequestMutation({
      input: {
        subject,
        message,
      },
    });

    if (res.error) {
      setStatus("error");
      return;
    }

    setMessage("");
    setSubject("");
    setStatus("success");
  };

  return (
    <div className="flex flex-col gap-4 w-full px-0.5 pb-4">
      <div className="flex flex-col gap-2 w-full">
        <span className="text-grey-300 text-xs font-medium">Subject</span>
        <Input
          placeholder="Subject"
          className="w-full"
          value={subject}
          onValueChange={(e) => setSubject(e)}
        />
      </div>

      <div className="flex flex-col gap-2 w-full">
        <span className="text-grey-300 text-xs font-medium">Your message</span>
        <TextArea
          placeholder="Enter your message here"
          className="w-full"
          value={message}
          onChange={(e) => setMessage(e)}
        />
      </div>
      {status === "success" && (
        <div className="flex flex-row items-center w-full bg-positive/10 border border-positive px-3 py-2 rounded-md gap-2">
          <CheckCircledIcon className="text-positive w-4 h-4" />
          <span className="text-positive text-sm">
            Your support request has been sent. We will get back to you soon.
          </span>
        </div>
      )}

      <div className="flex flex-row items-center w-full gap-2 mt-4">
        <Button
          variant="secondary"
          onClick={() => {
            props.setPage("faq");
            setSubject("");
            setMessage("");
            setStatus("idle");
          }}
          className="shadow-border"
        >
          <ArrowLeftIcon /> Back to FAQ
        </Button>
        <Button
          variant="primary"
          onClick={doSendMessage}
          className="ml-auto"
          disabled={subject.trim().length === 0 || message.trim().length === 0}
          loading={status === "loading"}
        >
          Send Message
        </Button>
      </div>
    </div>
  );
};

export const FAQModal = (props: {
  open: boolean;
  setOpen: (value: boolean) => void;
  visaClass?: string;
}) => {
  const { open, setOpen, visaClass } = props;

  const [page, setPage] = useState<"faq" | "message">("faq");

  return (
    <Modal
      open={open}
      onOpenChange={() => {
        setOpen(false);
        setPage("faq");
      }}
      title="Help & Support"
      contentClassName={page === "faq" ? "h-[60vh]" : ""}
      childrenClassName="pr-6 pb-0"
      fitContent={page === "message"}
    >
      <div className="w-full h-full flex flex-col overflow-hidden">
        {page === "faq" && <FAQ visaClass={visaClass} setPage={setPage} />}
        {page === "message" && <Message setPage={setPage} />}
      </div>
    </Modal>
  );
};
