import { ResultOf } from "gql.tada";
import { getOnboarding } from "./queries";
import { parseFormValues } from "./formValues";
import { OnboardingNodeGroups } from "@/providers/onboardingProvider";

export type OnboardingProgress = Partial<{
  [groupId in OnboardingNodeGroups]: OnboardingGroupProgress;
}>;

export type OnboardingGroupProgress = {
  totalNodes: number;
  completedNodes: number;
  nodeProgress: {
    [id: number]: {
      totalFields: number;
      completedFields: number;
      rejectedFieldKeys: string[];
    };
  };
};

export const calculateProgress = (
  groups: ResultOf<typeof getOnboarding>["onboarding"]["groups"],
): OnboardingProgress => {
  if (groups == null) return {};
  const res: OnboardingProgress = {};

  for (const group of groups) {
    const completedNodes = group.nodes.filter((x) => x.completed).length;
    const totalNodes = group.nodes.length;

    if (totalNodes == 0) continue;

    const nodeProgress: OnboardingGroupProgress["nodeProgress"] = {};

    for (const node of group.nodes) {
      let totalFields = 0;
      let completedFields = 0;
      const rejectedFieldKeys = [];

      const content = parseFormValues(node.content);

      for (const typeStruct of node.schema as unknown as {
        type: string;
        key: string;
        required: boolean;
      }[]) {
        const type = typeStruct.type;
        const key = typeStruct.key;
        const required = typeStruct.required;
        if (required === false) continue; // skip optional fields

        totalFields += 1;

        if (type.includes("file")) {
          if (
            node.files.filter((x) => x.subKey === key).length > 0 &&
            content[key]?.status != "rejected"
          ) {
            completedFields++;
          }
        }

        if (content[key]?.value != null && content[key]?.status != "rejected") {
          completedFields++;
        }

        if (content[key]?.status == "rejected") {
          rejectedFieldKeys.push(key);
        }
      }

      nodeProgress[node.id] = {
        totalFields,
        completedFields,
        rejectedFieldKeys,
      };
    }

    res[group.id] = {
      totalNodes,
      completedNodes,
      nodeProgress,
    };
  }

  return res;
};
