import { getOnboarding } from "@/lib/queries";
import { useQuery } from "urql";
import { HighlightedText, LabelText } from "./typography";
import moment from "moment";
import { cn } from "@/lib/cn";
import { ArrowRightIcon, CheckCircledIcon } from "@radix-ui/react-icons";
import { Tooltip, TooltipContent, TooltipTrigger } from "./tooltip";
import { useState } from "react";
import { FormSignatureModal } from "./formSignatureModal";

export const ActionCard = (props: {
  id: number;
  createdAt: string;
  name: string;
  status: string;
}) => {
  const { id, createdAt, name, status } = props;

  const [modalOpen, setModalOpen] = useState(false);

  return (
    <div className="w-[240px] flex flex-col p-4 rounded-md gap-4 bg-grey-200">
      <FormSignatureModal
        open={modalOpen}
        setOpen={setModalOpen}
        formActionId={id}
      />

      <div className="flex items-center justify-center w-full h-[90px] bg-grey-300 flex-shrink-0 rounded-sm overflow-hidden">
        <div className="w-[70%] h-full bg-white rounded-sm shadow-lg mt-10 p-3 pt-5 text-[3px] text-wrap break-words">
          ~~~~~~~~
          <br />
          <br />
          ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
          <br />
          ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
          <br />
          <br />
          ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
        </div>
      </div>

      <div className="flex flex-col gap-1">
        <Tooltip>
          <TooltipTrigger className="w-fit">
            <span className="text-grey-600 text-[14px] truncate">{name}</span>
          </TooltipTrigger>
          <TooltipContent className="p-2" side="top">
            {name}
          </TooltipContent>
        </Tooltip>

        <span className="text-[12px] text-grey-400 truncate">
          Created {moment(createdAt).fromNow()}
        </span>

        <div className="flex flex-row w-full justify-between mt-2">
          {status === "sent" && (
            <div className="px-3 py-[3px] text-[#81CA67] text-[11px] font-medium bg-[#81CA67] bg-opacity-10 rounded-full ring-1 ring-[#81CA67] ring-opacity-25 shadow-positive-2">
              Ready to sign
            </div>
          )}
          {status === "changes_requested" && (
            <div className="px-3 py-[3px] text-[#caab67] text-[11px] font-medium bg-[#caab67] bg-opacity-10 rounded-full ring-1 ring-[#caab67] ring-opacity-25 shadow-positive-2">
              Changes Requested
            </div>
          )}
          {status === "signed" && (
            <div className="flex flex-row gap-1 items-center px-3 py-[3px] text-[#5da045] text-[11px] font-medium bg-[#5da045] bg-opacity-10 rounded-full ring-1 ring-[#5da045] ring-opacity-25 shadow-positive-2">
              <CheckCircledIcon /> <span>Signed</span>
            </div>
          )}

          <button
            onClick={() => setModalOpen(true)}
            className={cn(
              "w-fit bg-grey-700 rounded-full px-2.5 py-1 text-[14px] font-medium text-grey-200 disabled:opacity-60",
              status === "approved" && "bg-grey-600"
            )}
          >
            <ArrowRightIcon className="h-4 w-4" />
          </button>
        </div>
      </div>
    </div>
  );
};

const formActionStatusOrder = {
  sent: 0,
  changes_requested: 1,
  signed: 2,
};

export const FormActions = (props: { onboardingId: number }) => {
  const { onboardingId } = props;

  const [{ data }] = useQuery({
    query: getOnboarding,
    variables: {
      id: onboardingId,
    },
    requestPolicy: "network-only",
  });

  const formActions =
    data?.onboarding.formActions.sort(
      (a, b) =>
        formActionStatusOrder[a.status] - formActionStatusOrder[b.status]
    ) ?? [];

  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-col gap-1">
        <LabelText className="text-sm text-grey-100" as="span">
          Form Signatures
        </LabelText>
        <LabelText className="text-sm text-grey-300 max-w-[600px]" as="span">
          Your application will contain government forms for your review and
          signature. Review and sign them by selecting each letter below.{" "}
          <HighlightedText className="px-1 py-0.5 rounded-sm">
            Learn more
          </HighlightedText>
        </LabelText>
      </div>

      {formActions.length === 0 && (
        <div className="flex flex-col px-1 justify-center items-center">
          <img src="/icons/support-letters.png" />
          <div className="text-sm text-grey-300 mt-2 text-center w-[500px]">
            <span className="text-grey-200">Your forms will appear here </span>
            once you fill in the necessary information in your onboarding, we'll
            prepare it for your signature within 3 business days.
          </div>
        </div>
      )}

      <div className="flex flex-row gap-3 mb-2">
        {formActions.map((action) => (
          <ActionCard
            key={action.id}
            id={action.id}
            createdAt={action.createdAt}
            name={action.name}
            status={action.status}
          />
        ))}
      </div>
    </div>
  );
};
