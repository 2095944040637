import {
  getPendingLetterReviews,
  getRecipientNameByLetterId,
  InviteExternalReviewer,
} from "@/lib/queries";
import { ResultOf } from "gql.tada";
import { useMutation, useQuery } from "urql";
import { Button } from "./button";

import { cn } from "@/lib/cn";
import { graphql } from "@/lib/graphql";
import { LabelText } from "./typography";
import { Spinner } from "@radix-ui/themes";
import { useState } from "react";

type LetterActionStatus = Exclude<
  ReturnType<typeof graphql.scalar<"LetterActionStatus">>,
  "draft"
>;

const letterActionStatus: {
  value: LetterActionStatus;
  label: string;
}[] = [
  {
    value: "pending",
    label: "Not Invited",
  },
  {
    value: "changes_requested",
    label: "Changes Requested",
  },
  {
    value: "approved",
    label: "Approved",
  },
  {
    value: "sent",
    label: "Under Review",
  },
];

const letterActionStatusColorMapping: Record<
  LetterActionStatus,
  { dot: string; status: string }
> = {
  sent: {
    dot: "bg-[#ebb306]",
    status: "bg-[#fff9c3] text-[#a3783e] ring-[#ebb306]",
  },
  pending: {
    dot: "bg-[#575757]",
    status: "bg-[#dddddd] text-[#6e6e6e] ring-[#575757]",
  },

  approved: {
    dot: "bg-[#98dc2d]",
    status: "bg-[#e8ffc5] text-[#7fab3b] ring-[#98dc2d]",
  },
  changes_requested: {
    dot: "bg-[#ef4344]",
    status: "bg-[#fee2e1] text-[#c94a48] ring-[#ef4344]",
  },
};

export const LetterActionStatusBadge = (props: {
  status: LetterActionStatus;
}) => {
  const { status } = props;

  return (
    <div
      className={cn(
        "group flex w-fit flex-row gap-1 items-center rounded-full bg-gray-50 px-2 py-1 text-xs text-gray-600 flex-nowrap whitespace-nowrap ring-1 ring-inset ring-opacity-30",
        letterActionStatusColorMapping[status].status
      )}
    >
      {letterActionStatus.find((x) => x.value === status)?.label ?? ""}
    </div>
  );
};

const ReviewRow = (props: {
  action: ResultOf<
    typeof getPendingLetterReviews
  >["getPendingLetterReviews"][number];
  refetch: () => void;
}) => {
  const { action, refetch } = props;

  const [loading, setLoading] = useState(false);

  const inviteExternalReviewerMutation = useMutation(InviteExternalReviewer)[1];

  const [{ data }] = useQuery({
    query: getRecipientNameByLetterId,
    variables: {
      letterActionId: action.id,
    },
  });

  const copyLink = () => {
    if (disableActions || loading) return;

    setLoading(true);

    inviteExternalReviewerMutation({
      letterActionId: action.id,
      sendEmail: false,
    }).then((res) => {
      setLoading(false);
      if (res.error || res.data?.inviteExternalReviewer == null) return;

      navigator.clipboard.writeText(res.data.inviteExternalReviewer);
    });
  };

  const inviteUser = () => {
    if (disableActions || loading) return;

    setLoading(true);

    inviteExternalReviewerMutation({
      letterActionId: action.id,
      sendEmail: true,
    }).then((res) => {
      setLoading(false);
      if (res.error) return;

      refetch();
    });
  };

  const disableActions =
    action.status !== "pending" || action.recipientType !== "external";

  return (
    action.status != "draft" && (
      <tr
        key={action.id}
        className="text-xs text-grey-200 h-11 border-b-grey-600 border-b-[0.8px]"
      >
        <td className="px-1.5 pl-3">
          <span className="font-medium">
            {data?.getLetterActionRecipientName}
          </span>{" "}
          {action.externalEmail != null && (
            <span className="text-grey-300">({action.externalEmail})</span>
          )}
        </td>

        <td className="px-1.5">
          <LetterActionStatusBadge status={action.status} />
        </td>

        <td className="px-1.5 pr-3 w-0 min-w-fit">
          {disableActions && (
            <span className="text-grey-400">No actions available</span>
          )}
          {!disableActions && (
            <div className="flex flex-row gap-2">
              <Button
                variant="secondary"
                className="px-3 py-1.5"
                disabled={loading}
                onClick={copyLink}
              >
                <span className="text-xs font-semibold">Copy Link</span>
              </Button>

              <Button
                variant="primary"
                className="px-3 py-1.5 bg-grey-200"
                disabled={loading}
                onClick={inviteUser}
              >
                <span className="text-xs font-semibold">Invite to Review</span>
              </Button>
            </div>
          )}
        </td>
      </tr>
    )
  );
};

export const LetterReviewsTable = (props: { letterId: number }) => {
  const { letterId } = props;

  const [{ data, fetching }, refetch] = useQuery({
    query: getPendingLetterReviews,
    variables: {
      letterId: letterId,
    },
    requestPolicy: "network-only",
  });

  const refetchReviews = () => {
    refetch({ requestPolicy: "network-only" });
  };

  const sentActions = data?.getPendingLetterReviews.filter(
    (el) => el.status !== "pending"
  );
  const pendingActions = data?.getPendingLetterReviews.filter(
    (el) => el.status === "pending"
  );

  return (
    <div className="flex flex-col bg-grey-800 rounded-md p-4 gap-1 w-[825px] max-w-full mb-4">
      <LabelText className="text-sm text-grey-100" as="span">
        Reviews
      </LabelText>

      <LabelText className="text-sm text-grey-300 pr-10" as="span">
        Here, you can view all the reviews that have been requested for this
        letter. You can invite reviewers by clicking the invite button. If you
        want to personally invite someone, you can copy the link and send it to
        them.
      </LabelText>

      <table className="w-full text-left whitespace-nowrap mt-3">
        <tbody>
          <tr className="text-xs text-grey-300 h-7">
            <th className="font-medium px-1.5 pl-3 transition-opacity">
              Reviewer
            </th>
            <th className="font-medium px-1.5 transition-opacity">
              Review Progress
            </th>
            <th className="font-medium px-1.5 pr-3 transition-opacity">
              Actions
            </th>
          </tr>
          {sentActions?.map((action) => (
            <ReviewRow action={action} refetch={refetchReviews} />
          ))}
          {pendingActions?.map((action) => (
            <ReviewRow action={action} refetch={refetchReviews} />
          ))}
        </tbody>
      </table>

      {(fetching || data == null) && (
        <div className="w-full flex items-center justify-center text-grey-400">
          <Spinner className="animate-spin h-3 w-3" />
        </div>
      )}
    </div>
  );
};
