import { CompanySidebar, Sidebar } from "@/components/sidebar";

export const SidebarFrame = (props: { children?: React.ReactNode }) => {
  return (
    <div className="w-screen max-w-screen h-full flex flex-row bg-grey-800">
      <Sidebar />
      <div className="w-full h-full flex items-center justify-center py-4 pr-4 overflow-hidden">
        <div className="rounded-xl bg-grey-700 w-full h-full flex flex-row justify-center items-center overflow-hidden border border-grey-600">
          {props.children}
        </div>
      </div>
    </div>
  );
};

export const CompanySidebarFrame = (props: { children?: React.ReactNode }) => {
  return (
    <div className="w-screen max-w-screen h-full flex flex-row bg-grey-800">
      <CompanySidebar />
      <div className="w-full h-full flex items-center justify-center py-4 pr-4">
        <div className="rounded-[16px] bg-grey-700 w-full h-full flex flex-row justify-center items-center overflow-y-scroll border border-grey-600">
          {props.children}
        </div>
      </div>
    </div>
  );
};
