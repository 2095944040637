import { cn } from "@/lib/cn";
import { useWorkspaces } from "@/lib/hooks";
import { CheckIcon } from "@radix-ui/react-icons";

export const NoOnboardings = () => {
  const { workspaces, selectedWorkspace, setSelectedWorkspace } =
    useWorkspaces();

  return (
    <div className="w-full h-full flex flex-col items-center justify-center gap-2 bg-[url('/backgrounds/login.png')] bg-cover bg-center">
      <div className="w-1/4 flex flex-col gap-2 p-5 bg-white shadow-form rounded-lg">
        <div className="flex flex-col gap-1">
          <div className="text-md text-grey-300">
            We couldn't find an active onboarding for you.
          </div>
          <div className="text-sm text-grey-300">
            Try switching to a different workspace:
          </div>
        </div>

        {workspaces.map((workspace) => (
          <div
            key={workspace.id}
            className={cn(
              "flex flex-row px-3 py-2 rounded-md cursor-pointer items-center",
              selectedWorkspace?.id === workspace.id &&
                "bg-grey-700 shadow-border"
            )}
            onClick={() => setSelectedWorkspace(workspace)}
          >
            {workspace.name}

            {workspace.id === selectedWorkspace?.id && (
              <CheckIcon className="w-4 h-4 text-positive ml-auto" />
            )}
          </div>
        ))}
      </div>
    </div>
  );
};
