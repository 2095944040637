export const PendingDocumentsIcon = () => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1780_80246)">
      <path
        d="M3.375 10.499H2.625C2.52554 10.499 2.43016 10.4595 2.35984 10.3892C2.28951 10.3189 2.25 10.2235 2.25 10.124V8.62402"
        stroke="#2C2C2C"
        strokeWidth="0.785714"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.625 1.49902H7.125L9.75 4.12402V6.37402"
        stroke="#2C2C2C"
        strokeWidth="0.785714"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.25 2.99902V1.87402C2.25 1.77457 2.28951 1.67918 2.35984 1.60886C2.43016 1.53853 2.52554 1.49902 2.625 1.49902H3.75"
        stroke="#2C2C2C"
        strokeWidth="0.785714"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.125 1.49902V4.12402H9.75"
        stroke="#2C2C2C"
        strokeWidth="0.785714"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.75 8.24902V10.124C9.75 10.2235 9.71049 10.3189 9.64017 10.3892C9.56984 10.4595 9.47446 10.499 9.375 10.499H9"
        stroke="#2C2C2C"
        strokeWidth="0.785714"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.25 4.87402V6.74902"
        stroke="#2C2C2C"
        strokeWidth="0.785714"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.25 10.499H7.125"
        stroke="#2C2C2C"
        strokeWidth="0.785714"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_1780_80246">
        <rect
          width="12"
          height="12"
          fill="white"
          transform="translate(0 -0.000976562)"
        />
      </clipPath>
    </defs>
  </svg>
);

export const InProgressIcon = () => (
  <svg
    width="10"
    height="11"
    viewBox="0 0 10 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1780_70710)">
      <path
        d="M7.50065 1.33105L9.16732 2.99772M0.833984 9.66439L1.36582 7.71433C1.40052 7.58711 1.41786 7.52349 1.4445 7.46417C1.46814 7.4115 1.4972 7.36143 1.5312 7.31477C1.56949 7.26222 1.61611 7.21559 1.70936 7.12234L6.01495 2.81676C6.09745 2.73425 6.1387 2.693 6.18627 2.67755C6.22812 2.66395 6.27319 2.66395 6.31503 2.67755C6.3626 2.693 6.40385 2.73425 6.48635 2.81676L7.68162 4.01202C7.76412 4.09452 7.80537 4.13577 7.82083 4.18334C7.83442 4.22519 7.83442 4.27026 7.82083 4.3121C7.80537 4.35967 7.76412 4.40092 7.68162 4.48342L3.37603 8.78901C3.28278 8.88226 3.23616 8.92888 3.1836 8.96717C3.13694 9.00117 3.08687 9.03023 3.0342 9.05388C2.97488 9.08051 2.91127 9.09786 2.78404 9.13255L0.833984 9.66439Z"
        stroke="#2C2C2C"
        strokeWidth="0.785714"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_1780_70710">
        <rect
          width="10"
          height="10"
          fill="white"
          transform="translate(0 0.498047)"
        />
      </clipPath>
    </defs>
  </svg>
);

export const InFinalReviewIcon = () => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1780_69128)">
      <path
        d="M5.3125 8.74805C7.21098 8.74805 8.75 7.20903 8.75 5.31055C8.75 3.41207 7.21098 1.87305 5.3125 1.87305C3.41402 1.87305 1.875 3.41207 1.875 5.31055C1.875 7.20903 3.41402 8.74805 5.3125 8.74805Z"
        stroke="#532424"
        strokeWidth="0.785714"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.74414 7.74121L10.1259 10.123"
        stroke="#532424"
        strokeWidth="0.785714"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_1780_69128">
        <rect
          width="11"
          height="11"
          fill="white"
          transform="translate(0.5 0.498047)"
        />
      </clipPath>
    </defs>
  </svg>
);

export const SubmittedIcon = () => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.37492 10.4989H2.625C2.52554 10.4989 2.43016 10.4594 2.35983 10.3891C2.28951 10.3188 2.25 10.2234 2.25 10.1239V1.87402C2.25 1.77456 2.28951 1.67918 2.35983 1.60886C2.43016 1.53853 2.52554 1.49902 2.625 1.49902H7.12494L9.74991 4.12399V10.1239C9.74991 10.2234 9.7104 10.3188 9.64008 10.3891C9.56975 10.4594 9.47437 10.4989 9.37492 10.4989Z"
      stroke="#2C2C2C"
      strokeWidth="0.749991"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.125 1.49902V4.12399H9.74997"
      stroke="#2C2C2C"
      strokeWidth="0.749991"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.875 6.74901L5.99999 5.62402L7.12497 6.74901"
      stroke="#2C2C2C"
      strokeWidth="0.749991"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6 8.62399V5.62402"
      stroke="#2C2C2C"
      strokeWidth="0.749991"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const ShippedIcon = () => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.37492 10.4989H2.625C2.52554 10.4989 2.43016 10.4594 2.35983 10.3891C2.28951 10.3188 2.25 10.2234 2.25 10.1239V1.87402C2.25 1.77456 2.28951 1.67918 2.35983 1.60886C2.43016 1.53853 2.52554 1.49902 2.625 1.49902H7.12494L9.74991 4.12399V10.1239C9.74991 10.2234 9.7104 10.3188 9.64008 10.3891C9.56975 10.4594 9.47437 10.4989 9.37492 10.4989Z"
      stroke="#2C2C2C"
      strokeWidth="0.749991"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.125 1.49902V4.12399H9.74997"
      stroke="#2C2C2C"
      strokeWidth="0.749991"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.875 6.74901L5.99999 5.62402L7.12497 6.74901"
      stroke="#2C2C2C"
      strokeWidth="0.749991"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6 8.62399V5.62402"
      stroke="#2C2C2C"
      strokeWidth="0.749991"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const DeliveredIcon = () => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1780_82249)">
      <path
        d="M4.875 10.124H11.6249"
        stroke="#2C2C2C"
        strokeWidth="0.749991"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.81248 3.53622L4.87496 4.12403V2.24905C4.87496 2.18963 4.88907 2.13105 4.91615 2.07816C4.94322 2.02526 4.98248 1.97956 5.03069 1.94481C5.07889 1.91007 5.13466 1.88727 5.1934 1.87831C5.25215 1.86935 5.31218 1.87448 5.36855 1.89327L5.62495 1.97905L6.74994 4.66027L9.77615 5.50401C10.0924 5.59182 10.3712 5.78096 10.5697 6.04242C10.7681 6.30389 10.8754 6.62324 10.8749 6.95149V8.62397L2.59546 6.30556C2.28031 6.2173 2.00268 6.0284 1.8049 5.76765C1.60712 5.50691 1.50004 5.18863 1.5 4.86136V2.24905C1.5 2.18963 1.51411 2.13105 1.54119 2.07816C1.56826 2.02526 1.60752 1.97956 1.65572 1.94481C1.70393 1.91007 1.7597 1.88727 1.81844 1.87831C1.87718 1.86935 1.93722 1.87448 1.99359 1.89327L2.24999 1.97905L2.81248 3.53622Z"
        stroke="#2C2C2C"
        strokeWidth="0.749991"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_1780_82249">
        <rect
          width="11.9999"
          height="11.9999"
          fill="white"
          transform="translate(0 -0.000976562)"
        />
      </clipPath>
    </defs>
  </svg>
);

export const InUscisReviewIcon = () => (
  <svg
    width="12"
    height="13"
    viewBox="0 0 12 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1780_71476)">
      <path
        d="M6 1.99805V3.49805"
        stroke="#2C2C2C"
        strokeWidth="0.785714"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.5 6.49805H9"
        stroke="#2C2C2C"
        strokeWidth="0.785714"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.18188 9.67992L8.12109 8.61914"
        stroke="#2C2C2C"
        strokeWidth="0.785714"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6 10.998V9.49805"
        stroke="#2C2C2C"
        strokeWidth="0.785714"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.81836 9.67992L3.87914 8.61914"
        stroke="#2C2C2C"
        strokeWidth="0.785714"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.5 6.49805H3"
        stroke="#2C2C2C"
        strokeWidth="0.785714"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.81836 3.31641L3.87914 4.37719"
        stroke="#2C2C2C"
        strokeWidth="0.785714"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_1780_71476">
        <rect
          width="12"
          height="12"
          fill="white"
          transform="translate(0 0.498047)"
        />
      </clipPath>
    </defs>
  </svg>
);

export const ApprovedIcon = () => (
  <svg
    width="12"
    height="13"
    viewBox="0 0 12 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1780_74849)">
      <path
        d="M2.21875 10.998H9.78125"
        stroke="#2C2C2C"
        strokeWidth="0.785714"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.41161 6.87305L4.64763 2.98242C4.61563 2.81852 4.61759 2.64889 4.65338 2.48593C4.68917 2.32296 4.75788 2.17078 4.8545 2.04049C4.95111 1.91021 5.07318 1.80512 5.2118 1.73289C5.35041 1.66067 5.50206 1.62314 5.65567 1.62305H6.34317C6.49682 1.62307 6.64853 1.66055 6.78719 1.73274C6.92586 1.80494 7.04799 1.91002 7.14465 2.04031C7.24131 2.1706 7.31006 2.32281 7.34587 2.48581C7.38169 2.64881 7.38366 2.81848 7.35165 2.98242L6.58767 6.87305"
        stroke="#2C2C2C"
        strokeWidth="0.785714"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.4375 6.87305H2.5625C2.37265 6.87305 2.21875 7.04094 2.21875 7.24805V9.12305C2.21875 9.33015 2.37265 9.49805 2.5625 9.49805H9.4375C9.62735 9.49805 9.78125 9.33015 9.78125 9.12305V7.24805C9.78125 7.04094 9.62735 6.87305 9.4375 6.87305Z"
        stroke="#2C2C2C"
        strokeWidth="0.785714"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_1780_74849">
        <rect
          width="11"
          height="12"
          fill="white"
          transform="translate(0.5 0.498047)"
        />
      </clipPath>
    </defs>
  </svg>
);

export const DeniedIcon = () => (
  <svg
    width="12"
    height="13"
    viewBox="0 0 12 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1780_74849)">
      <path
        d="M2.21875 10.998H9.78125"
        stroke="#2C2C2C"
        strokeWidth="0.785714"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.41161 6.87305L4.64763 2.98242C4.61563 2.81852 4.61759 2.64889 4.65338 2.48593C4.68917 2.32296 4.75788 2.17078 4.8545 2.04049C4.95111 1.91021 5.07318 1.80512 5.2118 1.73289C5.35041 1.66067 5.50206 1.62314 5.65567 1.62305H6.34317C6.49682 1.62307 6.64853 1.66055 6.78719 1.73274C6.92586 1.80494 7.04799 1.91002 7.14465 2.04031C7.24131 2.1706 7.31006 2.32281 7.34587 2.48581C7.38169 2.64881 7.38366 2.81848 7.35165 2.98242L6.58767 6.87305"
        stroke="#2C2C2C"
        strokeWidth="0.785714"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.4375 6.87305H2.5625C2.37265 6.87305 2.21875 7.04094 2.21875 7.24805V9.12305C2.21875 9.33015 2.37265 9.49805 2.5625 9.49805H9.4375C9.62735 9.49805 9.78125 9.33015 9.78125 9.12305V7.24805C9.78125 7.04094 9.62735 6.87305 9.4375 6.87305Z"
        stroke="#2C2C2C"
        strokeWidth="0.785714"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_1780_74849">
        <rect
          width="11"
          height="12"
          fill="white"
          transform="translate(0.5 0.498047)"
        />
      </clipPath>
    </defs>
  </svg>
);

export const RfeIcon = () => (
  <svg
    width="12"
    height="13"
    viewBox="0 0 12 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1780_74849)">
      <path
        d="M2.21875 10.998H9.78125"
        stroke="#2C2C2C"
        strokeWidth="0.785714"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.41161 6.87305L4.64763 2.98242C4.61563 2.81852 4.61759 2.64889 4.65338 2.48593C4.68917 2.32296 4.75788 2.17078 4.8545 2.04049C4.95111 1.91021 5.07318 1.80512 5.2118 1.73289C5.35041 1.66067 5.50206 1.62314 5.65567 1.62305H6.34317C6.49682 1.62307 6.64853 1.66055 6.78719 1.73274C6.92586 1.80494 7.04799 1.91002 7.14465 2.04031C7.24131 2.1706 7.31006 2.32281 7.34587 2.48581C7.38169 2.64881 7.38366 2.81848 7.35165 2.98242L6.58767 6.87305"
        stroke="#2C2C2C"
        strokeWidth="0.785714"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.4375 6.87305H2.5625C2.37265 6.87305 2.21875 7.04094 2.21875 7.24805V9.12305C2.21875 9.33015 2.37265 9.49805 2.5625 9.49805H9.4375C9.62735 9.49805 9.78125 9.33015 9.78125 9.12305V7.24805C9.78125 7.04094 9.62735 6.87305 9.4375 6.87305Z"
        stroke="#2C2C2C"
        strokeWidth="0.785714"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_1780_74849">
        <rect
          width="11"
          height="12"
          fill="white"
          transform="translate(0.5 0.498047)"
        />
      </clipPath>
    </defs>
  </svg>
);
