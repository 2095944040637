import { OnboardingForm } from "@/components/form";

import { LetterSigner, LetterSigners } from "@/components/letterSigners";

import { SupportLetterPlaceholder } from "@/components/supportLetterPreview";
import { useOnboardingNodeData } from "@/lib/hooks";
import { useOnboarding } from "@/providers/onboardingProvider";

export const AdvisoryOpinionLetter = () => {
  const { currentOnboardingNodeId } = useOnboarding();
  const { data, doSetData } = useOnboardingNodeData(
    currentOnboardingNodeId ?? -1
  );

  return (
    <OnboardingForm heading="Support Letters">
      <span className="text-grey-300 text-[12px] mt-4 pr-12">
        This is an expert letter from a{" "}
        <span className="text-grey-200">
          US-based individual in your industry who can vouch for your overall
          professional experience and expertise in your shared field.
        </span>{" "}
        This signer does not have to have direct experience working with you.
        However, this individual should not have a financial stake in your
        upcoming employer.
      </span>

      <LetterSigners
        value={(data.signers?.value ?? []) as LetterSigner[]}
        onChange={(signers) => doSetData("signers", signers)}
      />
      <SupportLetterPlaceholder />
    </OnboardingForm>
  );
};
