import { createContext, useContext } from "react";
import { getOnboarding, subscribeToOnboardingChange } from "@/lib/queries";
import { useQuery, useSubscription } from "urql";
import { ResultOf } from "gql.tada";
import { calculateProgress, OnboardingProgress } from "@/lib/progress";
import { graphql } from "@/lib/graphql";

export type OnboardingNodeGroups = ResultOf<
  typeof getOnboarding
>["onboarding"]["groups"][0]["id"];

export type OnboardingNodes = NonNullable<
  NonNullable<ResultOf<typeof getOnboarding>["onboarding"]["groups"]>
>[0]["nodes"];

export type CaseStatus = ReturnType<typeof graphql.scalar<"ExternalStatus">>;

type OnboardingDataContextType = {
  onboardingId: number;
  visaClass: string;
  allOnboardingGroups: NonNullable<
    NonNullable<ResultOf<typeof getOnboarding>["onboarding"]["groups"]>
  >;
  progress: OnboardingProgress;
  caseStatus: CaseStatus;
  receiptNotice: string;
  trackingNumber?: string;
  publishedCase?: NonNullable<
    ResultOf<typeof getOnboarding>["onboarding"]["publishedCase"][0]
  >;
};

const OnboardingDataContext = createContext<OnboardingDataContextType | null>(
  null
);

export const OnboardingDataProvider = (props: {
  children: React.ReactNode;
  onboardingId: number;
}) => {
  const { onboardingId } = props;
  const [{ data }, refetch] = useQuery({
    query: getOnboarding,
    variables: { id: onboardingId },
  });

  useSubscription(
    {
      query: subscribeToOnboardingChange,
      variables: {
        id: onboardingId,
      },
    },
    () => {
      refetch({ requestPolicy: "network-only" });
    }
  );

  const progress = calculateProgress(data?.onboarding.groups ?? []);

  const receiptNotice = (data?.onboarding.receiptNotice ?? "").includes(
    "[UNKNOWN"
  )
    ? ""
    : (data?.onboarding.receiptNotice ?? "");

  return (
    <OnboardingDataContext.Provider
      value={{
        onboardingId,
        visaClass: data?.onboarding.visaClass ?? "",
        allOnboardingGroups:
          data?.onboarding.groups.filter((x) => x.nodes.length > 0) ?? [],
        progress,
        caseStatus: data?.onboarding.externalStatus ?? "pending_documents",
        receiptNotice,
        trackingNumber: data?.onboarding.trackingNumber ?? undefined,
        publishedCase:
          (data?.onboarding.publishedCase ?? []).length > 0
            ? data?.onboarding.publishedCase[0]
            : undefined,
      }}
    >
      {props.children}
    </OnboardingDataContext.Provider>
  );
};

export const useOnboardingData = () => {
  const context = useContext(OnboardingDataContext);
  if (!context)
    throw new Error(
      "useOnboardingData must be used within OnboardingDataProvider"
    );
  return context;
};
