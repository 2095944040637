export const MoonIcon = () => (
  <svg
    width="13"
    height="12"
    viewBox="0 0 13 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_482_6456)">
      <path
        d="M5.56766 1.31763C5.33191 2.09766 5.31219 2.92703 5.51059 3.71738C5.709 4.50774 6.1181 5.22946 6.6943 5.80567C7.27051 6.38187 7.99223 6.79097 8.78258 6.98937C9.57294 7.18778 10.4023 7.16806 11.1823 6.93231C10.9562 7.67584 10.5414 8.34813 9.97822 8.88371C9.41507 9.41928 8.72282 9.79989 7.96889 9.98845C7.21495 10.177 6.42503 10.1671 5.67607 9.95968C4.9271 9.75227 4.24461 9.35442 3.69508 8.80489C3.14555 8.25536 2.7477 7.57287 2.54029 6.8239C2.33287 6.07494 2.32296 5.28502 2.51152 4.53108C2.70008 3.77715 3.08069 3.0849 3.61626 2.52175C4.15184 1.95861 4.82413 1.54376 5.56766 1.31763Z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_482_6456">
        <rect width="12" height="12" fill="white" transform="translate(0.5)" />
      </clipPath>
    </defs>
  </svg>
);
