import { cn } from "@/lib/cn";
import {
  SidebarProgressCompleteIcon,
  SidebarProgressInProgress1Icon,
  SidebarProgressInProgress2Icon,
  SidebarProgressNotStartedIcon,
} from "./icons/sidebarProgress";
import { ComponentProps, useState } from "react";
import {
  AboutIcon,
  CompanyInfoIcon,
  DashboardIcon,
  EmployerDocumentsIcon,
  LockIcon,
  OfflineIcon,
  SupportLetterIcon,
  WorkIcon,
} from "./icons/sidebar";
import { OnboardingNodeGroups } from "@/providers/onboardingProvider";
import { Spinner } from "@radix-ui/themes";
import { LabelText } from "./typography";

import {
  DoubleArrowLeftIcon,
  DoubleArrowRightIcon,
  InfoCircledIcon,
} from "@radix-ui/react-icons";
import { create } from "zustand";
import { persist } from "zustand/middleware";
import { MarkRoundedSquareIcon } from "./icons/markRoundedSquare";
import {
  WorkplaceSelectorCollapsed,
  WorkplaceSelectorExpanded,
} from "./workspaceSelector";
import { ResultOf } from "gql.tada";
import { getCompanyWorkspace, getOnboarding } from "@/lib/queries";
import { WarningIcon } from "./icons/warning";
import { DraftIcon } from "./icons/draft";
import { InReviewIcon } from "./icons/inReview";
import { useCompany } from "@/providers/companyProvider";
import { Mark } from "./mark";
import { isMissingOrEmpty } from "@/lib/equals";
import { useOnboardingData } from "@/providers/onboardingDataProvider";
import { useNavigate, useRouterState } from "@tanstack/react-router";
import { FAQModal } from "./faqModal";

type SidebarStateType = {
  open: boolean;
  toggle: () => void;
};

export const useSidebarStore = create(
  persist<SidebarStateType>(
    (set) => ({
      open: true,
      toggle: () => set((state) => ({ open: !state.open })),
    }),
    {
      name: "lighthouse-sidebar-store",
    }
  )
);

type Option = {
  label: string;
  id: OnboardingNodeGroups;
  icon: React.ReactNode;
  status?: "completed" | "in-progress-1" | "not-started" | "in-progress-2";
};

const options: Option[] = [
  {
    label: "About you",
    id: "about_you",
    icon: <AboutIcon />,
  },
  {
    label: "Your work experience",
    id: "work_experience",
    status: "in-progress-2",
    icon: <WorkIcon />,
  },
  {
    label: "Employer Documents",
    id: "employer_documents",
    icon: <EmployerDocumentsIcon />,
  },
  {
    label: "Support letters",
    id: "support_letters",
    icon: <SupportLetterIcon />,
  },
];

const StatusIcon = (props: { status: string }) => {
  const { status } = props;

  return (
    <>
      {status === "in-progress-1" && <SidebarProgressInProgress1Icon />}
      {status === "in-progress-2" && <SidebarProgressInProgress2Icon />}
      {status === "completed" && <SidebarProgressCompleteIcon />}
      {status === "not-started" && <SidebarProgressNotStartedIcon />}
    </>
  );
};

const PulseCircleIcon = () => (
  <div className="w-[8px] h-[8px] rounded-full bg-positive/30 items-center">
    <div className="w-[4px] h-[4px] rounded-full bg-positive relative left-[2px] top-[2px]" />
  </div>
);

const AutoSaveBadge = (props: { status?: "pending" | "success" | "idle" }) => {
  const { status } = props;

  return (
    <div className="py-1 pr-2 pl-2 gap-2 flex flex-row items-center bg-positive bg-opacity-[8%] text-positive rounded-full w-fit">
      {status === "pending" ? (
        <>
          <Spinner className="animate-spin h-4 w-4" />
          <LabelText as="span" className="text-[12px]">
            Saving
          </LabelText>
        </>
      ) : (
        <>
          <PulseCircleIcon />
          <LabelText as="span" className="text-[12px] text-nowrap">
            Your progress is auto-saved
          </LabelText>
        </>
      )}
    </div>
  );
};

export const OfflineBadge = () => (
  <div className="py-1 pr-2 pl-2 gap-1 flex flex-row items-center bg-grey-600 text-grey-300 rounded-full w-fit">
    <OfflineIcon />

    <LabelText as="span" className="text-[12px] text-nowrap text-grey-200">
      You are offline
    </LabelText>

    <InfoCircledIcon />
  </div>
);

const SaveStatusIndicator = () => {
  return (
    <>
      <AutoSaveBadge status={"idle"} />
    </>
  );
};

const SidebarOption = (props: {
  icon: React.ReactNode;
  label: string;
  internalStatus?: ResultOf<
    typeof getOnboarding
  >["onboarding"]["groups"][0]["status"];
  status?: "completed" | "in-progress-1" | "not-started" | "in-progress-2";
  collapsed?: boolean;
  selected?: boolean;
  rejectedFields?: boolean;
  onClick?: () => void;
}) => {
  const {
    icon,
    label,
    status,
    collapsed = false,
    selected,
    onClick,
    internalStatus,
  } = props;

  return (
    <div
      className={cn(
        `flex flex-row items-center gap-2 p-3 h-[45px] rounded-[8px] hover:bg-grey-700`,
        selected && "bg-grey-700",
        collapsed ? "w-fit" : "w-[240px]",
        internalStatus === "submitted_for_review" && "text-grey-300"
      )}
      onClick={onClick}
    >
      <div>
        {internalStatus === "submitted_for_review" && (
          <div className="w-[9px] h-[9px] bg-white absolute translate-x-2.5 items-center justify-center flex rounded-full">
            <LockIcon />
          </div>
        )}

        {icon}
      </div>

      <span className={cn(collapsed ? "hidden" : "")}>{label}</span>

      <div
        className={cn(
          "flex flex-row gap-1 items-center",
          collapsed ? "hidden" : "ml-auto"
        )}
      >
        {internalStatus === "submitted_for_review" && <InReviewIcon />}
        {internalStatus === "rejected" && <WarningIcon />}
        {internalStatus === "pending" && <DraftIcon className="fill-none" />}

        {status && <StatusIcon status={status} />}
      </div>
    </div>
  );
};

const SidebarToggle = () => {
  const open = useSidebarStore((state) => state.open);
  const toggle = useSidebarStore((state) => state.toggle);

  return (
    <button
      className={cn(
        "transition-all duration-100 animate-slide-in ease-in-out",
        "h-[28px] w-[28px] absolute top-[30px] translate-x-[256px] rounded-r-[56px] bg-grey-800 flex items-center justify-center shadow-border",
        !open && "translate-x-[60px]"
      )}
      onClick={toggle}
    >
      {open ? (
        <DoubleArrowLeftIcon className="text-grey-300 w-3 h-3 font-bold" />
      ) : (
        <DoubleArrowRightIcon className="text-grey-300 w-3 h-3 font-bold" />
      )}
    </button>
  );
};

export const Sidebar = () => {
  const open = useSidebarStore((state) => state.open);
  const { progress, allOnboardingGroups, visaClass } = useOnboardingData();
  const nav = useNavigate();

  const [faqModalOpen, setFaqModalOpen] = useState(false);

  const availableOptions = options.filter((option) => {
    return allOnboardingGroups
      .filter((x) => x.nodes.length > 0)
      .map((x) => x.id)
      .includes(option.id);
  });

  const getStatus = (group: OnboardingNodeGroups) => {
    const groupProgress = progress[group];
    if (groupProgress == null)
      return {
        status: "not-started",
        rejectedFields: false,
      };

    if (groupProgress.completedNodes === groupProgress.totalNodes)
      return {
        status: "completed",
        rejectedFields: false,
      };

    let fieldsCompleted = 0;
    let fieldsTotal = 0;
    let rejectedFields = 0;

    for (const node of Object.keys(groupProgress.nodeProgress)) {
      fieldsCompleted +=
        groupProgress.nodeProgress[parseInt(node)].completedFields;
      fieldsTotal += groupProgress.nodeProgress[parseInt(node)].totalFields;
      rejectedFields +=
        groupProgress.nodeProgress[parseInt(node)].rejectedFieldKeys.length;
    }

    return {
      status:
        fieldsCompleted > 0.5 * fieldsTotal ? "in-progress-2" : "in-progress-1",
      rejectedFields: rejectedFields > 0,
    };
  };

  return (
    <div
      className={cn(
        "flex flex-col gap-2 h-full w-[256px]",
        !open && "w-[60px]"
      )}
    >
      <FAQModal
        open={faqModalOpen}
        setOpen={setFaqModalOpen}
        visaClass={visaClass}
      />
      <div className={cn("h-full flex flex-col", !open && "hidden")}>
        <div className="flex flex-row gap-[12px] p-[16px] h-[90px] items-center">
          <Mark />
          <h1 className="font-serif font-[600] leading-[24px] text-base tracking-[-0.5%]">
            Lighthouse
          </h1>
        </div>
        <SidebarToggle />

        <div className="flex flex-col gap-1 px-2 text-[14px] font-normal">
          <div className="border-b-[0.8px] border-b-grey-600 mx-1 pb-1">
            <WorkplaceSelectorExpanded />
          </div>

          <SidebarOption
            icon={<DashboardIcon />}
            label="Home"
            selected={window.location.pathname.endsWith("/home")}
            onClick={() => nav({ to: "/home" })}
          />

          {availableOptions.map((option) => {
            const { status, rejectedFields } = getStatus(option.id);

            return (
              <SidebarOption
                key={option.id}
                {...option}
                status={
                  status as ComponentProps<typeof SidebarOption>["status"]
                }
                internalStatus={
                  allOnboardingGroups.find((x) => x.id === option.id)?.status
                }
                rejectedFields={rejectedFields}
                selected={window.location.pathname.endsWith(
                  `/group/${option.id}`
                )}
                onClick={() => nav({ to: `/group/${option.id}` })}
              />
            );
          })}
        </div>

        <div className="mt-auto flex flex-col h-1/6 px-6 pt-4 pb-6 gap-4 justify-end">
          <SaveStatusIndicator />

          <div className="w-full flex flex-row justify-between items-center">
            <div
              onClick={() => setFaqModalOpen(true)}
              className="cursor-pointer"
            >
              <LabelText as="span" className="text-[12px] text-grey-400">
                Need some help?
              </LabelText>
            </div>

            {/* <ThemePicker /> */}
          </div>
        </div>
      </div>

      <div className={cn("h-full w-[60px] flex-col hidden", !open && "inline")}>
        <div className="flex flex-row gap-[12px] p-[16px] h-[90px] items-center justify-center">
          <MarkRoundedSquareIcon key={`mark-${open}`} />
        </div>
        <SidebarToggle />

        <div className="flex flex-col w-full items-center gap-1 px-2 text-[14px] font-normal">
          <div className="flex flex-col gap-1">
            <WorkplaceSelectorCollapsed />
            <SidebarOption
              icon={<DashboardIcon />}
              label="Home"
              collapsed
              selected={window.location.pathname.endsWith("/home")}
              onClick={() => nav({ to: "/home" })}
            />

            <div className="border-b-[0.8px] border-b-grey-600  mx-1" />
          </div>

          {availableOptions.map((option) => (
            <SidebarOption
              key={option.id}
              {...option}
              collapsed
              selected={window.location.pathname.endsWith(
                `/group/${option.id}`
              )}
              onClick={() =>
                nav({ to: `/group/$groupId`, params: { groupId: option.id } })
              }
            />
          ))}
        </div>
      </div>
    </div>
  );
};

const companySidebarOptions = [
  {
    label: "Home",
    path: "/home",
    icon: <AboutIcon />,
  },
  {
    label: "Company Details",
    path: "/company-info",
    icon: <CompanyInfoIcon />,
  },
  {
    label: "Applicants",
    path: "/applicants",
    icon: <AboutIcon />,
  },
];

export const getCompletedFields = (
  data: ResultOf<typeof getCompanyWorkspace>
): {
  files: { total: number; completed: number };
  entityData: { total: number; completed: number };
} => {
  const entityData = data?.getCompanyWorkspace.entityData;
  const files = data?.getCompanyWorkspace.files;

  const keysToCheck = [
    "legalName",
    "ein",
    "currentNumberOfUSFullTimeEmployees",
    "grossAnnualIncome",
    "netAnnualIncome",
    "mailingAddress",
  ];

  const filesToCheck = [
    ["articles-of-incorporation", "company-bylaws"],
    ["tax-id-fein-document"],
    ["bank-statement"],
    ["safe-documentation"],
    ["company-overview"],
  ];

  let fieldsCompleted = 0;
  let filesCompleted = 0;

  for (const key of keysToCheck) {
    if (isMissingOrEmpty(entityData[key])) continue;
    if (isMissingOrEmpty(entityData[key])) continue;
    fieldsCompleted += 1;
  }

  for (const orFiles of filesToCheck) {
    const fileFiltered = files.filter((file) =>
      orFiles.includes(file.subKey ?? "")
    );

    if (fileFiltered.length > 0) {
      filesCompleted += 1;
    }
  }

  return {
    files: {
      total: filesToCheck.length,
      completed: filesCompleted,
    },
    entityData: {
      total: keysToCheck.length,
      completed: fieldsCompleted,
    },
  };
};

const checkCompanyInfoStatus = (
  data: ResultOf<typeof getCompanyWorkspace>
): "completed" | "in-progress-2" | "not-started" => {
  const entityData = data?.getCompanyWorkspace.entityData;
  const files = data?.getCompanyWorkspace.files;

  const keysToCheck = [
    "legalName",
    "ein",
    "currentNumberOfUSFullTimeEmployees",
    "grossAnnualIncome",
    "netAnnualIncome",
    "mailingAddress",
  ];

  const filesToCheck = [
    ["articles-of-incorporation", "company-bylaws"],
    ["tax-id-fein-document"],
    ["bank-statement"],
    ["safe-documentation"],
    ["company-overview"],
  ];

  let totalCompleted = 0;

  for (const key of keysToCheck) {
    if (!isMissingOrEmpty(entityData[key])) {
      totalCompleted += 1;
    }
  }

  for (const orFiles of filesToCheck) {
    const fileFiltered = files.filter((file) =>
      orFiles.includes(file.subKey ?? "")
    );
    if (fileFiltered.length > 0) {
      totalCompleted += 1;
    }
  }

  if (totalCompleted >= keysToCheck.length + filesToCheck.length)
    return "completed";

  return "in-progress-2";
};

export const CompanySidebar = () => {
  const open = useSidebarStore((state) => state.open);
  const { data } = useCompany();

  const router = useRouterState();
  const nav = useNavigate();

  return (
    <div
      className={cn(
        "flex flex-col gap-2 h-full w-[256px]",
        !open && "w-[60px]"
      )}
    >
      <div className={cn("h-full flex flex-col", !open && "hidden")}>
        <div className="flex flex-row gap-[12px] p-[16px] h-[90px] items-center">
          <Mark />
          <h1 className="font-serif font-[600] leading-[24px] text-base tracking-[-0.5%]">
            Lighthouse
          </h1>
        </div>
        <SidebarToggle />

        <div className="flex flex-col gap-1 px-2 text-[14px] font-normal">
          <div className="flex flex-col gap-1">
            <WorkplaceSelectorExpanded />

            <div className="border-b-[0.8px] border-b-grey-600  mx-1" />
          </div>
          {companySidebarOptions.map((option) => {
            return (
              <SidebarOption
                key={option.path}
                {...option}
                selected={router.location.pathname == option.path}
                onClick={() => nav({ to: option.path })}
                status={
                  option.path === "/company-info" && data != null
                    ? checkCompanyInfoStatus(data)
                    : undefined
                }
              />
            );
          })}
        </div>

        <div className="mt-auto flex flex-col h-1/6 px-6 pt-4 pb-6 gap-4 justify-end">
          <SaveStatusIndicator />

          <div className="w-full flex flex-row justify-between items-center">
            <a
              href="mailto:support@lighthousehq.com"
              className="cursor-pointer"
            >
              <LabelText as="span" className="text-[12px] text-grey-400">
                Need some help?
              </LabelText>
            </a>

            {/* <ThemePicker /> */}
          </div>
        </div>
      </div>

      <div className={cn("h-full w-[60px] flex-col hidden", !open && "inline")}>
        <div className="flex flex-row gap-[12px] p-[16px] h-[90px] items-center justify-center">
          <MarkRoundedSquareIcon key={`mark-${open}`} />
        </div>
        <SidebarToggle />

        <div className="flex flex-col w-full items-center gap-1 px-2 text-[14px] font-normal">
          <div className="flex flex-col gap-1 w-full justify-center">
            <WorkplaceSelectorCollapsed />
            <div className="border-b-[0.8px] border-b-grey-600  mx-1" />
          </div>
          {companySidebarOptions.map((option) => {
            return (
              <SidebarOption
                key={option.path}
                {...option}
                selected={router.location.pathname == option.path}
                onClick={() => nav({ to: option.path })}
                collapsed
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};
