import { Field, OnboardingForm } from "@/components/form";
import { useOnboarding } from "@/providers/onboardingProvider";
import { Spinner } from "@radix-ui/themes";
import { Select } from "@/components/select";
import { Checkbox, DateInput } from "@/components/inputs";
import { useOnboardingNodeData } from "@/lib/hooks";

const visaSelectOptions = [
  { value: "F-1", label: "F-1" },
  { value: "O-1A", label: "O-1A" },
  { value: "H-1B", label: "H-1B" },
  { value: "E-3", label: "E-3" },
  { value: "B1", label: "B1" },
  { value: "B2", label: "B2" },
  { value: "E-2", label: "E-2" },
  { value: "TN", label: "TN" },
  { value: "J-1", label: "J-1" },
];

export const CurrentVisa = () => {
  const { currentOnboardingNodeId, groupStatus } = useOnboarding();
  const { data, fetching, error, doSetData } = useOnboardingNodeData(
    currentOnboardingNodeId ?? -1
  );

  return (
    <OnboardingForm heading="Your personal details and visa background">
      {fetching && data == null && <Spinner />}

      {error != null && (
        <div className="text-sm text-negative">
          Error loading personal details, please contact
          support@lighthousehq.com for assistance.
        </div>
      )}

      {data != null && !fetching && (
        <>
          <Field id="currentVisa" label="What US visa are you currently on?">
            <Select
              value={data.currentVisa?.value}
              onValueChange={(value) => doSetData("currentVisa", value)}
              options={visaSelectOptions}
              disabled={data.noValidVisa?.value}
              placeholder="Select a visa type"
            />
          </Field>

          <div
            id="field-noValidVisa"
            className="flex flex-row gap-1.5 items-center"
          >
            <Checkbox
              checked={data.noValidVisa?.value}
              onCheckedChange={(value) => {
                doSetData("noValidVisa", value, true);
              }}
              label="I do not have any active visa"
              disabled={groupStatus === "submitted_for_review"}
            />
          </div>

          <Field id="visaExpiryDate" label="Expiry Date">
            <DateInput
              value={
                data.visaExpiryDate?.value != null
                  ? new Date(data.visaExpiryDate.value)
                  : undefined
              }
              onValueChange={(value) =>
                doSetData("visaExpiryDate", value?.toISOString())
              }
              placeholder="Expiry Date"
              disabled={data.noValidVisa?.value}
            />
          </Field>
        </>
      )}
    </OnboardingForm>
  );
};
