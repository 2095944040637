import { useEffect, useState } from "react";
import { InputText, LabelText } from "./typography";
import { cn } from "@/lib/cn";
import { useCompany } from "@/providers/companyProvider";
import { CountryAddressInput, CountryIcon, Input } from "./inputs";
import { useDebounce, useWorkspaces } from "@/lib/hooks";
import {
  getCompanyWorkspace,
  subscribeToCompanyFileChange,
  unlinkFileMutation,
  updateCompanyData,
  useCompanyFileUploadMutation,
} from "@/lib/queries";
import { useMutation, useQuery, useSubscription } from "urql";
import { Spinner } from "@radix-ui/themes";
import { FileUploadArea } from "./fileUploadArea";
import { useNavigate } from "@tanstack/react-router";
import { ResultOf } from "@/lib/graphql";
import { isEqual } from "@/lib/equals";

export type CompanyInfoTabs = "general" | "documents";

const Field = (props: {
  id?: string;
  label?: string;
  description?: string;
  children: React.ReactNode;
  className?: string;
  disabled?: boolean;
}) => {
  return (
    <div
      className={cn("flex flex-col gap-[1px] w-full", props.className)}
      id={`field-${props.id}`}
    >
      {(props.label != null || props.description != null) && (
        <div className="py-3 pr-8 pl-1.5 gap-[4px] flex flex-col">
          {props.label != null && (
            <LabelText
              className={cn(
                "flex flex-row gap-1 items-center text-grey-300 text-[12px]"
              )}
              as="span"
            >
              {props.label}
            </LabelText>
          )}

          {props.description && (
            <LabelText as="span" className="text-grey-400 text-[12px]">
              {props.description}
            </LabelText>
          )}
        </div>
      )}

      <div className={cn("flex flex-row items-center w-full")}>
        <div
          className={cn(
            "w-full",
            props.disabled && "pointer-events-none opacity-50"
          )}
          tabIndex={-1}
        >
          {props.children}
        </div>
      </div>
    </div>
  );
};

const AddressField = (props: {
  header: string;
  description?: string;
  currentAddress: Record<string, string>;
  doSetAddressKey: (key: string, value?: string) => void;
}) => {
  const { currentAddress, doSetAddressKey } = props;
  return (
    <div className="flex flex-col bg-grey-700 rounded-lg pt-1.5 pb-4 pl-3 mt-2">
      <div className="flex flex-col gap-1 pt-3 pb-3 pl-1.5">
        <InputText className="text-grey-300 text-[12px]">
          {props.header}
        </InputText>
        {props.description && (
          <LabelText as="span" className="text-grey-400 text-[12px]">
            {props.description}
          </LabelText>
        )}
        <div id="field-currentAddress" className="flex flex-col gap-2">
          <Field label="Address" className="pr-4">
            <Input
              placeholder="123 Main St"
              type="text"
              value={currentAddress.street ?? ""}
              onValueChange={(val) => doSetAddressKey("street", val)}
              autoComplete={"street-address"}
            />
          </Field>
          <Field label="City" className="pr-4">
            <Input
              placeholder="San Francisco"
              type="text"
              value={currentAddress.city ?? ""}
              onValueChange={(val) => doSetAddressKey("city", val)}
              autoComplete={"address-level2"}
            />
          </Field>
          <div className="flex flex-row gap-2 w-full">
            <Field label="State" className="pr-4">
              <Input
                placeholder="California"
                type="text"
                value={currentAddress.state ?? ""}
                onValueChange={(val) => doSetAddressKey("state", val)}
                autoComplete={"address-level1"}
              />
            </Field>
            <Field label="ZIP / Postal Code" className="pr-4">
              <Input
                placeholder="94102"
                type="text"
                value={currentAddress.zip ?? ""}
                onValueChange={(val) => doSetAddressKey("zip", val)}
                autoComplete={"postal-code"}
              />
            </Field>
          </div>
          <Field label="Country" className="pr-4">
            <CountryAddressInput
              value={currentAddress.country}
              onValueChange={(val) => doSetAddressKey("country", val as string)}
            />
          </Field>
        </div>
      </div>
    </div>
  );
};

const IncomeInput = (props: {
  value?: string;
  onValueChange?: (value: string) => void;
}) => {
  return (
    <div className="flex flex-row w-full group bg-white rounded-md has-[:focus]:shadow-border-active shadow-border transition-all duration-150">
      <div className="bg-grey-700 flex flex-row items-center gap-0.25 rounded-l-md pl-1 pr-3 py-1 text-grey-300 text-xs">
        <CountryIcon country="US" />
        USD
      </div>
      <input
        value={props.value ?? ""}
        onChange={(e) => props.onValueChange?.(e.target.value)}
        type="text"
        placeholder="0.0"
        className={cn(
          "w-full rounded-r-md px-3 py-2 bg-white",
          "placeholder:text-grey-500 placeholder:text-sm placeholder:leading-[140%] placeholder:font-normal",
          "text-sm leading-[140%] font-normal",
          "disabled:bg-grey-700 disabled:text-grey-500 disabled:placeholder:text-grey-500",
          "focus:outline-none"
        )}
      />
    </div>
  );
};

const GeneralFieldEditor = (props: {
  parsedData: Record<string, unknown>;
  doSetData: (
    key: string,
    value?:
      | string
      | boolean
      | Record<string, string>
      | string[]
      | object
      | number
  ) => void;
}) => {
  const { parsedData, doSetData } = props;

  return (
    <div className="flex flex-col gap-3 mt-1">
      <Field
        id="legalName"
        label="Company legal name"
        description="This is the company's entity name, and likely ends with 'LLC' or 'Inc.'"
      >
        <Input
          type="text"
          value={(parsedData.legalName as string) ?? ""}
          onValueChange={(e) => doSetData("legalName", e)}
          className="w-full"
          placeholder={"e.g 'Lighthouse Inc.' or 'Lighthouse LLC'"}
        />
      </Field>

      <Field
        id="ein"
        label="Employer Identification Number (EIN)"
        description="If you have an existing EIN from the IRS, please enter it here."
      >
        <Input
          type="text"
          value={(parsedData.ein as string) ?? ""}
          onValueChange={(e) => doSetData("ein", e)}
          className="w-full"
          placeholder={"e.g. 12-3456789"}
        />
      </Field>

      <Field
        id="currentNumberOfUSFullTimeEmployees"
        label="Total number of US full-time employees"
        description="This helps us determine which filing fees are applicable. For companies of >25 US FTEs, USCIS filing fees are higher."
      >
        <Input
          type="number"
          value={
            (parsedData?.currentNumberOfUSFullTimeEmployees as string) ?? ""
          }
          onValueChange={(e) =>
            doSetData("currentNumberOfUSFullTimeEmployees", parseInt(e))
          }
          min={0}
          placeholder="0"
          className="w-full"
        />
      </Field>

      <Field
        id="grossAnnualIncome"
        label="Gross annual income"
        description="If N/A, please indicate 0.0"
      >
        <IncomeInput
          value={(parsedData?.grossAnnualIncome as string) ?? ""}
          onValueChange={(e) => doSetData("grossAnnualIncome", e)}
        />
      </Field>

      <Field
        id="netAnnualIncome"
        label="Net annual income"
        description="If N/A, please indicate 0.0"
      >
        <IncomeInput
          value={(parsedData?.netAnnualIncome as string) ?? ""}
          onValueChange={(e) => doSetData("netAnnualIncome", e)}
        />
      </Field>

      <AddressField
        header="Mailing Address"
        description="Mailing address is where we will send your filing documents."
        currentAddress={
          (parsedData.mailingAddress ?? {}) as Record<string, string>
        }
        doSetAddressKey={(key: string, value?: string) =>
          doSetData("mailingAddress", {
            ...((parsedData.mailingAddress as object | undefined) ?? {}),
            [key]: value,
          })
        }
      />
    </div>
  );
};

const General = () => {
  const { data, fetching } = useCompany();

  const [parsedData, setParsedData] = useState<Record<string, unknown>>(
    data?.getCompanyWorkspace.entityData ?? {}
  );

  const debouncedParsedData = useDebounce(parsedData, 500);
  const mutation = useMutation(updateCompanyData)[1];

  const doSetData = async (
    key: string,
    value?:
      | string
      | boolean
      | Record<string, string>
      | string[]
      | object
      | number
  ) => {
    if (data == null) return;

    setParsedData({
      ...parsedData,
      [key]: value,
    });
    return;
  };

  useEffect(() => {
    if (data?.getCompanyWorkspace.id == null) {
      return;
    }
    if (
      debouncedParsedData == null ||
      Object.keys(debouncedParsedData).length === 0
    ) {
      return;
    }

    if (
      isEqual(debouncedParsedData, data?.getCompanyWorkspace.entityData) ||
      isEqual(data.getCompanyWorkspace.entityData, debouncedParsedData)
    ) {
      return;
    }

    mutation({
      input: {
        data: debouncedParsedData,
        companyId: data?.getCompanyWorkspace.id,
      },
    });
  }, [debouncedParsedData, data?.getCompanyWorkspace.id]);

  return (
    <div
      className="flex flex-col gap-3"
      key={`company-info-general-${data?.getCompanyWorkspace.id}`}
    >
      {fetching && <Spinner />}
      {!fetching && data != null && (
        <GeneralFieldEditor
          parsedData={parsedData}
          doSetData={doSetData}
          key={`company-info-${data?.getCompanyWorkspace.id}`}
        />
      )}
    </div>
  );
};

const SubkeyUploadArea = (props: {
  companyId: number;
  subKey: string;
  companyEntityId: number;
}) => {
  const { companyId, subKey, companyEntityId } = props;

  const [{ data }, refetch] = useQuery({
    query: getCompanyWorkspace,
    variables: { id: companyId },
    requestPolicy: "cache-and-network",
  });

  useSubscription(
    {
      query: subscribeToCompanyFileChange,
      variables: { companyId },
    },
    () => refetch({ requestPolicy: "network-only" })
  );

  const uploadFiles = useCompanyFileUploadMutation();
  const removeMutation = useMutation(unlinkFileMutation)[1];

  const onUpload = async (files: File[]) => {
    if (files.length == 0) return;
    uploadFiles(files, companyEntityId, subKey);
    refetch({ requestPolicy: "network-only" });
  };

  const doRemove = (fileId: number) => {
    removeMutation({
      input: {
        fileId: fileId,
        linkType: "entity",
        linkRecordId: companyEntityId,
        linkSubKey: subKey,
      },
    });

    refetch({ requestPolicy: "network-only" });
  };

  const subKeyFiles =
    (data?.getCompanyWorkspace.files ?? []).filter(
      (file) => file.subKey === subKey
    ) ?? [];

  return (
    <FileUploadArea
      onUpload={onUpload}
      files={subKeyFiles.map((file) => ({
        id: file.id,
        name: file.name,
        mimeType: file.mimeType,
        size: file.size ?? undefined,
      }))}
      onRemove={doRemove}
      key={`upload-area-${companyId}-${subKey}`}
      multiple
    />
  );
};

const DocumentsFieldEditor = (props: {
  data: ResultOf<typeof getCompanyWorkspace>;
}) => {
  const { data } = props;

  return (
    <div className="flex flex-col gap-3 mt-1 w-full h-full">
      <Field
        id="articles-of-incorporation"
        label="Upload Articles of Incorporation"
        description="These documents help us show USCIS that the employer is an operational entity."
      >
        <SubkeyUploadArea
          companyId={data?.getCompanyWorkspace.id}
          subKey="articles-of-incorporation"
          companyEntityId={data?.getCompanyWorkspace.entityId}
        />
      </Field>
      <Field
        id="tax-id-fein-document"
        label="Upload FEIN/EIN letter"
        description="These documents help us show USCIS that the employer is an operational entity."
      >
        <SubkeyUploadArea
          companyId={data?.getCompanyWorkspace.id}
          subKey="tax-id-fein-document"
          companyEntityId={data?.getCompanyWorkspace.entityId}
        />
      </Field>
      <Field
        id="bank-statement"
        label="Upload bank statement"
        description="These documents help us show USCIS that the employer is an operational entity."
      >
        <SubkeyUploadArea
          companyId={data?.getCompanyWorkspace.id}
          subKey="bank-statement"
          companyEntityId={data?.getCompanyWorkspace.entityId}
        />
      </Field>
      <Field
        id="company-overview"
        label="Upload documentation about your company"
        description="This is a summary of your company's products and services. This can be a pitch deck, or marketing materials."
      >
        <SubkeyUploadArea
          companyId={data?.getCompanyWorkspace.id}
          subKey="company-overview"
          companyEntityId={data?.getCompanyWorkspace.entityId}
        />
      </Field>
      <Field
        id="safe-documentation"
        label="Upload SAFE documentation (optional)"
        description="If you have SAFE documentation, please upload it here."
      >
        <SubkeyUploadArea
          companyId={data?.getCompanyWorkspace.id}
          subKey="safe-documentation"
          companyEntityId={data?.getCompanyWorkspace.entityId}
        />
      </Field>
      <Field
        id="company-bylaws"
        label="Upload company bylaws (optional)"
        description="Upload your company bylaws, if you don't have articles of incorporation."
      >
        <SubkeyUploadArea
          companyId={data?.getCompanyWorkspace.id}
          subKey="company-bylaws"
          companyEntityId={data?.getCompanyWorkspace.entityId}
        />
      </Field>
    </div>
  );
};

const Documents = () => {
  const { data, fetching } = useCompany();

  return (
    <div className="flex flex-col gap-3 mt-1">
      {fetching && <Spinner />}

      {!fetching && data != null && <DocumentsFieldEditor data={data} />}
    </div>
  );
};

export const CompanyInfo = (props: { tab?: CompanyInfoTabs }) => {
  const nav = useNavigate();
  const tab = props.tab ?? "general";

  const setTab = (tab: CompanyInfoTabs) => {
    if (tab == "general") {
      nav({ to: "/company-info" });
      return;
    }
    nav({ to: `/company-info`, search: { tab } });
  };

  const { selectedWorkspace } = useWorkspaces();

  return (
    <div className="w-full bg-grey-800 h-full flex flex-col items-center rounded-xl overflow-y-scroll">
      <div className="w-3/5 h-full flex flex-col mt-3 py-8 px-12">
        <div className="font-medium text-lg text-grey-100">
          About your company
        </div>
        <LabelText as="p" className="mt-3 text-sm text-grey-300">
          We need to know a few things about your company before we can file.
        </LabelText>

        <div className="mt-4 flex flex-row gap-3 items-center border-b border-grey-600">
          <button
            className={cn(
              "flex flex-row items-center px-3 py-1 text-sm font-medium text-grey-300 border-b border-[transparent]",
              tab === "general" && "text-grey-100 border-grey-100"
            )}
            onClick={() => setTab("general")}
          >
            General
          </button>
          <button
            className={cn(
              "flex flex-row items-center px-3 py-1 text-sm font-medium text-grey-300 border-b border-[transparent]",
              tab === "documents" && "text-grey-100 border-grey-100"
            )}
            onClick={() => setTab("documents")}
          >
            Documents
          </button>
        </div>

        <div className="pt-4 pb-12">
          {tab === "general" && (
            <General key={`company-general-${selectedWorkspace?.id}`} />
          )}
          {tab === "documents" && (
            <Documents key={`company-documents-${selectedWorkspace?.id}`} />
          )}
        </div>
      </div>
    </div>
  );
};
