import { cn } from "@/lib/cn";
import { ButtonText } from "./typography";
import { Spinner } from "@radix-ui/themes";
import { cva } from "class-variance-authority";

type ButtonType = "primary" | "secondary" | "action" | "tooltip";

export const shadCnbuttonVariants = cva(
  "inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50",
  {
    variants: {
      variant: {
        default: "bg-primary text-primary-foreground hover:bg-primary/90",
        destructive:
          "bg-destructive text-destructive-foreground hover:bg-destructive/90",
        outline:
          "border border-input bg-background hover:bg-accent hover:text-accent-foreground",
        secondary:
          "bg-secondary text-secondary-foreground hover:bg-secondary/80",
        ghost: "hover:bg-accent hover:text-accent-foreground",
        link: "text-primary underline-offset-4 hover:underline",
      },
      size: {
        default: "h-10 px-4 py-2",
        sm: "h-9 rounded-md px-3",
        lg: "h-11 rounded-md px-8",
        icon: "h-10 w-10",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "default",
    },
  },
);

export const Button = (props: {
  variant: ButtonType;
  children: React.ReactNode;
  disabled?: boolean;
  loading?: boolean;
  onClick?: () => void;
  className?: string;
}) => {
  const styles: Record<ButtonType, string> = {
    primary:
      "bg-grey-100 text-grey-800 hover:bg-grey-200 shadow-primary-button",
    secondary: "bg-grey-800 text-grey-100 hover:bg-[#E9E9E9]",
    action:
      "bg-[#F4BBB9] text-[#3F3651] hover:bg-[#F0B1AF] shadow-action-button",
    tooltip:
      "rounded-full px-1 py-1 bg-transparent hover:bg-grey-700 shadow-none",
  };

  return (
    <button
      className={cn(
        "px-3 py-2 gap-2 flex flex-row rounded-[8px] hover:cursor-pointer disabled:cursor-not-allowed shadow-button w-fit disabled:bg-grey-700 disabled:text-grey-500 transition-all duration-300",
        props.variant === "primary" && styles.primary,
        props.variant === "secondary" && styles.secondary,
        props.variant === "action" && styles.action,
        props.variant === "tooltip" && styles.tooltip,
        props.disabled && "shadow-button",
        props.className,
      )}
      disabled={props.loading || props.disabled}
      onClick={props.onClick}
    >
      {props.loading ? (
        <Spinner className="animate-spin h-4 w-4" />
      ) : (
        <ButtonText className="flex flex-row gap-2 items-center w-full">
          {props.children}
        </ButtonText>
      )}
    </button>
  );
};
