import { useAuth } from "@clerk/clerk-react";
import {
  MutableRefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";
import {
  getOnboardingContext,
  getOnboardingNode,
  getUserContext,
  patchUserEntityMutation,
  subscribeToOnboardingNodeChange,
  updateOnboardingNode,
} from "./queries";
import { useMutation, useQuery, useSubscription } from "urql";
import { parseFormValues, FormValues } from "./formValues";
import { useOnboarding } from "@/providers/onboardingProvider";
import { ResultOf } from "gql.tada";
import { isEqual } from "./equals";
import { PhoneNumber } from "@/components/inputs";
import { useNavigate } from "@tanstack/react-router";
import {
  HocuspocusProvider,
  onOutgoingMessageParameters,
} from "@hocuspocus/provider";
import { Editor } from "@tiptap/react";
import * as Y from "yjs";

declare global {
  interface Window {
    lighthouse: {
      overrideUserId: (userId: number) => void;
      clearOverrideUserId: () => void;
      token: () => void;
    };
  }
}

type AdminStateType = {
  overrideUserId: number | null;
  setOverrideUserId: (value: number | null) => void;
};

export const useAdminStore = create(
  persist<AdminStateType>(
    (set) => ({
      overrideUserId: null,
      setOverrideUserId: (value: number | null) =>
        set({ overrideUserId: value }),
    }),
    {
      name: "lighthouse-admin-store", // name of the item in the storage (must be unique)
      storage: createJSONStorage(() => localStorage), // (optional) by default, 'localStorage' is used
    }
  )
);

export const useAdminFunctions = () => {
  const { getToken } = useAuth();
  const overrideUserId = useAdminStore((state) => state.setOverrideUserId);

  // eslint-disable-next-line react-compiler/react-compiler
  window.lighthouse = {
    overrideUserId: (userId: number) => {
      overrideUserId(userId);
    },
    clearOverrideUserId: () => {
      overrideUserId(null);
    },
    token: async () => {
      const token = await getToken();
      if (token == null) return null;
      console.log(token);
    },
  };
};

export const useDebounce = <T>(value: T, delay: number): T | undefined => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
};

export const useAdminOverrideUserId = () => {
  const { overrideUserId } = useAdminStore((state) => state);
  return overrideUserId;
};

export const useUserContext = (opts?: {
  requestPolicy?: "cache-and-network" | "cache-first" | "network-only";
}) => {
  const { overrideUserId } = useAdminStore((state) => state);

  const [{ data, fetching }] = useQuery({
    query: getUserContext,
    variables: {
      userContextInput: {
        overrideUserId,
      },
    },
    requestPolicy: opts?.requestPolicy ?? "cache-and-network",
  });

  const [userEntity, setUserEntity] = useState<
    ResultOf<typeof getUserContext>["context"]["userEntity"] | undefined
  >(data?.context.userEntity);

  const doPatchUserEntity = useMutation(patchUserEntityMutation)[1];

  const debouncedUserEntity = useDebounce(userEntity, 150);

  useEffect(() => {
    if (debouncedUserEntity == null) return;

    const doUpdateUserEntity = async () => {
      if (data?.context.userEntity == null) return;

      for (const key in debouncedUserEntity) {
        const typedKey = key as keyof typeof debouncedUserEntity;
        if (
          debouncedUserEntity[typedKey] === data.context.userEntity[typedKey]
        ) {
          continue;
        }

        const { error } = await doPatchUserEntity({
          input: {
            userId: data.context.userEntity.id,
            data: {
              [typedKey]: debouncedUserEntity[typedKey],
            },
          },
        });

        if (error != null) {
          console.error(error);
          return;
        }
      }
    };

    doUpdateUserEntity();
  }, [debouncedUserEntity]);

  const doUpdateUserEntity = (
    key: keyof NonNullable<typeof userEntity>,
    value: string | PhoneNumber
  ) => {
    setUserEntity(
      (prev) =>
        ({
          ...(prev ?? {}),
          [key]: value,
        }) as ResultOf<typeof getUserContext>["context"]["userEntity"]
    );
  };

  return {
    loggedInUserId: data?.context.loggedInCustomerUserId,
    userId: data?.context.contextUserId,
    fetching,
    userEntity,
    doUpdateUserEntity,
  };
};

export const useOnboardingNodeData = (id: number) => {
  const [{ data, error, fetching }, refetch] = useQuery({
    query: getOnboardingNode,
    variables: { id: id },
    pause: id == null || id === -1,
  });

  const mutation = useMutation(updateOnboardingNode)[1];
  const [parsedData, setParsedData] = useState<FormValues>(
    parseFormValues(data?.onboardingNode.content ?? {})
  );

  const debouncedData = useDebounce(parsedData, 500);

  const doSetData = async (
    key: string,
    value?:
      | string
      | boolean
      | number
      | Record<string, string>
      | string[]
      | object,
    clear?: boolean
  ) => {
    if (data == null) return;

    if (clear) {
      setParsedData({
        [key]: {
          value: value,
          status: "not-submitted",
          rejectionReason: undefined,
        },
      });
      return;
    }

    setParsedData({
      ...parsedData,
      [key]: {
        ...(parsedData[key] ?? {}),
        value: value,
        status:
          parsedData[key]?.status === "not-submitted"
            ? "submitted"
            : parsedData[key]?.status,
      },
    });
    return;
  };

  useEffect(() => {
    if (data?.onboardingNode.content == null) return;
    if (debouncedData == null) return;
    if (isEqual(data.onboardingNode.content, debouncedData)) return;

    mutation({
      input: {
        onboardingNodeId: id,
        data: {
          ...debouncedData,
        },
      },
    });
    return;
  }, [debouncedData, id, mutation]);

  useSubscription(
    {
      query: subscribeToOnboardingNodeChange,
      variables: { id: id },
      pause: id == null,
    },
    () => {
      refetch({ requestPolicy: "cache-and-network" });
    }
  );

  return {
    data: parsedData,
    fetching,
    error,
    doSetData,
    customerCreated: data?.onboardingNode.customerCreated ?? false,
  };
};

export const useDataField = (
  key: string
): FormValues[string] & { disabled: boolean } => {
  const { currentOnboardingNodeId, groupStatus } = useOnboarding();
  const { data } = useOnboardingNodeData(currentOnboardingNodeId ?? -1);

  return {
    value: data[key]?.value,
    status: data[key]?.status,
    rejectionReason: data[key]?.rejectionReason,
    disabled: groupStatus === "submitted_for_review",
  };
};

const computeRect = (element: HTMLElement | null) => {
  if (element == null) return { width: 0, height: 0, x: 0, y: 0 };

  const { width, height, x, y } = element.getBoundingClientRect();

  return { width, height, x, y };
};

export const useResizeObserver = (
  ref: MutableRefObject<HTMLElement | null>
) => {
  const [rect, setRect] = useState(computeRect(ref.current));

  useEffect(() => {
    if (ref.current == null) return;

    setRect(computeRect(ref.current))

    const ob = new ResizeObserver(() => setRect(computeRect(ref.current)));
    ob.observe(ref.current);

    return () => ob.disconnect();
  }, [ref]);

  return rect;
};

type Workspace = {
  id: string;
  name: string;
  onboardings: {
    id: number;
  }[];
};

type WorkspaceStateType = {
  selectedWorkspace?: Workspace;
  setSelectedWorkspace: (workspace?: Workspace) => void;
};

export const useWorkspaceStore = create(
  persist<WorkspaceStateType>(
    (set) => ({
      setSelectedWorkspace: (value?: Workspace) => {
        set({ selectedWorkspace: value });
      },
    }),
    {
      name: "lighthouse-workspace-store",
      storage: createJSONStorage(() => sessionStorage),
    }
  )
);

export const useWorkspaces = () => {
  const overrideUserId = useAdminOverrideUserId();
  const nav = useNavigate();
  const { selectedWorkspace, setSelectedWorkspace } = useWorkspaceStore();

  const [{ data, fetching }, refetch] = useQuery({
    query: getOnboardingContext,
    variables: {
      contextInput: {
        overrideUserId,
      },
    },
    requestPolicy: "cache-and-network",
  });

  const doSetSelectedWorkspace = (workspace?: Workspace) => {
    setSelectedWorkspace(workspace);
    nav({ to: `/home` });
  };

  useEffect(() => {
    if (data?.context?.workspaces == null) return;
    if (data.context.workspaces.length === 0) {
      return;
    }

    if (
      selectedWorkspace == null ||
      data.context.workspaces.find((x) => x.id === selectedWorkspace.id) == null
    ) {
      setSelectedWorkspace({
        id: data.context.workspaces[0].id,
        name: data.context.workspaces[0].name,
        onboardings: data.context.workspaces[0].onboardings,
      });
    }
  }, [selectedWorkspace, data]);

  return {
    fetching,
    workspaces: data?.context?.workspaces ?? [],
    selectedWorkspace,
    setSelectedWorkspace: doSetSelectedWorkspace,
    refetchWorkspaces: refetch,
  };
};

export type collaboratorType = {
  clientId: number;
  user: { color: string; name: string };
  cursor?: { anchor: number; head: number } | null;
  lastEdit?: number;
};

const HOCUSPOCUS_BASE_URL =
  import.meta.env.VITE_HOCUSPOCUS_BASE_URL ??
  ("hocuspocus-axu5.onrender.com" as const);

export const useCollaborationProvider = (props: { letterId: number }) => {
  const { letterId } = props;

  const provider = useRef<HocuspocusProvider | null>(null);

  const [ready, setReady] = useState(false);

  useEffect(() => {
    if (provider.current != null && provider.current.isConnected) {
      provider.current.disconnect();
      provider.current.destroy();
      setReady(false);
    }

    const ydoc = new Y.Doc();

    ydoc.gc = false;

    const newProvider = new HocuspocusProvider({
      url: `wss://${HOCUSPOCUS_BASE_URL}/collaboration`,
      name: `letter:${letterId}`,
      token: "lighthousehocuspocus",
      document: ydoc,
      connect: true,
      preserveConnection: true,
    });

    newProvider.on("synced", () => setReady(true));

    newProvider.setAwarenessField("clientId", newProvider.document.clientID);
    newProvider.on("synced", () => setReady(true));
    newProvider.on("outgoingMessage", (data: onOutgoingMessageParameters) => {
      if (data.message.type === 0) {
        newProvider.setAwarenessField("lastEdit", new Date().getTime());
      }
    });

    provider.current = newProvider;
  }, [letterId, provider]);

  return { provider: provider.current, ready };
};

export const useRerenderOnEditorChange = (
  editor: Editor | null,
  callback?: () => void
) => {
  const [rerendered, forceRerender] = useState({});

  const doRerender = useCallback(() => {
    forceRerender({});
    callback?.();
  }, [callback]);

  useEffect(() => {
    doRerender();
    editor?.on("transaction", doRerender);

    return () => {
      editor?.off("transaction", doRerender);
    };
  }, [editor, doRerender]);

  return rerendered;
};

export const useRerenderOnYObjectChange = (
  obj?: Y.Map<unknown> | Y.Array<unknown>
) => {
  const [rerendered, forceRerender] = useState({});

  const doRerender = () => {
    forceRerender({});
  };

  useEffect(() => {
    obj?.observeDeep(doRerender);

    return () => obj?.observeDeep(doRerender);
  }, [obj]);

  return rerendered;
};
