import { createFileRoute } from "@tanstack/react-router";
import { SignIn } from "@clerk/clerk-react";
import { Heading } from "@radix-ui/themes";
import { MarkRoundedSquareIcon } from "@/components/icons/markRoundedSquare";

const Login = () => {
  return (
    <div className="flex flex-col w-full h-screen bg-[url(/backgrounds/login.png)] bg-cover bg-center">
      <div className="flex flex-col gap-4 py-6 px-8 w-full">
        <MarkRoundedSquareIcon />
      </div>

      <div className="w-full h-2/3 flex flex-col items-center justify-center gap-2">
        <Heading align="center" weight="regular">
          Empower your global <br /> ambitions with <strong>Lighthouse</strong>
        </Heading>
        <SignIn
          appearance={{
            elements: {
              footer: {
                display: "none",
              },
              card: {
                backgroundColor: "transparent",
                boxShadow: "none",
                paddingTop: "0.25rem",
              },
              formFieldLabel: {
                font: "Inter",
                color: "#666666",
                fontSize: "0.5 rem"
              },
              cardBox: {
                boxShadow: "none",
              },
              header: {
                display: "none",
              },
              form: {
                gap: "1rem",
              },
            },
          }}
        />
      </div>
    </div>
  );
};

export const Route = createFileRoute("/_login/$")({
  component: Login,
});
