import { approveLetterAction, getLetterActionById } from "@/lib/queries";
import {
  EditorContextProvider,
  useEditorContext,
} from "@/providers/editorProvider";
import { createFileRoute, Link } from "@tanstack/react-router";
import { useMutation, useQuery } from "urql";
import { TextEditor } from "@/components/textEditor";
import { useState } from "react";
import { useRerenderOnEditorChange, useUserContext } from "@/lib/hooks";
import { Spinner } from "@radix-ui/themes";
import { Mark } from "@/components/mark";
import { ArrowRightIcon, InfoCircledIcon } from "@radix-ui/react-icons";
import { Modal } from "@/components/modal";
import { Button } from "@/components/button";
import { FaRegCircleCheck, FaSignature } from "react-icons/fa6";
import { FiX } from "react-icons/fi";
import { ResultOf } from "gql.tada";
import { LoadingOverlay } from "@/components/LoadingOverlay";
import { LetterActionNotFoundPage } from "@/components/letterActionNotFoundPage";

const SuccessModal = (props: {
  open: boolean;
  setOpen: (value: boolean) => void;
}) => {
  const { open, setOpen } = props;

  return (
    <Modal
      open={open}
      onOpenChange={setOpen}
      title="Letter Signed!"
      fitContent
      contentClassName="w-[550px] z-5 py-2 px-4"
    >
      <div className="pr-4 pb-1 gap-4 flex flex-col">
        <p className="text-sm text-grey-300">
          Your signature has been successfully added to the letter. The letter
          is now available to the applicant and the Lighthouse team. You can
          close this window.
        </p>
      </div>

      <div className="flex flex-row justify-end">
        <Button
          variant="primary"
          className="disabled:bg-grey-100 disabled:text-grey-400 disabled:shadow-primary-button"
          onClick={() => setOpen(false)}
        >
          Got it
        </Button>
      </div>
    </Modal>
  );
};

export const FailiureModal = (props: {
  open: boolean;
  setOpen: (alue: boolean) => void;
}) => {
  const { open, setOpen } = props;

  return (
    <Modal
      open={open}
      onOpenChange={setOpen}
      title="Oops! Something Went Wrong"
      fitContent
      contentClassName="w-[550px] z-5 py-2 px-4"
    >
      <div className="pr-4 pb-1 gap-4 flex flex-col">
        <p className="text-sm text-grey-300">
          Looks like something went wrong. Please try again.
        </p>
      </div>

      <div className="flex flex-row justify-end">
        <Button
          variant="primary"
          className="disabled:bg-grey-100 disabled:text-grey-400 disabled:shadow-primary-button"
          onClick={() => setOpen(false)}
        >
          Got it
        </Button>
      </div>
    </Modal>
  );
};

export const InformationModal = (props: {
  isBeneficiary: boolean;
  beneficiaryName: string;
  open: boolean;
  setOpen: (value: boolean) => void;
}) => {
  const { open, isBeneficiary, beneficiaryName, setOpen } = props;

  const formattedBeneficiaryName =
    beneficiaryName[beneficiaryName.length - 1] == "s"
      ? beneficiaryName + "'"
      : beneficiaryName + "'s";

  return (
    <Modal
      open={open}
      onOpenChange={setOpen}
      title="Signing a letter"
      fitContent
      contentClassName="w-[650px] max-w-[80%] z-5 py-2 px-4"
    >
      <div className="pr-4 pb-1 gap-4 flex flex-col">
        {!isBeneficiary && (
          <p className="text-sm text-grey-300">
            Your signature is needed regarding this letter in support of{" "}
            {formattedBeneficiaryName} upcoming work visa application.
          </p>
        )}

        <p className="text-sm text-grey-300">
          Once signed, this letter will be submitted to USCIS, the government
          agency responsible for citizenship and immigration.
        </p>

        <p className="text-sm text-grey-200">A few key points about USCIS:</p>

        <div className="flex flex-col gap-3">
          <div className="flex flex-row items-start justify-start gap-2">
            <ArrowRightIcon
              width={30}
              color="#2c2c2c"
              className="flex-shrink-0"
            />

            <span className="text-sm text-grey-200">
              They prefer a grandiose tone in these letters. Although this may
              seem unusual, it is standard practice and enhances the likelihood
              of approval.
            </span>
          </div>

          <div className="flex flex-row items-start gap-2">
            <ArrowRightIcon
              width={30}
              color="#2c2c2c"
              className="flex-shrink-0"
            />

            <span className="text-sm text-grey-200">
              Since USCIS may not be familiar with the specific industry, it is
              crucial to clearly outline the applicant's achievements and
              expertise in related or adjacent fields.
            </span>
          </div>

          <div className="flex flex-row items-start gap-2">
            <ArrowRightIcon
              width={30}
              color="#2c2c2c"
              className="flex-shrink-0"
            />

            <span className="text-sm text-grey-200">
              Processing times can be lengthy and the stakes are high. Prompt
              review is highly recommended.
            </span>
          </div>
        </div>
        <div className="flex flex-row justify-end">
          <Button
            variant="primary"
            className="disabled:bg-grey-100 disabled:text-grey-400 disabled:shadow-primary-button"
            onClick={() => setOpen(false)}
          >
            Got it
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export const ScrollDownPopup = () => {
  const [open, setOpen] = useState(true);

  return (
    open && (
      <div className="flex flex-row items-center bg-grey-200 px-4 py-3 gap-2.5 absolute rounded-lg top-4 shadow-action-button">
        <FaSignature color="#3a87c2" />

        <span className="text-sm text-grey-800">
          Scroll down to find the spot where you can sign the document
        </span>

        <button onClick={() => setOpen(false)}>
          <FiX width={10} className="text-grey-400" />
        </button>
      </div>
    )
  );
};

export const SubmitFailedPopup = (props: {
  onSubmit: () => void;
  letterActionId: number;
  status: string;
  hidden: boolean;
}) => {
  const { onSubmit, letterActionId, status, hidden } = props;

  const { editor } = useEditorContext();

  const [isSigned, setIsSigned] = useState(false);

  const checkForSignature = () => {
    if (editor == null) return setIsSigned(false);

    const signedNode = editor.$node("signature", {
      letterActionId: letterActionId.toString(),
      signed: true,
    });

    setIsSigned(signedNode != null);
  };

  useRerenderOnEditorChange(editor, checkForSignature);

  return (
    !hidden &&
    isSigned &&
    status !== "approved" && (
      <div className="z-10 flex flex-col bg-grey-200 p-4 gap-2.5 absolute rounded-lg bottom-4 right-4 shadow-action-button w-[300px]">
        <div className="flex flex-row items-center gap-1.5">
          <span className="text-sm text-grey-800">Something went wrong!</span>
        </div>

        <span className="text-sm text-grey-500 mb-2">
          There was an issue submitting your signature. Please try again.
        </span>

        <Button onClick={onSubmit} variant="secondary">
          Submit
        </Button>
      </div>
    )
  );
};

const LetterSignatureEditor = (props: {
  data: ResultOf<typeof getLetterActionById>;
}) => {
  const { data } = props;

  const { loggedInUserId, userEntity } = useUserContext();

  const [informationModalOpen, setInformationModalOpen] = useState(
    data?.getLetterAction.status === "sent"
  );
  const [successModalOpen, setSuccessModalOpen] = useState(false);
  const [failureModalOpen, setFailureModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const userName =
    userEntity != null
      ? userEntity.firstName + " " + userEntity.lastName
      : "You";

  const [status, setStatus] = useState<string>(data.getLetterAction.status);

  const approveLetterActionMutation = useMutation(approveLetterAction)[1];

  const approve = async () => {
    setLoading(true);

    const res = await approveLetterActionMutation({
      letterActionId: data.getLetterAction.id,
    });

    setLoading(false);

    if (res.error) {
      setFailureModalOpen(true);
      return;
    }

    setStatus("approved");
    setSuccessModalOpen(true);
  };

  return (
    loggedInUserId != null && (
      <div
        className="relative w-full h-full"
        style={{
          background: "url('/backgrounds/letter-review.png')",
          backgroundBlendMode: "normal",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <InformationModal
          isBeneficiary={data.getLetterAction.recipientType === "beneficiary"}
          beneficiaryName={data.getLetterAction.beneficiaryName}
          open={informationModalOpen}
          setOpen={setInformationModalOpen}
        />
        <SuccessModal open={successModalOpen} setOpen={setSuccessModalOpen} />
        <FailiureModal open={failureModalOpen} setOpen={setFailureModalOpen} />
        <LoadingOverlay isLoading={loading} />

        <div className="w-full h-full absolute bg-grey-800 bg-opacity-25 backdrop-blur-[2px]" />
        <div className="relative w-full h-full flex flex-col items-center overflow-hidden">
          {data?.getLetterAction.letterId != null && (
            <EditorContextProvider
              letterId={data.getLetterAction.letterId}
              userId={loggedInUserId.toString()}
              userName={userName}
              commentUserIdWhitelist={[]}
              mode="sign"
              letterActionId={data.getLetterAction.id}
              onSignatureAdded={approve}
              disableSignatures={status === "approved"}
            >
              {status === "sent" && <ScrollDownPopup />}

              <SubmitFailedPopup
                onSubmit={approve}
                letterActionId={data.getLetterAction.id}
                status={status}
                hidden={loading}
              />

              <div className="w-full flex flex-row items-center justify-between px-6 h-20 mb-3 flex-shrink-0">
                <Link
                  to="/home"
                  as="div"
                  className="flex flex-row gap-[12px] p-[16px] h-[90px] items-center"
                >
                  <Mark />
                  <h1 className="font-serif font-[600] text-base tracking-[-0.5%]">
                    Lighthouse
                  </h1>
                </Link>

                <div className="flex flex-row items-center gap-3">
                  <button
                    onClick={() => setInformationModalOpen(true)}
                    className="bg-grey-200 shadow-action-button h-8 w-8 rounded-full flex items-center justify-center"
                  >
                    <InfoCircledIcon color="white" width={20} />
                  </button>
                </div>
              </div>
              <div className="flex flex-col gap-4 overflow-hidden p-10 pb-0">
                {status === "approved" && (
                  <div className="flex flex-row items-center gap-4 bg-grey-600 shadow-bubble p-4 w-[825px] rounded-lg text-sm text-grey-300">
                    <div className="h-9 w-9 bg-[#D9D9D9] rounded-full flex items-center justify-center text-grey-300 text-lg">
                      <FaRegCircleCheck />
                    </div>

                    <span>
                      You have signed this letter. There is no need for you to
                      take any action.
                    </span>
                  </div>
                )}
                <div className="overflow-y-auto no-scrollbar pb-10">
                  <TextEditor key={data.getLetterAction.letterId} />
                </div>
              </div>
            </EditorContextProvider>
          )}
        </div>
      </div>
    )
  );
};

const LetterSignaturePage = () => {
  const { letterActionId } = Route.useParams();

  const { loggedInUserId } = useUserContext();

  const [{ data, fetching, error }] = useQuery({
    query: getLetterActionById,
    variables: {
      id: isNaN(parseInt(letterActionId)) ? -1 : parseInt(letterActionId),
    },
    requestPolicy: "network-only",
  });

  if (error != null) return <LetterActionNotFoundPage />;

  if (
    data?.getLetterAction.type != null &&
    data.getLetterAction.type !== "sign"
  )
    return (
      <LetterActionNotFoundPage>
        <p className="text-grey-200 font-normal">
          Did you mean to review this letter?{" "}
          <Link
            to="/letter/review/$letterActionId"
            params={{ letterActionId: letterActionId }}
            className="text-blue"
          >
            Review Letter
          </Link>
        </p>
      </LetterActionNotFoundPage>
    );

  if (loggedInUserId == null || fetching || data == null)
    return (
      <div className="flex flex-col items-center text-center h-full justify-center">
        <Spinner />
      </div>
    );

  return <LetterSignatureEditor data={data} />;
};

export const Route = createFileRoute("/_portal/letter/sign/$letterActionId")({
  component: () => <LetterSignaturePage />,
});
