import { useNavigate } from "@tanstack/react-router";
import { Button } from "./button";
import { Mark } from "./mark";

export const LetterActionNotFoundPage = (props: {
  external?: boolean;
  children?: React.ReactNode;
}) => {
  const { external = false, children } = props;

  const nav = useNavigate();

  const handleClick = () => {
    nav({ to: external ? "/" : "/home" });
  };

  return (
    <div
      className="w-screen h-screen flex items-center justify-center fixed top-0 left-0 z-50 opacity-100"
      style={{
        background: "url('/backgrounds/external.png')",
        backgroundBlendMode: "color-burn",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundColor: "#E7E7E8",
      }}
    >
      <div className="absolute top-7 left-7">
        <Mark />
      </div>

      <div className="flex flex-col w-[500px] max-w-[80%] items-start gap-6">
        <h1 className="text-3xl text-grey-200 font-semibold font-serif">
          We couldn't find the letter you were looking for
        </h1>

        {children}

        <p className="text-grey-200 font-normal">
          If you think this is a mistake, please contact us at{" "}
          <a href="mailto:support@lighthousehq.com" className="text-blue">
            support@lighthousehq.com
          </a>
        </p>

        <p className="text-grey-200 font-semibold">
          Thank you for supporting exceptional talent.
        </p>

        <Button variant="primary" onClick={handleClick}>
          Take me back
        </Button>
      </div>
    </div>
  );
};
