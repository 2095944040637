export const WorkExperienceIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_482_6868)">
      <path
        d="M13.5 3.99988H2.5C2.22386 3.99988 2 4.22374 2 4.49988V12.4999C2 12.776 2.22386 12.9999 2.5 12.9999H13.5C13.7761 12.9999 14 12.776 14 12.4999V4.49988C14 4.22374 13.7761 3.99988 13.5 3.99988Z"
        fill="#666666"
        stroke="#CDCFD3"
        strokeWidth="0.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.5 4V3C10.5 2.73478 10.3946 2.48043 10.2071 2.29289C10.0196 2.10536 9.76522 2 9.5 2H6.5C6.23478 2 5.98043 2.10536 5.79289 2.29289C5.60536 2.48043 5.5 2.73478 5.5 3V4"
        stroke="#CDCFD3"
        strokeWidth="0.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14 7.39441C12.1766 8.44941 10.1066 9.00335 8 9.00003C5.89347 9.00339 3.82351 8.44968 2 7.39503"
        stroke="#CDCFD3"
        strokeWidth="0.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7 7H9"
        stroke="#CDCFD3"
        strokeWidth="0.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_482_6868">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
