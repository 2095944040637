import { createFileRoute, redirect, useNavigate } from "@tanstack/react-router";
import { useEffect } from "react";
import { RedirectToSignIn, SignedOut, useAuth } from "@clerk/clerk-react";

const Index = () => {
  const { isSignedIn } = useAuth();
  const nav = useNavigate();

  useEffect(() => {
    if (isSignedIn) {
      nav({ to: "/home" });
    }
  }, [isSignedIn, nav]);

  return (
    <div className="flex flex-row h-screen w-screen items-center justify-center">
      <SignedOut>
        <RedirectToSignIn />
      </SignedOut>
    </div>
  );
};

export const Route = createFileRoute("/")({
  beforeLoad: ({ context }) => {
    const { auth } = context;

    if (auth.isSignedIn) {
      throw redirect({ to: "/home" });
    }
  },
  component: () => <Index />,
});
