export const UploadIcon = () => (
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_388_16928)">
      <path
        d="M13 13.9995H3C2.86739 13.9995 2.74021 13.9468 2.64645 13.8531C2.55268 13.7593 2.5 13.6321 2.5 13.4995V4.99951L3.5 2.99951H12.5L13.5 4.99951V13.4995C13.5 13.6321 13.4473 13.7593 13.3536 13.8531C13.2598 13.9468 13.1326 13.9995 13 13.9995Z"
        stroke="#3285E7"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.5 4.99951H13.5"
        stroke="#3285E7"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 6.99951V11.9995"
        stroke="#3285E7"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6 8.99951L8 6.99951L10 8.99951"
        stroke="#3285E7"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_388_16928">
        <rect
          width="16"
          height="16"
          fill="white"
          transform="translate(0 0.499512)"
        />
      </clipPath>
    </defs>
  </svg>
);
