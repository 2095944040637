import { getOnboarding } from "@/lib/queries";
import { useQuery } from "urql";
import { HighlightedText, LabelText } from "./typography";
import moment from "moment";
import { cn } from "@/lib/cn";
import { Link } from "@tanstack/react-router";
import { ArrowRightIcon, CheckCircledIcon } from "@radix-ui/react-icons";
import { Tooltip, TooltipContent, TooltipTrigger } from "./tooltip";

import { graphql } from "@/lib/graphql";

type LetterActionType = ReturnType<typeof graphql.scalar<"LetterActionType">>;
type LetterActionRecipient = ReturnType<
  typeof graphql.scalar<"LetterActionRecipient">
>;

export const ActionCard = (props: {
  id: number;
  createdAt: string;
  letterName: string;
  status: string;
  type: LetterActionType;
  compact: boolean;
}) => {
  const { id, createdAt, letterName, status, type, compact } = props;

  if (compact)
    return (
      <div className="w-[240px] flex flex-col p-4 rounded-md gap-4 bg-grey-200">
        <div className="flex items-center justify-center w-full h-[90px] bg-grey-300 flex-shrink-0 rounded-sm overflow-hidden">
          <div className="w-[70%] h-full bg-white rounded-sm shadow-lg mt-10 p-3 pt-5 text-[3px] text-wrap break-words">
            ~~~~~~~~
            <br />
            <br />
            ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
            <br />
            ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
            <br />
            <br />
            ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
          </div>
        </div>

        <div className="flex flex-col gap-1">
          <Tooltip>
            <TooltipTrigger className="w-fit">
              <span className="text-grey-600 text-[14px] truncate">
                {letterName}
              </span>
            </TooltipTrigger>
            <TooltipContent className="p-2" side="top">
              {letterName}
            </TooltipContent>
          </Tooltip>

          <span className="text-[12px] text-grey-400 truncate">
            Created {moment(createdAt).fromNow()}
          </span>

          <div className="flex flex-row w-full justify-between mt-2">
            {status === "sent" && (
              <div className="px-3 py-[3px] text-[#81CA67] text-[11px] font-medium bg-[#81CA67] bg-opacity-10 rounded-full ring-1 ring-[#81CA67] ring-opacity-25 shadow-positive-2">
                {type === "reviewApprove" ? "Ready to review" : "Ready to sign"}
              </div>
            )}
            {status === "changes_requested" && (
              <div className="px-3 py-[3px] text-[#caab67] text-[11px] font-medium bg-[#caab67] bg-opacity-10 rounded-full ring-1 ring-[#caab67] ring-opacity-25 shadow-positive-2">
                Changes Requested
              </div>
            )}
            {status === "approved" && (
              <div className="flex flex-row gap-1 items-center px-3 py-[3px] text-[#5da045] text-[11px] font-medium bg-[#5da045] bg-opacity-10 rounded-full ring-1 ring-[#5da045] ring-opacity-25 shadow-positive-2">
                <CheckCircledIcon />{" "}
                <span>{type === "reviewApprove" ? "Approved" : "Signed"}</span>
              </div>
            )}

            <Link
              to={
                type === "reviewApprove"
                  ? "/letter/review/$letterActionId"
                  : "/letter/sign/$letterActionId"
              }
              params={{ letterActionId: id.toString() }}
              className={cn(
                "w-fit bg-grey-700 rounded-full px-2.5 py-1 text-[14px] font-medium text-grey-200 disabled:opacity-60",
                status === "approved" && "bg-grey-600"
              )}
            >
              <ArrowRightIcon className="h-4 w-4" />
            </Link>
          </div>
        </div>
      </div>
    );

  return (
    <div className="flex flex-row items-center p-3 rounded-md gap-4 bg-grey-200 w-full">
      <div className="flex items-center justify-center w-[170px] h-[90px] bg-grey-300 flex-shrink-0 rounded-sm overflow-hidden">
        <div className="w-[70%] h-full bg-white rounded-sm shadow-lg mt-10 p-3 pt-5 text-[3px] text-wrap break-words">
          ~~~~~~~~
          <br />
          <br />
          ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
          <br />
          ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
          <br />
          <br />
          ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
        </div>
      </div>

      <div className="flex flex-col justify-between h-full w-full gap-2">
        <div className="flex flex-col gap-1">
          <div className="flex flex-row items-center">
            <span className="text-[12px] text-grey-400">
              Created {moment(createdAt).fromNow()}
            </span>
            {status === "sent" && (
              <div className="mr-0.5 ml-auto px-3 py-[3px] text-[#81CA67] text-[11px] font-medium bg-[#81CA67] bg-opacity-10 rounded-full ring-1 ring-[#81CA67] ring-opacity-25 shadow-positive-2">
                {type === "reviewApprove" ? "Ready to review" : "Ready to sign"}
              </div>
            )}
            {status === "changes_requested" && (
              <div className="mr-0.5 ml-auto px-3 py-[3px] text-[#caab67] text-[11px] font-medium bg-[#caab67] bg-opacity-10 rounded-full ring-1 ring-[#caab67] ring-opacity-25 shadow-positive-2">
                Changes Requested
              </div>
            )}
            {status === "approved" && (
              <div className="mr-0.5 flex flex-row gap-1 items-center ml-auto px-3 py-[3px] text-[#5da045] text-[11px] font-medium bg-[#5da045] bg-opacity-10 rounded-full ring-1 ring-[#5da045] ring-opacity-25 shadow-positive-2">
                <CheckCircledIcon />{" "}
                <span>{type === "reviewApprove" ? "Approved" : "Signed"}</span>
              </div>
            )}
          </div>

          <span className="text-grey-600 text-[14px]">{letterName}</span>
        </div>

        <Link
          to={
            type === "reviewApprove"
              ? "/letter/review/$letterActionId"
              : "/letter/sign/$letterActionId"
          }
          params={{ letterActionId: id.toString() }}
          className={cn(
            "w-fit bg-grey-700 rounded-[6px] px-2.5 py-1 text-[14px] font-medium text-grey-200 disabled:opacity-60",
            status === "approved" && "bg-grey-600"
          )}
        >
          {status === "sent" && (type === "reviewApprove" ? "Review" : "Sign")}
          {status !== "sent" && "View"}
        </Link>
      </div>
    </div>
  );
};

const letterActionStatusOrder = {
  draft: -1,
  pending: -1,
  sent: 0,
  changes_requested: 1,
  approved: 2,
};

export const LetterActions = (props: {
  onboardingId: number;
  recipient: LetterActionRecipient;
  compact?: boolean;
}) => {
  const { onboardingId, compact = false, recipient } = props;

  const [{ data }] = useQuery({
    query: getOnboarding,
    variables: {
      id: onboardingId,
    },
    requestPolicy: "network-only",
  });

  const letterActions = data?.onboarding.letterActions ?? [];

  const sortedLetterActions = letterActions
    .filter((action) => action.recipientType === recipient)
    .sort(
      (a, b) =>
        letterActionStatusOrder[a.status] - letterActionStatusOrder[b.status]
    );
  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-col gap-1">
        <LabelText className="text-sm text-grey-100" as="span">
          Support Letters
        </LabelText>
        <LabelText className="text-sm text-grey-300 max-w-[600px]" as="span">
          Your application will contain support letter drafts for your review.
          Review, approve, and then send them off for signature by selecting
          each letter below.{" "}
          <HighlightedText className="px-1 py-0.5 rounded-sm">
            Learn more
          </HighlightedText>
        </LabelText>
      </div>

      {sortedLetterActions.length === 0 && (
        <div className="flex flex-col px-1 justify-center items-center">
          <img src="/icons/support-letters.png" />
          <div className="text-sm text-grey-300 mt-2 text-center w-[500px]">
            <span className="text-grey-200">
              Your draft letters will appear here{" "}
            </span>
            once you fill in the necessary information in the Support letters
            step, we'll prepare it for your approval within 3 business days.
          </div>
        </div>
      )}

      <div className={cn("flex flex-row gap-3 mb-2", !compact && "flex-col")}>
        {sortedLetterActions.map((action) => (
          <ActionCard
            key={action.id}
            id={action.id}
            createdAt={action.createdAt}
            letterName={action.letterName}
            status={action.status}
            type={action.type}
            compact={compact}
          />
        ))}
      </div>
    </div>
  );
};
