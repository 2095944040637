import { Button } from "@/components/button";
import { FormModal } from "@/components/modal";
import { create } from "zustand";
import { persist } from "zustand/middleware";

type SupportLettersModalStateType = {
  open: boolean;
  firstShown: boolean;
  toggleFirstShown: () => void;
  setOpen: (open: boolean) => void;
};

export const useSupportLettersModalStore = create(
  persist<SupportLettersModalStateType>(
    (set) => ({
      open: true,
      firstShown: false,
      toggleFirstShown: () =>
        set((state) => ({ firstShown: !state.firstShown })),
      setOpen: (open: boolean) => set(() => ({ open })),
    }),
    {
      name: "lighthouse-support-letters-modal",
    }
  )
);

export const SupportLettersIntroModal = () => {
  const open = useSupportLettersModalStore((state) => state.open);
  const setOpen = useSupportLettersModalStore((state) => state.setOpen);
  const firstShown = useSupportLettersModalStore((state) => state.firstShown);
  const toggleFirstShown = useSupportLettersModalStore(
    (state) => state.toggleFirstShown
  );

  return (
    <FormModal
      open={open || !firstShown}
      onOpenChange={(val) => {
        if (!val) {
          if (!firstShown) {
            toggleFirstShown();
          }
        }
        setOpen(val);
      }}
      title='Intro to "Support letters" step'
    >
      <div className="flex flex-col gap-2 pr-12">
        <p className="text-sm text-grey-300 font-normal">
          [placeholder text] Support letters are recommendations from others
          that highlight your skills and achievements. These letters help
          support your application by focusing on specific areas, like your job
          experience or membership in an organization.
        </p>

        <p className="text-sm text-grey-300 font-normal">
          Text about how we will draft letters for you and what this process
          looks like in general.
        </p>
      </div>
      <div className="w-full flex justify-end pr-8 mt-4">
        <Button
          variant="primary"
          onClick={() => {
            setOpen(false);
            if (!firstShown) {
              toggleFirstShown();
            }
          }}
        >
          Got it
        </Button>
      </div>
    </FormModal>
  );
};
