import { cn } from "@/lib/cn";
import * as TooltipRadix from "@radix-ui/react-tooltip";
import { HintIcon } from "./icons/hint";
import { ButtonHTMLAttributes } from "react";

export const TooltipIcon = (props: ButtonHTMLAttributes<HTMLButtonElement>) => (
  <button
    {...props}
    className="flex flex-row items-center gap-1 rounded-full p-1 hover:bg-grey-700 transition-all duration-500 h-fit w-fit"
  >
    <HintIcon />
  </button>
);

export const TooltipTrigger = TooltipRadix.Trigger;

export const TooltipContent = (props: {
  children: React.ReactNode;
  className?: string;
  hideArrow?: boolean;
  sideOffset?: number;
  side?: "top" | "right" | "bottom" | "left";
}) => {
  return (
    <TooltipRadix.Portal>
      <TooltipRadix.Content
        className={cn(
          "bg-grey-200 text-white w-fit rounded-lg text-xs flex items-center justify-center backdrop-blur-sm",
          "border-1 border-grey-150 border-opacity-[72%]",
          props.className
        )}
        sideOffset={props.sideOffset ?? 5}
        side={props.side ?? "right"}
      >
        {props.children}
        {!props.hideArrow && <TooltipRadix.Arrow className="fill-grey-150" />}
      </TooltipRadix.Content>
    </TooltipRadix.Portal>
  );
};

export const Tooltip = (props: { children: React.ReactNode }) => {
  const { children } = props;

  return (
    <TooltipRadix.Provider delayDuration={400}>
      <TooltipRadix.Root>{children}</TooltipRadix.Root>
    </TooltipRadix.Provider>
  );
};
