import { cn } from "@/lib/cn";
import { ArrowLeftIcon, ArrowRightIcon } from "@radix-ui/react-icons";
import { useRef, useState } from "react";

export const Carousel = (props: { imageUrls: string[] }) => {
  const carouselContainer = useRef<HTMLDivElement>(null);
  const itemsRef = useRef<HTMLImageElement[]>([]);
  const [activeItem, setActiveItem] = useState(0);

  const navigateTo = (index: number) => {
    const item = (index + props.imageUrls.length) % props.imageUrls.length;

    if (itemsRef.current == null) return;
    if (carouselContainer.current == null) return;

    carouselContainer.current.scrollTo({
      left:
        itemsRef.current[item].offsetLeft - itemsRef.current[item].width / 2,
      behavior: "smooth",
    });

    setActiveItem(index);
  };

  const goBack = () => {
    if (activeItem === 0) return;
    navigateTo(activeItem - 1);
  };

  const goForward = () => {
    if (activeItem === props.imageUrls.length - 1) return;
    navigateTo(activeItem + 1);
  };

  return (
    <div className="flex flex-col gap-4 w-full pl-4">
      <div
        className="flex flex-row gap-1 w-full overflow-hidden pr-4"
        ref={carouselContainer}
      >
        {props.imageUrls.map((url, index) => {
          return (
            <img
              key={index}
              ref={(el) => {
                if (el == null) return;
                itemsRef.current[index] = el;
              }}
              src={url}
              className="w-full h-full rounded-lg object-cover w-[210px] h-[147px]"
            />
          );
        })}
      </div>

      <div className="w-full flex flex-row pr-4 pb-4">
        <div className="flex flex-row gap-1 items-center">
          {props.imageUrls.map((_, index) => (
            <button
              key={index}
              className={cn(
                "w-[4px] h-[4px] rounded-full bg-grey-400",
                "transition-all duration-500 ease-in-out",
                activeItem === index && "bg-grey-800 w-[8px]"
              )}
              onClick={() => navigateTo(index)}
              disabled={activeItem === index}
            />
          ))}
        </div>

        <div className="flex flex-row gap-3 items-center ml-auto">
          <button
            className="rounded-full p-1 text-white bg-grey-300 disabled:opacity-[32%]"
            disabled={activeItem === 0}
            onClick={goBack}
          >
            <ArrowLeftIcon className="w-3 h-3" />
          </button>

          <button
            className="rounded-full p-1 text-white bg-grey-300 disabled:opacity-[32%]"
            disabled={activeItem === props.imageUrls.length - 1}
            onClick={goForward}
          >
            <ArrowRightIcon className="w-3 h-3" />
          </button>
        </div>
      </div>
    </div>
  );
};
