import { useState } from "react";
import { DesktopIcon } from "./icons/desktop";
import { MoonIcon } from "./icons/moon";
import { SunIcon } from "./icons/sun";
import { cn } from "@/lib/cn";

export const ThemePicker = () => {
  type Theme = "light" | "dark" | "system";
  const [selectedOption, setSelectedOption] = useState<Theme>("system");

  const options: {
    id: Theme;
    icon: React.ReactNode;
  }[] = [
    {
      id: "light",
      icon: <SunIcon />,
    },
    {
      id: "dark",
      icon: <MoonIcon />,
    },
    {
      id: "system",
      icon: <DesktopIcon />,
    },
  ];

  return (
    <div className="flex flex-row rounded-md items-center">
      {options.map((x) => (
        <div
          className={cn(
            "first:border first:rounded-l-md last:border last:rounded-r-md even:border-y border-grey-600 p-1 bg-grey-700 stroke-grey-400",
            selectedOption === x.id && "bg-white stroke-black"
          )}
          key={x.id}
          onClick={() => setSelectedOption(x.id)}
        >
          {x.icon}
        </div>
      ))}
    </div>
  );
};
