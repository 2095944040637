export const DraftIcon = (props: { className?: string }) => (
  <svg
    width="10"
    height="10"
    viewBox="0 0 10 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={props.className}
  >
    <rect width="10" height="10" rx="5" fillOpacity="0.72" />
    <line
      x1="9.32925"
      y1="1.37629"
      x2="0.576785"
      y2="9.0347"
      stroke="#888888"
    />
    <circle cx="5" cy="5" r="3.5" stroke="#888888" />
  </svg>
);
