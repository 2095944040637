import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { HocuspocusProvider } from "@hocuspocus/provider";
import Collaboration from "@tiptap/extension-collaboration";
import { StarterKit } from "@tiptap/starter-kit";
import Highlight from "@tiptap/extension-highlight";
import Placeholder from "@tiptap/extension-placeholder";
import TextAlign from "@tiptap/extension-text-align";
import ImageResize from "tiptap-extension-resize-image";
import { Editor, Extensions } from "@tiptap/react";
import { useCollaborationProvider } from "@/lib/hooks";
import BubbleMenu from "@tiptap/extension-bubble-menu";
import Underline from "@tiptap/extension-underline";
import { CommentHighlight } from "@/lib/extensions/commentHighlight";
import { Signature } from "@/lib/extensions/signature";

export interface EditorContextType {
  provider: HocuspocusProvider | null;
  editor: Editor | null;
  setEditor: (value: Editor | null) => void;
  extensions: Extensions | null;
  ready: boolean | null;
  commentMarks: string[] | null;
  focusedCommentId: string | null;
  userId: string;
  userName: string;
  mode: "sign" | "review";
  commentUserIdWhitelist: string[];
  handleFocusComment: (id: string | null, scroll?: boolean) => void;
}

const EditorContext = createContext<EditorContextType>({} as EditorContextType);

export const EditorContextProvider = (props: {
  children: React.ReactNode;
  userId: string;
  userName: string;
  commentUserIdWhitelist: string[];
  letterId: number;
  mode: "sign" | "review";
  letterActionId: number;
  onSignatureAdded?: () => void;
  disableSignatures?: boolean;
}) => {
  const {
    children,
    userName,
    userId,
    commentUserIdWhitelist,
    mode,
    letterId,
    letterActionId,
    onSignatureAdded,
    disableSignatures,
  } = props;

  const [focusedCommentId, setFocusedCommentId] = useState<string | null>(null);

  const [commentMarks, setCommentMarks] = useState<string[] | null>(null);

  const [editor, setEditor] = useState<Editor | null>(null);

  const { provider, ready } = useCollaborationProvider({
    letterId,
  });

  const handleFocusComment = useCallback(
    (id: string | null, scroll: boolean = true) => {
      setFocusedCommentId(id);

      const highlightedMarks = document.querySelectorAll(
        ".comment-highlight-focused, .comment-highlight-focused-external"
      );

      highlightedMarks.forEach((mark) => {
        requestAnimationFrame(() => {
          mark.classList.remove("comment-highlight-focused");
          mark.classList.remove("comment-highlight-focused-external");
        });
      });

      if (id == null) return;

      const newMarks = document.querySelectorAll(
        `[data-id='${id}'][data-type='comment-highlight']`
      );

      if (newMarks.length === 0) return;

      const internal = newMarks[0].getAttribute("data-internal") === "true";

      newMarks.forEach((mark) => {
        requestAnimationFrame(() => {
          mark.classList.add(
            internal
              ? "comment-highlight-focused"
              : "comment-highlight-focused-external"
          );
        });
      });

      if (!scroll) return;

      requestAnimationFrame(() => {
        newMarks[0]?.scrollIntoView({ behavior: "smooth" });
      });
    },
    [setFocusedCommentId]
  );

  const extensions = useMemo(() => {
    if (provider === null) return null;
    if (provider.document === null) return null;

    const base: Extensions = [
      Collaboration.configure({
        fragment: provider.document.getXmlFragment("default"),
      }),
      StarterKit.configure({
        history: false,
        dropcursor: {
          color: "#6398de",
          width: 2,
        },
      }),
      TextAlign.configure({
        types: ["heading", "paragraph"],
        defaultAlignment: "justify",
      }),
      Placeholder.configure({
        emptyEditorClass: "is-editor-empty",
        placeholder: "Start typing...",
      }),
      Highlight,
      CommentHighlight.configure({
        provider: provider,
        onMarksChange: setCommentMarks,
        handleFocusComment,
      }),
      Signature.configure({
        disabled: mode === "review" || disableSignatures,
        letterActionId: letterActionId.toString(),
        onSignatureAdded,
      }),
      ImageResize.configure({
        HTMLAttributes: {
          class: "w-full flex justify-center items-center",
        },
      }),
      BubbleMenu,
      Underline,
    ];

    return base;
  }, [provider, handleFocusComment, disableSignatures, mode]);

  return (
    <EditorContext.Provider
      value={{
        provider,
        extensions,
        ready,
        editor,
        setEditor,
        focusedCommentId,
        commentMarks,
        commentUserIdWhitelist,
        userId,
        userName,
        mode,
        handleFocusComment,
      }}
    >
      <React.Fragment key={letterId}>
        <React.Fragment>{children}</React.Fragment>
      </React.Fragment>
    </EditorContext.Provider>
  );
};

export const useEditorContext = () => useContext(EditorContext);
