import { PlusCircledIcon } from "@radix-ui/react-icons";
import { Button } from "./button";

export const RequestNewApplicantButton = () => {
  return (
    <a href="mailto:hello@lighthousehq.com">
      <Button
        variant="secondary"
        className="bg-grey-700 shadow-[0_0_0_1px_#EAEAEB] shadow_[0_1px_2px_0_rgba(17, 17, 17, 0.06)] hover:bg-grey-800"
      >
        <PlusCircledIcon className="w-3 h-3" />
        Request new applicant
      </Button>
    </a>
  );
};
