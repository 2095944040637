import { CompanyInfo, CompanyInfoTabs } from "@/components/companyInfo";
import { CompanySidebarFrame } from "@/components/sidebarFrame";
import { useWorkspaces, useWorkspaceStore } from "@/lib/hooks";
import { getCompanyWorkspace } from "@/lib/queries";
import { CompanyProvider } from "@/providers/companyProvider";
import { createFileRoute, useNavigate } from "@tanstack/react-router";

const CompanyInfoPage = () => {
  const { selectedWorkspace } = useWorkspaces();
  const nav = useNavigate();
  const { tab } = Route.useSearch();

  if (selectedWorkspace?.id.startsWith("user")) {
    nav({ to: "/home", replace: true });
    return;
  }

  return (
    <CompanyProvider key={`company-info-${selectedWorkspace?.id}`}>
      <CompanySidebarFrame>
        <CompanyInfo tab={tab} />
      </CompanySidebarFrame>
    </CompanyProvider>
  );
};

export const Route = createFileRoute("/_portal/company-info/")({
  validateSearch: (
    search: Record<string, unknown>
  ): { tab?: CompanyInfoTabs } => {
    return {
      tab: (search.tab as CompanyInfoTabs | undefined) ?? undefined,
    };
  },
  loader: async ({ context }) => {
    const workspaceId = useWorkspaceStore.getState().selectedWorkspace?.id;
    if (workspaceId == null) return;
    if (!workspaceId.startsWith("company")) return;

    await context.client.query(getCompanyWorkspace, {
      id: parseInt(workspaceId.split("-")[1]),
    });
  },
  component: () => <CompanyInfoPage />,
});
