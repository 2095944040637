export const WarningIcon = () => (
  <svg
    width="13"
    height="12"
    viewBox="0 0 13 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.17478 1.88531L11.2745 9.00422C11.5618 9.50578 11.1906 10.125 10.599 10.125H2.39963C1.80806 10.125 1.43681 9.50578 1.72416 9.00422L5.82385 1.88531C6.11916 1.37156 6.87947 1.37156 7.17478 1.88531Z"
      fill="#EE6262"
      stroke="#EE6262"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
