import React from "react";
import ReactDOM from "react-dom/client";
import { RouterProvider, createRouter } from "@tanstack/react-router";
import { Toaster } from "react-hot-toast";
import {
  ClerkLoaded,
  ClerkLoading,
  ClerkProvider,
  useAuth,
} from "@clerk/clerk-react";
import { Spinner, Theme } from "@radix-ui/themes";
import * as Sentry from "@sentry/react";
import { pdfjs } from "react-pdf";

import "./index.css";

// Import the generated route tree
import { routeTree } from "./routeTree.gen";
import { useAdminFunctions } from "./lib/hooks";
import { GraphqlProvider } from "./providers/graphql";
import { useClient } from "urql";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

const queryClient = new QueryClient();

const PUBLISHABLE_KEY = import.meta.env.VITE_CLERK_PUBLISHABLE_KEY;

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  "pdfjs-dist/build/pdf.worker.min.mjs",
  import.meta.url
).toString();

const isProd =
  import.meta.env.PROD || window.location.hostname === "lighthousehq.com";

if (isProd) {
  Sentry.init({
    dsn: "https://cc04c536066027236f52e4ef1fc0f866@o4507013422776320.ingest.us.sentry.io/4508162381643776",
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

if (!PUBLISHABLE_KEY) {
  throw new Error("Missing CLERK_PUBLISHABLE_KEY");
}

const PageLoader = () => (
  <div className="flex items-center justify-center h-screen w-screen">
    <Spinner />
  </div>
);

const router = createRouter({
  routeTree,
  defaultPendingComponent: () => <PageLoader />,
  defaultErrorComponent: (error) => (
    <div className={`p-2 text-2xl`}>Error: {JSON.stringify(error)}</div>
  ),
  defaultNotFoundComponent: () => <div>404</div>,
  context: {
    auth: undefined!, // This will be set after we wrap the app in an Provider
    client: undefined!, //  This will be set after we wrap the app in an Provider
  },
});

declare module "@tanstack/react-router" {
  interface Register {
    router: typeof router;
  }
}

const InnerApp = () => {
  const auth = useAuth();
  const client = useClient();
  useAdminFunctions();

  return <RouterProvider router={router} context={{ auth, client }} />;
};

// Render the app
const rootElement = document.getElementById("app")!;
if (!rootElement.innerHTML) {
  const root = ReactDOM.createRoot(rootElement);
  root.render(
    <React.StrictMode>
      <Theme
        appearance="light"
        accentColor="gray"
        panelBackground="translucent"
        scaling="95%"
      >
        <ClerkProvider publishableKey={PUBLISHABLE_KEY}>
          <ClerkLoading>
            <PageLoader />
          </ClerkLoading>
          <ClerkLoaded>
            <GraphqlProvider>
              <QueryClientProvider client={queryClient}>
                <InnerApp />
              </QueryClientProvider>
            </GraphqlProvider>
            <Toaster
              position="top-right"
              toastOptions={{
                duration: 5000,
              }}
              containerStyle={{
                marginTop: "10px",
                marginRight: "10px",
              }}
            />
          </ClerkLoaded>
        </ClerkProvider>
      </Theme>
    </React.StrictMode>
  );
}
