import { useCompany } from "@/providers/companyProvider";
import { Button } from "./button";
import { useState } from "react";
import { CompanyInfoIcon } from "./icons/sidebar";
import { cn } from "@/lib/cn";
import { getCompletedFields } from "./sidebar";
import { useNavigate } from "@tanstack/react-router";
import { RequestNewApplicantButton } from "./requestNewApplicant";

const OpenIcon = () => (
  <svg
    width="8"
    height="7"
    viewBox="0 0 8 7"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.43301 5.75C4.24056 6.08333 3.75944 6.08333 3.56699 5.75L0.968912 1.25C0.776461 0.916666 1.01702 0.499999 1.40192 0.499999L6.59808 0.5C6.98298 0.5 7.22354 0.916667 7.03109 1.25L4.43301 5.75Z"
      fill="#2C2C2C"
      stroke="#2C2C2C"
    />
  </svg>
);

const ClosedIcon = () => (
  <svg
    width="7"
    height="9"
    viewBox="0 0 7 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.25 4.06699C6.58333 4.25944 6.58333 4.74056 6.25 4.93301L1.75 7.53109C1.41667 7.72354 1 7.48298 1 7.09808L1 1.90192C1 1.51702 1.41667 1.27646 1.75 1.46891L6.25 4.06699Z"
      fill="#2C2C2C"
      stroke="#2C2C2C"
    />
  </svg>
);

const DropdownRow = (props: {
  children: React.ReactNode;
  noBorder?: boolean;
}) => {
  const { children, noBorder = false } = props;
  return (
    <div
      className={cn(
        "flex flex-row items-center py-4 text-sm text-grey-300",
        !noBorder && "border-b-[1px] border-grey-600"
      )}
    >
      {children}
    </div>
  );
};

const Dropdown = (props: {
  icon: React.ReactNode;
  title: string;
  count?: number;
  children: React.ReactNode;
}) => {
  const { icon, title, children, count } = props;

  const [open, setOpen] = useState(false);

  return (
    <div className="w-full flex flex-col ">
      <div className="flex flex-row items-center gap-3">
        <button onClick={() => setOpen((prev) => !prev)}>
          {open ? <OpenIcon /> : <ClosedIcon />}
        </button>
        {icon}
        <span className="text-sm text-grey-100 font-medium">{title}</span>
        {count != null && (
          <span className="text-sm text-grey-400">{count}</span>
        )}
      </div>

      {open && <div className="flex flex-col pl-6 mt-2">{children}</div>}
    </div>
  );
};

export const CompanyDetailsActions = () => {
  const { data } = useCompany();

  const nav = useNavigate();
  const completedFields = data != null ? getCompletedFields(data) : undefined;

  const pendingFields =
    completedFields != null
      ? completedFields?.files.total +
        completedFields?.entityData.total -
        (completedFields?.files.completed +
          completedFields?.entityData.completed)
      : undefined;

  if (pendingFields === 0) {
    return null;
  }

  return (
    <Dropdown
      icon={<CompanyInfoIcon />}
      title="Complete company details"
      count={pendingFields}
    >
      <DropdownRow>
        Fill in general company information
        <Button
          variant="primary"
          className="ml-auto"
          onClick={() => nav({ to: "/company-info" })}
        >
          Provide Info
        </Button>
      </DropdownRow>
      <DropdownRow>
        Upload company documents
        <Button
          variant="primary"
          className="ml-auto"
          onClick={() =>
            nav({ to: "/company-info", search: { tab: "documents" } })
          }
        >
          Provide Info
        </Button>
      </DropdownRow>
    </Dropdown>
  );
};

export const CompanyTodoList = () => {
  return (
    <div className="w-full p-8 flex flex-col gap-2">
      <div className="flex flex-row items-center">
        <div className="flex flex-col gap-1">
          <h1 className="text-grey-100 text-sm font-semibold">
            Your to-do list
          </h1>
          <h2 className="text-sm text-grey-300">
            Find all the key actions you need to take to speed up visa process
            of your employees.
          </h2>
        </div>

        <div className="ml-auto">
          <RequestNewApplicantButton />
        </div>
      </div>

      <div className="mt-4">
        <CompanyDetailsActions />
      </div>
    </div>
  );
};
