import { CompanyDashboard, Dashboard } from "@/components/dashboard";
import { NoOnboardings } from "@/components/noOnboardings";
import { CompanySidebarFrame, SidebarFrame } from "@/components/sidebarFrame";
import { useWorkspaces } from "@/lib/hooks";
import { getOnboarding, getOnboardingContext } from "@/lib/queries";
import { CompanyProvider } from "@/providers/companyProvider";
import { OnboardingDataProvider } from "@/providers/onboardingDataProvider";
import { Spinner } from "@radix-ui/themes";
import {
  createFileRoute,
  useNavigate,
  useRouter,
} from "@tanstack/react-router";
import { useEffect } from "react";
import { useQuery } from "urql";

const CompanyHome = () => {
  return (
    <CompanyProvider>
      <CompanySidebarFrame>
        <CompanyDashboard />
      </CompanySidebarFrame>
    </CompanyProvider>
  );
};

const UserHome = (props: { onboardingId: number }) => {
  const [{ data }] = useQuery({
    query: getOnboarding,
    variables: { id: props.onboardingId },
    requestPolicy: "cache-and-network",
  });
  const nav = useNavigate();
  const router = useRouter();

  useEffect(() => {
    if (data == null) return;

    for (const group of data.onboarding.groups) {
      if (group.nodes.length === 0) continue;

      const firstNode = group.nodes[0];
      try {
        router.preloadRoute({
          to: "/group/$groupId",
          params: { groupId: group.id },
          search: { nodeId: firstNode.id },
        });
      } catch (e) {
        // console.error(e);
      }
    }
  }, [data, router]);

  if (data == null) {
    return (
      <div className="w-full h-full flex items-center justify-center">
        <Spinner />
      </div>
    );
  }

  const gatedOnboarding = data?.onboarding.groups.find((x) => x.id === "gate");

  if (
    gatedOnboarding?.status !== "submitted_for_review" &&
    (gatedOnboarding?.nodes.filter((x) => !x.completed) ?? []).length > 0
  ) {
    nav({ to: "/group/$groupId", params: { groupId: "gate" } });
    return;
  }

  return (
    <OnboardingDataProvider onboardingId={props.onboardingId}>
      <SidebarFrame>
        <Dashboard onboardingId={props.onboardingId} />
      </SidebarFrame>
    </OnboardingDataProvider>
  );
};

const WorkspaceHome = () => {
  const { selectedWorkspace, fetching } = useWorkspaces();
  const onboardingList = selectedWorkspace?.onboardings ?? [];

  return (
    <div className="w-screen h-screen max-w-screen">
      {selectedWorkspace == null && fetching && (
        <div className="w-full h-full flex items-center justify-center">
          <Spinner />
        </div>
      )}

      {selectedWorkspace != null && (
        <>
          {selectedWorkspace.id.startsWith("company") && <CompanyHome />}

          {selectedWorkspace.id.startsWith("user") && (
            <>
              {(onboardingList.length ?? 0) > 0 &&
                onboardingList[0].id != null && (
                  <UserHome onboardingId={onboardingList[0].id} />
                )}
              {onboardingList.length === 0 && <NoOnboardings />}
            </>
          )}
        </>
      )}
    </div>
  );
};

export const Route = createFileRoute("/_portal/home")({
  preload: true,
  loader: async ({ context }) => {
    context.client.query(getOnboardingContext, {
      contextInput: {},
    });
  },
  component: () => <WorkspaceHome />,
});
