import { ResultOf } from "@/lib/graphql";
import { getCompanyWorkspace } from "@/lib/queries";
import { Button } from "./button";
import { ApplicantStatusBadge } from "./applicantStatusBadge";
import { VisaClassBadge } from "./visaClassBadge";
import { useQuery } from "urql";
import { useState } from "react";
import { useWorkspaces } from "@/lib/hooks";
import { useNavigate } from "@tanstack/react-router";
import { LabelText } from "./typography";
import { Spinner } from "@radix-ui/themes";
import { RequestNewApplicantButton } from "./requestNewApplicant";

const ApplicantRow = (props: {
  idx: number;
  applicant: ResultOf<
    typeof getCompanyWorkspace
  >["getCompanyWorkspace"]["cases"][0];

  onSelect?: (idx?: number) => void;
}) => {
  const { idx, applicant, onSelect } = props;

  return (
    <tr
      key={applicant.id}
      className="grid grid-cols-5 items-center border-b-grey-600 border-b-[0.8px] px-1"
    >
      <td className="px-3 py-2 text-xs text-neutral-700 flex flex-row items-center gap-2">
        <img
          src={`https://avatar.vercel.sh/${applicant.beneficiaryName}`}
          className="w-4 h-4 rounded-full"
        />
        {applicant.beneficiaryName}
      </td>
      <td className="px-3 py-2 text-sm text-neutral-700">
        <VisaClassBadge visaClass={applicant.visaClass} />
      </td>
      <td className="px-3 py-2 text-xs text-neutral-700">
        <ApplicantStatusBadge status={applicant.status} />
      </td>
      <td className="px-3 py-2 text-xs text-neutral-700 flex flex-row items-center gap-1">
        {applicant.petitionerRepresentative?.name}
      </td>
      {applicant.companyOnboarding?.employerDetailsNodeId != null && (
        <td className="px-3 py-2 text-xs text-neutral-700">
          <Button
            variant={
              applicant.status === "pending_documents" ? "primary" : "secondary"
            }
            onClick={() => onSelect?.(idx)}
          >
            {applicant.status === "pending_documents"
              ? "Provide info"
              : "View application"}
          </Button>
        </td>
      )}
    </tr>
  );
};

export const Applicants = () => {
  const [search, setSearch] = useState("");
  const { selectedWorkspace } = useWorkspaces();
  const nav = useNavigate();

  const [{ data, fetching }] = useQuery({
    query: getCompanyWorkspace,
    variables: { id: parseInt(selectedWorkspace?.id.split("-")[1] ?? "-1") },
    pause: selectedWorkspace?.id == null,
    requestPolicy: "cache-and-network",
  });

  const applicants = data?.getCompanyWorkspace.cases ?? [];

  const searchedApplicants = applicants.filter((applicant) => {
    return (
      applicant.beneficiaryName.toLowerCase().includes(search.toLowerCase()) ||
      applicant.visaClass.toLowerCase().includes(search.toLowerCase())
    );
  });

  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-row items-center">
        <div className="flex flex-col gap-1">
          <LabelText className="text-sm text-grey-100" as="span">
            Applicants
          </LabelText>
          <LabelText className="text-sm text-grey-300" as="span">
            Here, you can view all applications for your organization.
          </LabelText>
        </div>
        <div className="ml-auto">
          <RequestNewApplicantButton />
        </div>
      </div>

      <input
        type="text"
        placeholder="Search applicants"
        className="w-[332px] rounded-[4px] py-1.5 pl-3 pr-1 text-xs font-medium bg-grey-800 shadow-border outline-none focus:shadow-border-active"
        value={search}
        onChange={(e) => setSearch(e.target.value)}
      />

      <div className="flex flex-col px-1 justify-center items-center">
        {fetching && <Spinner />}
        {!fetching && searchedApplicants.length === 0 && (
          <div className="w-full text-left text-xs text-grey-300">
            No applicants found
          </div>
        )}
        {!fetching && searchedApplicants.length > 0 && (
          <table className="w-full">
            <thead>
              <tr className="grid grid-cols-5 text-[11px] text-grey-400">
                <th className="px-3 py-2 text-left font-medium">Applicant</th>
                <th className="px-3 py-2 text-left font-medium">Visa Class</th>
                <th className="px-3 py-2 text-left font-medium">
                  Applicant progress
                </th>
                <th className="px-3 py-2 text-left font-medium">
                  Representative
                </th>
              </tr>
            </thead>

            <tbody>
              {searchedApplicants.map((applicant, idx) => (
                <ApplicantRow
                  idx={idx}
                  applicant={applicant}
                  onSelect={() =>
                    nav({
                      to: "/applicants/$caseId",
                      params: { caseId: applicant.id.toString() },
                    })
                  }
                  key={`applicant-${idx}`}
                />
              ))}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};
