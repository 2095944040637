import { OnboardingFrame } from "@/components/onboardingFrame";
import React, { useEffect, useRef, useState } from "react";
import { O1ChatLogs } from "./chat";
import { Button } from "@/components/button";
import { LabelText } from "@/components/typography";
import { useOnboarding } from "@/providers/onboardingProvider";
import { Welcome } from "@/components/welcome";
import {
  isDateRangeValid,
  isNextBlocked,
  O1StartProvider,
  StepData,
  useO1StartContext,
} from "./store";
import { MarkRoundedSquareIcon } from "@/components/icons/markRoundedSquare";
import {
  markOnboardingNodeAsCompleteMutation,
  submitGroupForReview,
} from "@/lib/queries";
import { useMutation } from "urql";
import { useNavigate } from "@tanstack/react-router";

const EnterToContinue = () => (
  <LabelText as="span" className="text-grey-400">
    Press{" "}
    <kbd className="bg-grey-600 text-grey-300 py-0.5 px-1 font-sans rounded-[0.25em]">
      Enter
    </kbd>{" "}
    to continue
  </LabelText>
);

const SpaceToBottom = () => (
  <LabelText as="span" className="text-grey-400">
    Press{" "}
    <kbd className="bg-grey-600 text-grey-300 py-0.5 px-1 font-sans rounded-[0.25em]">
      space
    </kbd>{" "}
    to jump to the latest
  </LabelText>
);

const RespondToContinue = () => (
  <LabelText as="span" className="text-grey-400">
    Answer the questions to continue
  </LabelText>
);

const isFormValidFn = (state: StepData) => {
  const responsiveInvariant =
    (state.canBeResponsive === false &&
      state.bestDates?.startDate != null &&
      state.bestDates.endDate != null &&
      isDateRangeValid(state.bestDates.startDate, state.bestDates.endDate)) ||
    state.canBeResponsive === true;

  const travelInvariant =
    (state.travelWithin2Weeks === true &&
      state.travelDetails != null &&
      state.travelDetails.trim().length > 10) ||
    state.travelWithin2Weeks === false;

  return responsiveInvariant && travelInvariant;
};

export const InnerStep = (props: {
  setProgress: React.Dispatch<React.SetStateAction<number>>;
  next: () => void;
}) => {
  const { setProgress } = props;
  const { currentOnboardingNodeId, onboardingId, refetchOnboarding } =
    useOnboarding();
  const {
    nextMessage,
    messages,
    disableScroll,
    bestDates,
    canBeResponsive,
    travelWithin2Weeks,
    travelDetails,
  } = useO1StartContext();

  const isFormValid = isFormValidFn({
    canBeResponsive,
    bestDates,
    travelWithin2Weeks,
    travelDetails,
  });

  const completeMutaion = useMutation(markOnboardingNodeAsCompleteMutation)[1];
  const submitMutation = useMutation(submitGroupForReview)[1];
  const nav = useNavigate();

  const [isNearBottom, setIsNearBottom] = useState(false);

  const messagesEndRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (messages[messages.length - 1] === "documents") {
      setProgress(100);
      return;
    }
    setProgress((messages.length / 8) * 100);
  }, [messages, setProgress]);

  const doScrollToBottom = async () => {
    await new Promise((resolve) => setTimeout(resolve, 5));

    messagesEndRef.current?.scrollIntoView({
      behavior: "smooth",
      inline: "nearest",
    });
  };

  const doProgress = () => {
    if (messages[messages.length - 1] === "documents") {
      props.next();
      return;
    }

    nextMessage();
    doScrollToBottom();
  };

  const doMarkAsCompleteAndGoToNextStep = async (dashboard?: boolean) => {
    if (currentOnboardingNodeId == null || onboardingId == null) return;
    await completeMutaion({
      onboardingNodeId: currentOnboardingNodeId,
    });
    await submitMutation({
      input: {
        onboardingId: onboardingId,
        group: "gate",
      },
    });

    await refetchOnboarding();

    if (dashboard) {
      nav({ to: "/home" });
      return;
    }
    props.next();
  };

  useEffect(() => {
    const doProgressInEffect = () => {
      if (messages[messages.length - 1] === "documents") {
        doMarkAsCompleteAndGoToNextStep();
        return;
      }

      nextMessage();
      doScrollToBottom();
    };

    const down = (e: KeyboardEvent) => {
      if (e.key === "Enter") {
        e.preventDefault();
        doProgressInEffect();
      }
    };

    document.addEventListener("keydown", down);
    return () => document.removeEventListener("keydown", down);
  }, [messages, nextMessage, props, doMarkAsCompleteAndGoToNextStep]);

  useEffect(() => {
    const down = (e: KeyboardEvent) => {
      if (e.key === " ") {
        if (!isNearBottom && !disableScroll) {
          messagesEndRef.current?.scrollIntoView({
            behavior: "smooth",
            inline: "nearest",
          });
        }
      }
    };

    document.addEventListener("keydown", down);
    return () => document.removeEventListener("keydown", down);
  }, [disableScroll, isNearBottom]);

  useEffect(() => {
    const currentRef = messagesEndRef.current;
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsNearBottom(entry.isIntersecting);
      },
      {
        root: null, // viewport
        rootMargin: "0px", // no margin
        threshold: 0.5, // 50% of target visible
      }
    );

    if (currentRef) {
      observer.observe(currentRef);
    }

    // Clean up the observer
    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, []);

  return (
    <div className="max-h-full min-h-full w-full flex gap-12 flex-col items-center no-scrollbar">
      <div className="overflow-auto mt-auto no-scrollbar">
        <div className="h-full flex flex-row gap-3 no-scrollbar">
          <div className="mt-auto">
            <MarkRoundedSquareIcon />
          </div>
          <div className="mt-auto">
            <div
              className="w-full h-full flex flex-col gap-4 transition-all ease-in duration-300 justify-end"
              ref={containerRef}
            >
              <O1ChatLogs doAddMessage={doProgress} />
            </div>
          </div>
        </div>
        <div ref={messagesEndRef} className="h-[0.5px]" />
      </div>

      {!isNextBlocked(
        {
          canBeResponsive,
          bestDates,
          travelWithin2Weeks,
          travelDetails,
        },
        messages[messages.length - 1]
      ) ? (
        <>
          {messages[messages.length - 1] !== "documents" ? (
            <>{isNearBottom ? <EnterToContinue /> : <SpaceToBottom />}</>
          ) : (
            <div className="flex flex-row gap-2 items-center">
              {isFormValid && (
                <Button
                  variant="secondary"
                  onClick={() => doMarkAsCompleteAndGoToNextStep(true)}
                  disabled={!isFormValid}
                >
                  Go to the dashboard
                </Button>
              )}

              <Button
                variant="primary"
                onClick={doMarkAsCompleteAndGoToNextStep}
                disabled={!isFormValid}
              >
                {!isFormValid
                  ? "Answer all questions to continue"
                  : "Start filling out the form"}
              </Button>
            </div>
          )}
        </>
      ) : (
        <RespondToContinue />
      )}
    </div>
  );
};

export const O1Start = () => {
  const { nextStep, currentOnboardingNodeId, visaClass } = useOnboarding();
  const [page, setPage] = useState<"welcome" | "chat">("welcome");
  const [progress, setProgress] = useState(0);

  return (
    <>
      {page === "welcome" && <Welcome next={() => setPage("chat")} visaClass={visaClass} />}
      {page === "chat" && currentOnboardingNodeId != null && (
        <div
          className="h-full w-full bg-[url('/backgrounds/welcome.png')] bg-cover bg-bottom bg-blend-color-burn"
          style={{
            backgroundColor: "#EAEAEB",
          }}
        >
          <O1StartProvider onboardingNodeId={currentOnboardingNodeId}>
            <OnboardingFrame progress={progress}>
              <InnerStep setProgress={setProgress} next={nextStep} />
            </OnboardingFrame>
          </O1StartProvider>
        </div>
      )}
    </>
  );
};
