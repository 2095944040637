import { createContext, useContext, useState } from "react";
import { getOnboarding, subscribeToOnboardingChange } from "@/lib/queries";
import { useQuery, useSubscription } from "urql";
import { ResultOf } from "gql.tada";
import { calculateProgress, OnboardingProgress } from "@/lib/progress";
import { FormValues } from "@/lib/formValues";
import { redirect, useNavigate } from "@tanstack/react-router";

export type OnboardingNodeGroups = ResultOf<
  typeof getOnboarding
>["onboarding"]["groups"][0]["id"];

export type OnboardingNodes = NonNullable<
  NonNullable<ResultOf<typeof getOnboarding>["onboarding"]["groups"]>
>[0]["nodes"];

type OnboardingContextType = {
  onboardingId: number;
  visaClass: string;
  currentOnboardingNodeId?: number;
  currentOnboardingGroup?: OnboardingNodeGroups;
  nextStep: () => void;
  previousStep: () => void;
  canGoBack: boolean;
  canGoForward: boolean;
  switchGroup: (group: OnboardingNodeGroups) => void;

  allOnboardingGroups: NonNullable<
    NonNullable<ResultOf<typeof getOnboarding>["onboarding"]["groups"]>
  >;
  groupNodes: NonNullable<
    NonNullable<ResultOf<typeof getOnboarding>["onboarding"]["groups"]>
  >[0]["nodes"];
  groupStatus?: NonNullable<
    NonNullable<ResultOf<typeof getOnboarding>["onboarding"]["groups"]>
  >[0]["status"];
  availableGroups: OnboardingNodeGroups[];
  switchNode: (nodeId: number) => void;
  progress: OnboardingProgress;

  rejectedFields: string[];
  currentRejectedFieldIndex?: number;
  setCurrentRejectedFieldIndex: (value: number) => void;

  isCurrentNodeLastInGroup: boolean;
  refetchOnboarding: () => void;
};

const OnboardingContext = createContext<OnboardingContextType | null>(null);

const isThisLastNodeInGroup = (
  selectedNode: {
    group: OnboardingNodeGroups | undefined;
    nodeId: number | undefined;
  },
  data: ResultOf<typeof getOnboarding>
) => {
  if (selectedNode.group == null || selectedNode.nodeId == null) return false;
  if (["gate", "dashboard", "review"].includes(selectedNode.group))
    return false;

  const group = data?.onboarding.groups.find(
    (x) => x.id === selectedNode.group
  );

  if (group == null) return false;
  if (group.nodes.length === 0) return false;

  return group.nodes[group.nodes.length - 1].id === selectedNode.nodeId;
};

export const OnboardingRouteProvider = (props: {
  children: React.ReactNode;
  onboardingId: number;
  currentGroup: OnboardingNodeGroups;
  currentNodeId: number;
}) => {
  const nav = useNavigate();

  const [currentRejectedFieldIndex, setCurrentRejectedFieldIndex] =
    useState<number>();

  const [{ data }, refetch] = useQuery({
    query: getOnboarding,
    variables: { id: props.onboardingId },
  });

  useSubscription(
    {
      query: subscribeToOnboardingChange,
      variables: {
        id: props.onboardingId,
      },
    },
    () => {
      refetch({ requestPolicy: "network-only" });
    }
  );

  const nextStep = () => {
    if (data == null) return;

    const currentGroup = data?.onboarding.groups.find(
      (x) => x.id === props.currentGroup
    );

    const currentGroupIndex = data.onboarding.groups.findIndex(
      (x) => x.id === props.currentGroup
    );

    if (currentGroup == null) return;

    // if current node is the last node in the group, go to the next group
    if (
      currentGroup.nodes[currentGroup.nodes.length - 1].id ===
      props.currentNodeId
    ) {
      if (currentGroupIndex === data.onboarding.groups.length - 1) {
        nav({ to: "/home" });
        return;
      }

      const nextGroup = data.onboarding.groups[currentGroupIndex + 1];
      const firstNodeInNewGroup = nextGroup.nodes[0];

      nav({
        to: `/group/$groupId`,
        params: { groupId: nextGroup.id },
        search: { nodeId: firstNodeInNewGroup.id },
        replace: true,
      });
      return;
    } else {
      const nodes = currentGroup.nodes;

      const currentNodeIndex = nodes.findIndex(
        (node) => node.id === props.currentNodeId
      );

      nav({
        to: `/group/$groupId`,
        params: { groupId: currentGroup.id },
        search: { nodeId: nodes[currentNodeIndex + 1].id },
        replace: true,
      });
    }
  };

  const previousStep = () => {
    if (data == null) return;
    if (props.currentGroup === "gate") {
      return;
    }

    const currentGroupIndex = data.onboarding.groups.findIndex(
      (x) => x.id === props.currentGroup
    );

    if (currentGroupIndex === -1) return;

    const currentGroup = data.onboarding.groups[currentGroupIndex];

    if (currentGroup.nodes[0].id === props.currentNodeId) {
      if (props.currentGroup === "about_you") {
        redirect({ to: "/home" });
        setCurrentRejectedFieldIndex(undefined);
        return;
      }

      const previousGroup = data.onboarding.groups[currentGroupIndex - 1];
      if (previousGroup == null) return;

      const lastNodeInPreviousGroup =
        previousGroup.nodes[previousGroup.nodes.length - 1];

      nav({
        to: `/group/$groupId`,
        params: { groupId: previousGroup.id },
        search: { nodeId: lastNodeInPreviousGroup.id },
        replace: true,
      });
      setCurrentRejectedFieldIndex(undefined);

      return;
    } else {
      const nodes =
        data.onboarding.groups.find((x) => x.id === props.currentGroup)
          ?.nodes ?? [];

      const currentNodeIndex = nodes.findIndex(
        (node) => node.id === props.currentNodeId
      );

      nav({
        to: `/group/$groupId`,
        params: { groupId: currentGroup.id },
        search: { nodeId: nodes[currentNodeIndex - 1].id },
        replace: true,
      });
      setCurrentRejectedFieldIndex(undefined);
    }
  };

  const flatNodes =
    data?.onboarding.groups.flatMap((group) => {
      return group.nodes.map((node) => ({ ...node, group: group.id }));
    }) ?? [];

  const groupNodes =
    data?.onboarding.groups.find((x) => x.id === props.currentGroup)?.nodes ??
    [];

  const currentOnboardingNode = groupNodes.find(
    (node) => node.id === props.currentNodeId
  );

  const rejectedFields = Object.keys(
    currentOnboardingNode?.content ?? {}
  ).filter(
    (field) =>
      (currentOnboardingNode?.content as unknown as FormValues)[field]
        ?.status === "rejected"
  );

  const switchGroup = (group: OnboardingNodeGroups) => {
    if (data == null) return;
    const nodes =
      data.onboarding.groups.find((x) => x.id === group)?.nodes ?? [];

    if (nodes.length === 0) {
      nav({
        to: `/group/$groupId`,
        params: { groupId: group },
        replace: true,
      });
      setCurrentRejectedFieldIndex(undefined);
      return;
    }

    nav({
      to: `/group/$groupId`,
      params: { groupId: group },
      search: { nodeId: nodes[0].id },
      replace: true,
    });
    setCurrentRejectedFieldIndex(undefined);
  };

  const availableGroups =
    data?.onboarding.groups
      .filter((x) => x.nodes.length > 0)
      .map((x) => x.id) ?? [];

  const switchNode = (nodeId: number) => {
    if (data == null) return;

    nav({
      to: `/group/$groupId`,
      params: { groupId: props.currentGroup },
      search: { nodeId: nodeId },
    });
  };

  const canGoBack =
    flatNodes.length > 0 && flatNodes[0].id != props.currentNodeId
      ? true
      : false;

  const canGoForward =
    props.currentGroup === "support_letters" &&
    flatNodes[flatNodes.length - 1].id === props.currentNodeId
      ? true
      : flatNodes.findIndex((node) => node.id === props.currentNodeId) <
          flatNodes.length - 1
        ? true
        : false;

  const progress = calculateProgress(data?.onboarding.groups ?? []);

  const isCurrentNodeLastInGroup =
    props.currentNodeId == null || data == null
      ? false
      : isThisLastNodeInGroup(
          { group: props.currentGroup, nodeId: props.currentNodeId },
          data
        );

  const doChangeCurrentRejectedFieldIndex = (value: number) => {
    setCurrentRejectedFieldIndex(value);
    const selectedRejectedField = rejectedFields[value];

    const divElement = document.getElementById(
      `field-${selectedRejectedField}`
    );

    if (divElement == null) return;
    setTimeout(function () {
      divElement.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
      divElement.focus();
    }, 100);
  };

  return (
    <OnboardingContext.Provider
      value={{
        onboardingId: props.onboardingId,
        visaClass: data?.onboarding.visaClass ?? "",
        canGoBack,
        canGoForward,
        currentOnboardingNodeId: props.currentNodeId,
        currentOnboardingGroup: props.currentGroup,
        allOnboardingGroups: data?.onboarding.groups ?? [],
        groupStatus: data?.onboarding.groups.find(
          (x) => x.id === props.currentGroup
        )?.status,
        nextStep,
        previousStep,
        switchGroup,
        groupNodes,
        switchNode,
        availableGroups,
        progress,

        rejectedFields,
        currentRejectedFieldIndex,
        setCurrentRejectedFieldIndex: doChangeCurrentRejectedFieldIndex,
        isCurrentNodeLastInGroup,
        refetchOnboarding: refetch,
      }}
    >
      {props.children}
    </OnboardingContext.Provider>
  );
};

export const useOnboarding = () => {
  const context = useContext(OnboardingContext);
  if (!context)
    throw new Error("useOnboarding must be used within OnboardingProvider");
  return context;
};
