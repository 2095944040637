import { useEffect, useState } from "react";
import { Mark } from "./mark";
import { cn } from "@/lib/cn";

export const ExternalLandingPage = (props: { hide: () => void }) => {
  const { hide } = props;

  const [transitionStart, setTransitionStart] = useState(false);

  const doHide = () => {
    setTransitionStart(true);

    setTimeout(() => {
      hide();
    }, 1000);
  };

  useEffect(() => {
    const down = (e: KeyboardEvent) => {
      if (e.key === "Enter") {
        e.preventDefault();

        doHide();
      }
    };

    document.addEventListener("keydown", down);

    return () => {
      document.removeEventListener("keydown", down);
    };
  }, [doHide]);

  return (
    <div
      className={cn(
        "w-screen h-screen flex items-center justify-center fixed top-0 left-0 z-50 opacity-100",
        "transition-opacity duration-1000",
        transitionStart && "opacity-0"
      )}
      style={{
        background: "url('/backgrounds/external.png')",
        backgroundBlendMode: "color-burn",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundColor: "#E7E7E8",
      }}
    >
      <div className="absolute top-7 left-7">
        <Mark />
      </div>

      <div className="flex flex-col w-[500px] max-w-[80%] items-start gap-6">
        <h1 className="text-3xl text-grey-200 font-medium font-serif">
          Welcome to <span className="font-semibold">Lighthouse</span>
        </h1>

        <p className="text-grey-200 font-normal">
          We are the fastest immigration solution for technical talent and fast
          growing teams. Our mission is to democratize access to high skilled
          immigration by making visa preparation fast and accessible.
        </p>

        <p className="text-grey-200 font-semibold">
          Thank you for supporting exceptional talent.
        </p>

        <button
          onClick={doHide}
          className="text-xs text-grey-300 font-normal mt-5"
        >
          Press{" "}
          <span className="text-grey-300 bg-grey-500 py-0.5 px-1 rounded">
            Enter
          </span>{" "}
          to continue
        </button>
      </div>
    </div>
  );
};
