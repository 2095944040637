import { Spinner } from "@radix-ui/themes";
import { createPortal } from "react-dom";

export const LoadingOverlay = (props: { isLoading: boolean }) => {
  const { isLoading } = props;

  return createPortal(
    isLoading && (
      <div
        className="fixed w-screen h-screen top-0 left-0 bg-grey-800 bg-opacity-40 flex items-center justify-center text-grey-300"
        style={{ pointerEvents: "all" }}
      >
        <Spinner className="animate-spin w-4 h-4" />
      </div>
    ),
    document.body
  );
};
