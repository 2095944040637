export const TrashIcon = (props: { className?: string }) => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={props.className}
  >
    <path d="M10.2309 2.53846H8.30803V2.15385C8.30769 1.84793 8.18601 1.55465 7.9697 1.33834C7.75338 1.12202 7.4601 1.00035 7.15418 1H4.84649C4.54058 1.00035 4.2473 1.12202 4.03098 1.33834C3.81467 1.55465 3.69299 1.84793 3.69265 2.15385V2.53846H1.76938C1.66737 2.53846 1.56955 2.57898 1.49742 2.65111C1.42529 2.72324 1.38477 2.82107 1.38477 2.92308C1.38477 3.02508 1.42529 3.12291 1.49742 3.19504C1.56955 3.26717 1.66737 3.30769 1.76938 3.30769H2.154V10.2308C2.15423 10.4347 2.23535 10.6302 2.37956 10.7744C2.52376 10.9186 2.71929 10.9998 2.92323 11H9.07707C9.28102 10.9998 9.47654 10.9186 9.62074 10.7744C9.76495 10.6302 9.84607 10.4347 9.8463 10.2308V3.30769H10.2309C10.3329 3.30769 10.4308 3.26717 10.5029 3.19504C10.575 3.12291 10.6155 3.02508 10.6155 2.92308C10.6155 2.82107 10.575 2.72324 10.5029 2.65111C10.4308 2.57898 10.3329 2.53846 10.2309 2.53846ZM4.46188 2.15385C4.46199 2.05188 4.50255 1.95411 4.57466 1.88201C4.64676 1.80991 4.74452 1.76935 4.84649 1.76923H7.15418C7.25616 1.76935 7.35392 1.80991 7.42602 1.88201C7.49812 1.95411 7.53868 2.05188 7.5388 2.15385V2.53846H4.46188V2.15385ZM9.07707 10.2308H2.92323V3.30769H9.07707V10.2308ZM5.23111 5.23077V8.30769C5.23111 8.4097 5.19059 8.50753 5.11846 8.57966C5.04633 8.65179 4.9485 8.69231 4.84649 8.69231C4.74449 8.69231 4.64666 8.65179 4.57453 8.57966C4.5024 8.50753 4.46188 8.4097 4.46188 8.30769V5.23077C4.46188 5.12876 4.5024 5.03093 4.57453 4.95881C4.64666 4.88668 4.74449 4.84615 4.84649 4.84615C4.9485 4.84615 5.04633 4.88668 5.11846 4.95881C5.19059 5.03093 5.23111 5.12876 5.23111 5.23077ZM7.5388 5.23077V8.30769C7.5388 8.4097 7.49828 8.50753 7.42615 8.57966C7.35402 8.65179 7.25619 8.69231 7.15418 8.69231C7.05218 8.69231 6.95435 8.65179 6.88222 8.57966C6.81009 8.50753 6.76957 8.4097 6.76957 8.30769V5.23077C6.76957 5.12876 6.81009 5.03093 6.88222 4.95881C6.95435 4.88668 7.05218 4.84615 7.15418 4.84615C7.25619 4.84615 7.35402 4.88668 7.42615 4.95881C7.49828 5.03093 7.5388 5.12876 7.5388 5.23077Z" />
  </svg>
);
