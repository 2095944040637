import { useOnboardingNodeData } from "@/lib/hooks";
import { Spinner } from "@radix-ui/themes";
import moment from "moment";
import { createContext, useContext, useState } from "react";

export type ChatMessages =
  | "goal"
  | "timeline"
  | "sections"
  | "autosave"
  | "travel-plan"
  | "can-be-responsive"
  | "case-strategy"
  | "documents";

const baseOrder: ChatMessages[] = [
  "goal",
  "timeline",
  "sections",
  "autosave",
  "travel-plan",
  "can-be-responsive",
  "case-strategy",
  "documents",
] as const;

export type StepData = {
  travelWithin2Weeks?: boolean;
  travelDetails?: string;
  canBeResponsive?: boolean;
  bestDates?: {
    startDate?: string;
    endDate?: string;
  };
};

type StepDataActions = {
  setTravelWithin2Weeks: (value: boolean) => void;
  setTravelDetails: (value: string) => void;
  setCanBeResponsive: (value: boolean) => void;

  setBestDates: (value: { startDate?: string; endDate?: string }) => void;
};

type StepState = {
  messages: ChatMessages[];
  disableScroll?: boolean;
};

type StepStateActions = {
  nextMessage: () => void;
  doFilterMessages: (message: ChatMessages) => void;
  setDisableScroll: (value: boolean) => void;
};

const isMissing = (value: unknown) => value === null || value === undefined;

export const isNextBlocked = (data: StepData, currMessage: ChatMessages) => {
  if (currMessage === "travel-plan") {
    if (
      isMissing(data.travelWithin2Weeks) ||
      (data.travelWithin2Weeks === true &&
        (data.travelDetails == null || data.travelDetails.trim().length === 0))
    ) {
      return true;
    }
  }

  if (currMessage === "can-be-responsive") {
    if (data.canBeResponsive === true) {
      return false;
    } else {
      if (
        isMissing(data.bestDates?.startDate) ||
        isMissing(data.bestDates.endDate)
      ) {
        return true;
      }

      if (!isDateRangeValid(data.bestDates.startDate, data.bestDates.endDate)) {
        return true;
      }
    }
  }

  return false;
};

type O1StartState = StepState & StepData & StepStateActions & StepDataActions;

export const O1StartContext = createContext<O1StartState | null>(null);

export const O1StartProvider = (props: {
  children: React.ReactNode;
  onboardingNodeId: number;
}) => {
  const { onboardingNodeId } = props;

  const [messages, setMessages] = useState<ChatMessages[]>(["goal"]);
  const [disableScroll, setDisableScroll] = useState(false);

  const doFilterMessages = (message: ChatMessages) => {
    setMessages((prev) => prev.filter((x) => x !== message));
  };

  const {
    data,
    fetching,
    error,
    doSetData: doChangeData,
  } = useOnboardingNodeData(onboardingNodeId ?? -1);

  const setBestDates = async (value: {
    startDate?: string;
    endDate?: string;
  }) => {
    await doChangeData("bestDates", value);
  };

  const setTravelDetails = async (value: string) => {
    await doChangeData("travelDetails", value);
  };

  const setCanBeResponsive = async (value: boolean) => {
    await doChangeData("canBeResponsive", value);
  };

  const setTravelWithin2Weeks = async (value: boolean) => {
    await doChangeData("travelWithin2Weeks", value);
  };

  const nextBlocked = isNextBlocked(
    {
      travelWithin2Weeks: data.travelWithin2Weeks?.value,
      travelDetails: data.travelDetails?.value,
      bestDates: data.bestDates?.value,
      canBeResponsive: data.canBeResponsive?.value,
    },
    messages[messages.length - 1]
  );

  const nextMessage = () => {
    if (nextBlocked) {
      return;
    }
    setMessages((prev) => [...prev, baseOrder[prev.length]]);
  };

  return (
    <O1StartContext.Provider
      value={{
        bestDates: data.bestDates?.value,
        travelDetails: data.travelDetails?.value,
        canBeResponsive: data.canBeResponsive?.value,
        travelWithin2Weeks: data.travelWithin2Weeks?.value,
        messages,
        disableScroll,
        setBestDates,
        setTravelDetails,
        setCanBeResponsive,
        setTravelWithin2Weeks,
        doFilterMessages,
        setDisableScroll,
        nextMessage,
      }}
    >
      {error != null && data == null && (
        <div className="text-sm text-red-500">
          Error loading onboarding node
        </div>
      )}
      {fetching && data == null && (
        <div className="flex flex-col gap-4 items-center justify-center w-full h-full">
          <Spinner />
        </div>
      )}
      {data != null && props.children}
    </O1StartContext.Provider>
  );
};

export const useO1StartContext = () => {
  const context = useContext(O1StartContext);
  if (!context) throw new Error("Missing O1StartContext.Provider in the tree");
  return context;
};

export const isDateRangeValid = (startDate: string, endDate: string) =>
  moment(endDate).toDate().getTime() - moment(startDate).toDate().getTime() >=
  1000 * 60 * 60 * 24 * 14;
