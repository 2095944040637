export const SunIcon = () => (
  <svg
    width="13"
    height="12"
    viewBox="0 0 13 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_482_6444)">
      <path d="M6.5 1.875V0.75" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M6.5 8.625C7.94975 8.625 9.125 7.44975 9.125 6C9.125 4.55025 7.94975 3.375 6.5 3.375C5.05025 3.375 3.875 4.55025 3.875 6C3.875 7.44975 5.05025 8.625 6.5 8.625Z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M3.5 3L2.75 2.25" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M3.5 9L2.75 9.75" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M9.5 3L10.25 2.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.5 9L10.25 9.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M2.375 6H1.25" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M6.5 10.125V11.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M10.625 6H11.75" strokeLinecap="round" strokeLinejoin="round" />
    </g>
    <defs>
      <clipPath id="clip0_482_6444">
        <rect width="12" height="12" fill="white" transform="translate(0.5)" />
      </clipPath>
    </defs>
  </svg>
);
