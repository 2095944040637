import {
  getPetitionerRepSelectorData,
  updatePetitionerRep,
} from "@/lib/queries";
import { useState } from "react";
import { useMutation, useQuery } from "urql";
import { Modal } from "./modal";
import { RadioSelect } from "./radio";
import { PlusCircledIcon } from "@radix-ui/react-icons";
import { Button } from "./button";

const PetitionerRepSelectorModal = (props: {
  open: boolean;
  setOpen: (value: boolean) => void;
  value?: number;
  options: {
    label: string;
    value: number;
  }[];
  onSelect: (value: number) => void;
}) => {
  const { open, setOpen, options, value, onSelect } = props;

  return (
    <Modal
      open={open}
      onOpenChange={setOpen}
      title="Assign employer representative"
      borderUnderTitle
      fitContent
      modal
    >
      <div className="mt-4 pr-4">
        <RadioSelect
          value={value?.toString()}
          onChange={(x) => onSelect(parseInt(x as string))}
          orientation="vertical"
          options={options.map((option) => ({
            value: option.value.toString(),
            label: option.label,
          }))}
        />

        <div className="w-full flex mt-2">
          <Button
            variant="primary"
            onClick={() => setOpen(false)}
            className="ml-auto"
          >
            Save
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export const PetitionerRepSelector = (props: {
  caseId: number;
  companyId: number;
}) => {
  const { companyId, caseId } = props;

  const [modalOpen, setModalOpen] = useState(false);

  const [{ data }, refetch] = useQuery({
    query: getPetitionerRepSelectorData,
    variables: {
      companyId,
      caseId,
    },
    requestPolicy: "cache-and-network",
  });

  const selectedPetitionerRep = data?.getEmployeeCase.petitionerRepresentative;

  const options =
    data?.getCompanyWorkspace.members.members.map((member) => ({
      label: member.name ?? member.email ?? "",
      value: member.id,
    })) ?? [];

  const mutation = useMutation(updatePetitionerRep)[1];

  const doSelect = (value: number) => {
    mutation({
      input: {
        representativeUserId: value,
        caseId: caseId,
      },
    });
    refetch({ requestPolicy: "network-only" });
  };

  return (
    <div>
      <PetitionerRepSelectorModal
        open={modalOpen}
        setOpen={setModalOpen}
        value={selectedPetitionerRep?.id}
        options={options}
        onSelect={doSelect}
      />

      {selectedPetitionerRep == null ? (
        <button
          className="text-blue text-xs flex flex-row items-center gap-1"
          onClick={() => setModalOpen(true)}
        >
          <PlusCircledIcon className="w-3 h-3 text-blue" />
          Assign employer representative
        </button>
      ) : (
        <button
          className="text-xs flex flex-row items-center gap-1"
          onClick={() => setModalOpen(true)}
        >
          {selectedPetitionerRep.name}
        </button>
      )}
    </div>
  );
};
