export const AboutIcon = () => (
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13 2.99997H3C2.72386 2.99997 2.5 3.22383 2.5 3.49997V13.5C2.5 13.7761 2.72386 14 3 14H13C13.2761 14 13.5 13.7761 13.5 13.5V3.49997C13.5 3.22383 13.2761 2.99997 13 2.99997Z"
      fill="#F2F2F2"
      stroke="#2C2C2C"
      strokeWidth="0.8"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8 10.5C9.38071 10.5 10.5 9.38071 10.5 8C10.5 6.61929 9.38071 5.5 8 5.5C6.61929 5.5 5.5 6.61929 5.5 8C5.5 9.38071 6.61929 10.5 8 10.5Z"
      stroke="#2C2C2C"
      strokeWidth="0.8"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.61133 14C3.83688 13.0045 4.39412 12.1154 5.1916 11.4784C5.98908 10.8414 6.97942 10.4944 8.00008 10.4944C9.02074 10.4944 10.0111 10.8414 10.8086 11.4784C11.606 12.1154 12.1633 13.0045 12.3888 14"
      stroke="#2C2C2C"
      strokeWidth="0.8"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const WorkIcon = () => (
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_350_3935)">
      <path
        d="M13.5 4.49994H2.5C2.22386 4.49994 2 4.7238 2 4.99994V12.9999C2 13.2761 2.22386 13.4999 2.5 13.4999H13.5C13.7761 13.4999 14 13.2761 14 12.9999V4.99994C14 4.7238 13.7761 4.49994 13.5 4.49994Z"
        fill="#F2F2F2"
        stroke="#2C2C2C"
        strokeWidth="0.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.5 4.49997V3.49997C10.5 3.23475 10.3946 2.9804 10.2071 2.79286C10.0196 2.60533 9.76522 2.49997 9.5 2.49997H6.5C6.23478 2.49997 5.98043 2.60533 5.79289 2.79286C5.60536 2.9804 5.5 3.23475 5.5 3.49997V4.49997"
        stroke="#2C2C2C"
        strokeWidth="0.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14 7.89435C12.1766 8.94935 10.1066 9.50329 8 9.49997C5.89347 9.50333 3.82351 8.94962 2 7.89497"
        stroke="#2C2C2C"
        strokeWidth="0.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7 7.49997H9"
        stroke="#2C2C2C"
        strokeWidth="0.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_350_3935">
        <rect
          width="16"
          height="16"
          fill="white"
          transform="translate(0 0.5)"
        />
      </clipPath>
    </defs>
  </svg>
);

export const EmployerDocumentsIcon = () => (
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_350_3952)">
      <path
        d="M12.5 14.5H3.5C3.36739 14.5 3.24021 14.4473 3.14645 14.3536C3.05268 14.2598 3 14.1326 3 14V3C3 2.86739 3.05268 2.74021 3.14645 2.64645C3.24021 2.55268 3.36739 2.5 3.5 2.5H9.5L13 6V14C13 14.1326 12.9473 14.2598 12.8536 14.3536C12.7598 14.4473 12.6326 14.5 12.5 14.5Z"
        fill="#F2F2F2"
        stroke="#2C2C2C"
        strokeWidth="0.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.5 2.5V6H13"
        stroke="#2C2C2C"
        strokeWidth="0.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6 9H10"
        stroke="#2C2C2C"
        strokeWidth="0.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6 11H10"
        stroke="#2C2C2C"
        strokeWidth="0.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_350_3952">
        <rect
          width="16"
          height="16"
          fill="white"
          transform="translate(0 0.5)"
        />
      </clipPath>
    </defs>
  </svg>
);

export const ReviewInformationIcon = () => (
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_350_3969)">
      <path
        d="M11 3H13.5V5.5"
        stroke="#2C2C2C"
        strokeWidth="0.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5 14H2.5V11.5"
        stroke="#2C2C2C"
        strokeWidth="0.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.5 11.5V14H11"
        stroke="#2C2C2C"
        strokeWidth="0.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.5 5.5V3H5"
        stroke="#2C2C2C"
        strokeWidth="0.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11 5.5H5V11.5H11V5.5Z"
        fill="#F2F2F2"
        stroke="#2C2C2C"
        strokeWidth="0.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_350_3969">
        <rect
          width="16"
          height="16"
          fill="white"
          transform="translate(0 0.5)"
        />
      </clipPath>
    </defs>
  </svg>
);

export const AllDocumentsIcon = () => (
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 6C0 2.68629 2.68629 0 6 0H22C25.3137 0 28 2.68629 28 6V22C28 25.3137 25.3137 28 22 28H6C2.68629 28 0 25.3137 0 22V6Z"
      fill="#E7DAF7"
    />
    <path
      d="M8 19.0001V10.0001C8 9.86748 8.05268 9.74031 8.14645 9.64654C8.24021 9.55277 8.36739 9.50009 8.5 9.50009H11.8331C11.9413 9.50009 12.0466 9.53518 12.1331 9.60009L14 11.0001H18.5C18.6326 11.0001 18.7598 11.0528 18.8536 11.1465C18.9473 11.2403 19 11.3675 19 11.5001V13.0001"
      stroke="#5B4C79"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8 19.0003L9.88625 13.3422C9.91944 13.2427 9.98311 13.1561 10.0683 13.0947C10.1534 13.0333 10.2557 13.0003 10.3606 13.0003H20.5C20.5792 13.0003 20.6573 13.0192 20.7279 13.0553C20.7984 13.0914 20.8593 13.1437 20.9056 13.208C20.952 13.2722 20.9824 13.3466 20.9943 13.4249C21.0063 13.5033 20.9994 13.5833 20.9744 13.6585L19.1931 19.0003H8Z"
      stroke="#5B4C79"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const OfflineIcon = () => (
  <svg
    width="12"
    height="13"
    viewBox="0 0 12 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_969_26903)">
      <path
        d="M2.25 2.49707L9.75 10.7471"
        stroke="#E11F1F"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6 10.7471C6.31066 10.7471 6.5625 10.4952 6.5625 10.1846C6.5625 9.87391 6.31066 9.62207 6 9.62207C5.68934 9.62207 5.4375 9.87391 5.4375 10.1846C5.4375 10.4952 5.68934 10.7471 6 10.7471Z"
        fill="#E11F1F"
      />
      <path
        d="M3.35625 3.71582C2.54608 4.01209 1.79174 4.44299 1.125 4.99035"
        stroke="#E11F1F"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.875 4.99036C9.50178 3.86072 7.77815 3.24435 6 3.24708C5.67088 3.24696 5.3421 3.26778 5.01562 3.30942"
        stroke="#E11F1F"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.05219 5.5791C4.16509 5.73462 3.33082 6.10906 2.625 6.66848"
        stroke="#E11F1F"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.37523 6.66918C8.7125 6.14336 7.93582 5.78007 7.10742 5.6084"
        stroke="#E11F1F"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.25672 8.00444C6.74887 7.78714 6.19332 7.70535 5.64439 7.76705C5.09546 7.82875 4.57193 8.03185 4.125 8.35647"
        stroke="#E11F1F"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_969_26903">
        <rect
          width="12"
          height="12"
          fill="white"
          transform="translate(0 0.62207)"
        />
      </clipPath>
    </defs>
  </svg>
);

export const LockIcon = () => (
  <svg
    width="9"
    height="10"
    viewBox="0 0 9 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1780_53823)">
      <rect y="0.5" width="9" height="9" rx="2" fill="#F7F7F7" />
      <path
        d="M7.3125 3.59375H1.6875C1.53217 3.59375 1.40625 3.71967 1.40625 3.875V7.8125C1.40625 7.96783 1.53217 8.09375 1.6875 8.09375H7.3125C7.46783 8.09375 7.59375 7.96783 7.59375 7.8125V3.875C7.59375 3.71967 7.46783 3.59375 7.3125 3.59375Z"
        fill="#2C2C2C"
        stroke="#2C2C2C"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.09375 3.59375V2.46875C3.09375 2.09579 3.24191 1.7381 3.50563 1.47438C3.76935 1.21066 4.12704 1.0625 4.5 1.0625C4.87296 1.0625 5.23065 1.21066 5.49437 1.47438C5.75809 1.7381 5.90625 2.09579 5.90625 2.46875V3.59375"
        stroke="#2C2C2C"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_1780_53823">
        <rect y="0.5" width="9" height="9" rx="2" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const DashboardIcon = () => (
  <div>
    <div className="w-[8px] h-[8px] bg-white absolute translate-x-2.5 items-center justify-center flex rounded-full">
      <div className="w-[4px] h-[4px] bg-negative rounded-full" />
    </div>
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13 9H3C2.72386 9 2.5 9.22386 2.5 9.5V12C2.5 12.2761 2.72386 12.5 3 12.5H13C13.2761 12.5 13.5 12.2761 13.5 12V9.5C13.5 9.22386 13.2761 9 13 9Z"
        stroke="#2C2C2C"
        strokeWidth="0.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13 3.5H3C2.72386 3.5 2.5 3.72386 2.5 4V6.5C2.5 6.77614 2.72386 7 3 7H13C13.2761 7 13.5 6.77614 13.5 6.5V4C13.5 3.72386 13.2761 3.5 13 3.5Z"
        stroke="#2C2C2C"
        strokeWidth="0.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </div>
);

export const SupportLetterIcon = (props: { className?: string }) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={props.className}
  >
    <path
      d="M2 4.5H14V13C14 13.1326 13.9473 13.2598 13.8536 13.3536C13.7598 13.4473 13.6326 13.5 13.5 13.5H2.5C2.36739 13.5 2.24021 13.4473 2.14645 13.3536C2.05268 13.2598 2 13.1326 2 13V4.5Z"
      stroke="#2C2C2C"
      strokeWidth="0.8"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14 4.5L8 10L2 4.5"
      stroke="#2C2C2C"
      strokeWidth="0.8"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.91125 9L2.15625 13.3588"
      stroke="#2C2C2C"
      strokeWidth="0.8"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.8448 13.3588L9.08984 9"
      stroke="#2C2C2C"
      strokeWidth="0.8"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const CompanyInfoIcon = () => (
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.79688 14.3747V3.95285C8.79683 3.87085 8.77452 3.79039 8.73234 3.72006C8.69017 3.64974 8.62969 3.59217 8.55737 3.55351C8.48505 3.51485 8.40359 3.49654 8.32168 3.50054C8.23977 3.50453 8.16048 3.53068 8.09227 3.57619L3.56102 6.59684C3.49886 6.6383 3.44793 6.6945 3.41275 6.76041C3.37757 6.82632 3.35923 6.89992 3.35938 6.97463V14.3747"
      stroke="#2C2C2C"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.79688 7.125H12.875C12.9952 7.125 13.1104 7.17274 13.1954 7.25772C13.2804 7.34269 13.3281 7.45795 13.3281 7.57812V14.375"
      stroke="#2C2C2C"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2 14.375H14.6875"
      stroke="#2C2C2C"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.98438 8.48438V9.39062"
      stroke="#2C2C2C"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.17188 8.48438V9.39062"
      stroke="#2C2C2C"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.17188 11.6562V12.5625"
      stroke="#2C2C2C"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.98438 11.6562V12.5625"
      stroke="#2C2C2C"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
