export const HintIcon = (props: { fill?: string; stroke?: string }) => (
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_d_388_16685)">
      <path
        d="M8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14Z"
        fill={props.fill ?? "#2C2C2C"}
      />
    </g>
    <path
      d="M7.5 7.5C7.63261 7.5 7.75979 7.55268 7.85355 7.64645C7.94732 7.74021 8 7.86739 8 8V10.5C8 10.6326 8.05268 10.7598 8.14645 10.8536C8.24021 10.9473 8.36739 11 8.5 11"
      stroke={props.stroke ?? "#F7F7F7"}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.81816 5.59091C8.11941 5.59091 8.36361 5.3467 8.36361 5.04545C8.36361 4.74421 8.11941 4.5 7.81816 4.5C7.51691 4.5 7.27271 4.74421 7.27271 5.04545C7.27271 5.3467 7.51691 5.59091 7.81816 5.59091Z"
      fill={props.stroke ?? "#F7F7F7"}
    />
    <defs>
      <filter
        id="filter0_d_388_16685"
        x="0"
        y="1"
        width="16"
        height="16"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="1" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_388_16685"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_388_16685"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);
