export const CalendarIcon = () => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_388_16743)">
      <path
        d="M9.75 1.875H2.25C2.04289 1.875 1.875 2.04289 1.875 2.25V9.75C1.875 9.95711 2.04289 10.125 2.25 10.125H9.75C9.95711 10.125 10.125 9.95711 10.125 9.75V2.25C10.125 2.04289 9.95711 1.875 9.75 1.875Z"
        stroke="#666666"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.25 1.125V2.625"
        stroke="#666666"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.75 1.125V2.625"
        stroke="#666666"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.875 4.125H10.125"
        stroke="#666666"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_388_16743">
        <rect width="12" height="12" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
