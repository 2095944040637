import {
  Client,
  fetchExchange,
  gql,
  Provider,
  subscriptionExchange,
} from "urql";
import { retryExchange } from "@urql/exchange-retry";

import { authExchange } from "@urql/exchange-auth";
import { devtoolsExchange } from "@urql/devtools";
import { cacheExchange } from "@urql/exchange-graphcache";
import { requestPolicyExchange } from "@urql/exchange-request-policy";
import { refocusExchange } from "@urql/exchange-refocus";
import * as schema from "@/codegen/schema.json";
import { useMemo } from "react";
import { ClerkLoaded, useAuth } from "@clerk/clerk-react";
import { parseFormValues } from "@/lib/formValues";
import { createClient as createWSClient } from "graphql-ws";

const url =
  import.meta.env.VITE_CUSTOMER_BACKEND_API_URL ??
  "https://customer-backend-prod.onrender.com";
const fetchUrl = `${url}/graphql`;
const wsUrl = fetchUrl.replace("http", "ws");

const isTokenInvalid = (
  token: string | null | undefined,
  isSignedIn: boolean
) => !isSignedIn || token == null || JSON.stringify(token).length === 0;

const cache = cacheExchange({
  schema,
  resolvers: {
    OnboardingNode: {
      content: (parent) => {
        return parseFormValues(parent.content ?? "{}");
      },
    },
  },
  optimistic: {
    updateOnboardingNode(args: Record<string, unknown>) {
      const argsTyped = args as {
        onboardingUpdate: { onboardingNodeId: number; data: string };
      };
      return {
        __typename: "OnboardingNode",
        id: argsTyped.onboardingUpdate.onboardingNodeId,
        content: parseFormValues(argsTyped.onboardingUpdate.data),
      };
    },

    updateCompanyEntityData(args: Record<string, unknown>) {
      const argsTyped = args as {
        data: Record<string, unknown>;
        companyId: number;
      };

      return {
        __typename: "CompanyWorkspace",
        id: argsTyped.companyId,
        entityData: argsTyped.data,
      };
    },

    completeOnboardingNode(args: Record<string, unknown>) {
      const argsTyped = args as {
        onboardingNodeId: number;
      };

      return {
        __typename: "OnboardingNode",
        id: argsTyped.onboardingNodeId,
        completed: true,
      };
    },

    submitGroupForReview(args: Record<string, unknown>, cache) {
      const argsTyped = args as {
        submitGroupForReviewInput: {
          onboardingId: number;
          group: string;
        };
      };

      const fragment = gql`
        fragment _ on Onboarding {
          id
          groups {
            id
            status
          }
        }
      `;

      const readFragment: {
        id: number;
        groups: {
          id: string;
          status: string;
        }[];
      } = cache.readFragment(fragment, {
        id: argsTyped.submitGroupForReviewInput.onboardingId,
      });

      const groupInCacheIndex = readFragment.groups.findIndex(
        (x) => x.id === argsTyped.submitGroupForReviewInput.group
      );

      if (groupInCacheIndex === -1) return readFragment;

      return {
        __typename: "Onboarding",
        id: argsTyped.submitGroupForReviewInput.onboardingId,
        groups: [
          ...readFragment.groups.slice(0, groupInCacheIndex),
          {
            ...readFragment.groups[groupInCacheIndex],
            status: "submitted_for_review",
          },
          ...readFragment.groups.slice(groupInCacheIndex + 1),
        ],
      };
    },

    patchUserEntity(args: Record<string, unknown>, cache) {
      const argsTyped = args as {
        patchUserEntityInput: {
          userId: number;
          data: Record<string, unknown>;
        };
      };

      const fragment = gql`
        fragment _ on UserEntity {
          id
          legalName
          role
          phoneNumber
          firstName
          lastName
        }
      `;

      const readFragment: {
        legalName?: string | null;
        role?: string | null;
        phoneNumber?: string | null;
        firstName?: string | null;
        lastName?: string | null;
      } | null = cache.readFragment(fragment, {
        id: argsTyped.patchUserEntityInput.userId,
      });

      return {
        __typename: "UserEntityMinimal",
        id: argsTyped.patchUserEntityInput.userId,

        ...readFragment,
        ...argsTyped.patchUserEntityInput.data,
      };
    },
  },
});

const InnerGraphqlProvider = ({ children }: { children: React.ReactNode }) => {
  const { getToken, isSignedIn, isLoaded } = useAuth();

  const graphqlClient = useMemo(() => {
    const wsClient = createWSClient({
      url: async () => {
        const token = await getToken();
        if (isTokenInvalid(token, isSignedIn ?? false)) return wsUrl;
        return wsUrl + "?token=" + token;
      },
    });

    return new Client({
      url: fetchUrl,
      exchanges: [
        devtoolsExchange,
        refocusExchange(),
        requestPolicyExchange({}),
        cache,
        retryExchange({
          initialDelayMs: 1000,
          maxDelayMs: 10000,
          randomDelay: true,
          maxNumberAttempts: 3,
          retryIf: (error) => {
            return !!error.networkError || error.response.status === 401;
          },
        }),
        authExchange(async (utils) => {
          let token = await getToken();

          return {
            addAuthToOperation(operation) {
              if (isTokenInvalid(token, isSignedIn ?? false)) {
                return operation;
              }
              return utils.appendHeaders(operation, {
                Authorization: `Bearer ${token}`,
              });
            },
            didAuthError(error) {
              return error.response?.status === 401;
            },
            refreshAuth: async () => {
              token = await getToken({ skipCache: true });
            },
            willAuthError: () => {
              if (!isLoaded) return true;
              if (isTokenInvalid(token, isSignedIn ?? false)) {
                return true;
              }
              return false;
            },
          };
        }),
        subscriptionExchange({
          forwardSubscription(request) {
            const input = { ...request, query: request.query || "" };
            return {
              subscribe(sink) {
                const unsubscribe = wsClient.subscribe(input, sink);
                return { unsubscribe };
              },
            };
          },
        }),
        fetchExchange,
      ],
    });
  }, [getToken, isLoaded, isSignedIn]);

  return <Provider value={graphqlClient}>{children}</Provider>;
};

export const GraphqlProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  return (
    <ClerkLoaded>
      <InnerGraphqlProvider>{children}</InnerGraphqlProvider>
    </ClerkLoaded>
  );
};
