import { Column } from "@/components/column";
import { ArrowRightIcon } from "@radix-ui/react-icons";
import { createFileRoute } from "@tanstack/react-router";

export const Form = () => {
  return (
    <div className="w-full h-full flex flex-col items-center pt-[40px]">
      <Column>
        <div className="rounded-[16px] bg-[#F7F7F766]/40 gap-[24px] py-[24px] flex flex-col w-full px-[48px]">
          <div className="flex flex-col gap-[4px]">
            <h1 className="font-serif font-[600] leading-[36px] text-[24px] tracking-[-0.5%]">
              Your work experience at Sunway
            </h1>
            <h2 className="text-[12px] font-normal text-gray-600">
              Helper Title
            </h2>
          </div>
        </div>

        <div className="w-full rounded-[16px] bg-white bg-opacity-[32%] backdrop-blur-md p-2">
          <div className="flex flex-col w-full bg-white rounded-[8px] pt-[16px] px-[48px] pb-[32px] gap-[32px]">
            <form noValidate>
              <div className="flex flex-col">
                <label className="flex text-[12px] text-[#666666] py-[12px] px-[6px] items-center">
                  What was your role title at Sunway?
                </label>
                <input
                  type="text"
                  className="w-full rounded-[8px] border-[1px] border-gray-200 px-[12px] py-[8px] text-[14px] font-normal text-gray-900"
                  placeholder="Role Title"
                />
              </div>
              <div className="flex flex-col">
                <label className="flex flex-col " htmlFor="website">
                  <span className="text-[12px] text-[#666666] py-[12px] px-[6px]">
                    What's the company website?
                  </span>
                  <input
                    type="text"
                    id="website"
                    className="w-full rounded-[8px] border-[1px] border-gray-200 px-[12px] py-[8px] text-[14px] font-normal text-gray-900 peer"
                    placeholder="lighthousehq.com"
                    pattern="https://.*"
                    required
                  />
                  <span className="mt-1 hidden text-[12px] text-red-500 peer-[&:not(:placeholder-shown):not(:focus):invalid]:block">
                    Please enter a valid website
                  </span>
                </label>
              </div>
              <div className="flex flex-col">
                <label className="flex text-[12px] text-[#666666] py-[12px] px-[6px] items-center gap-[4px]">
                  List 3 important projects you led or
                  <span className="font-[425] text-gray-900">
                    key responsibilities
                    <span className="text-[#0F6BD7]">*</span>
                  </span>
                  you had at this company
                </label>

                <div className="flex flex-col gap-[8px]">
                  <input
                    type="text"
                    className="w-full rounded-[8px] border-[1px] border-gray-200 px-[12px] py-[8px] text-[14px] font-normal text-gray-900"
                    placeholder="Responsibility - 1"
                  />
                  <input
                    type="text"
                    className="w-full rounded-[8px] border-[1px] border-gray-200 px-[12px] py-[8px] text-[14px] font-normal text-gray-900"
                    placeholder="Responsibility - 2"
                  />
                  <input
                    type="text"
                    className="w-full rounded-[8px] border-[1px] border-gray-200 px-[12px] py-[8px] text-[14px] font-normal text-gray-900"
                    placeholder="Responsibility - 3"
                  />
                </div>
              </div>

              <div className="flex flex-row w-full justify-between mt-2">
                <button className="border border-gray-200 rounded-[8px] px-[12px] py-[8px] text-[14px] font-normal text-gray-900">
                  Previous
                </button>

                <button className="flex flex-row items-center rounded-[8px] bg-gray-900 border border-gray-200 px-[12px] py-[8px] text-[14px] text-white gap-[4px] font-normal text-gray-600">
                  Next <ArrowRightIcon />
                </button>
              </div>
            </form>
          </div>
        </div>
      </Column>
    </div>
  );
};

export const Route = createFileRoute("/_portal/form")({
  component: () => <Form />,
});
