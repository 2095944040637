import { Badge, Heading } from "@radix-ui/themes";
import { LabelText } from "./typography";
import { getDashboardData, getOnboarding } from "@/lib/queries";
import { useAdminOverrideUserId, useUserContext } from "@/lib/hooks";
import { useQuery } from "urql";
import { OnboardingNodeGroups } from "@/providers/onboardingProvider";
import { AboutIcon } from "./icons/sidebar";
import { EmployerDocumentsIcon, WorkIcon } from "./icons/sidebar";
import {
  CheckCircledIcon,
  EnvelopeClosedIcon,
  ExclamationTriangleIcon,
} from "@radix-ui/react-icons";
import { Button } from "./button";
import { WarningIcon } from "./icons/warning";
import { DashboardProgressBar } from "./progress";
import { ResultOf } from "gql.tada";
import { InReviewIcon } from "./icons/inReview";
import { useOnboardingData } from "@/providers/onboardingDataProvider";
import { useNavigate } from "@tanstack/react-router";
import { CaseStatusTimeline } from "./caseStatusTimeline";
import { LetterActions } from "./LetterActions";
import { Input } from "./inputs";
import { cn } from "@/lib/cn";

import { CompanyStats } from "./companyStats";
import { CompanyTodoList } from "./companyTodoList";
import { FormActions } from "./formActions";

const timeGreeting = () => {
  const now = new Date();
  const hour = now.getHours();
  if (hour < 12) {
    return "Good Morning";
  } else if (hour < 18) {
    return "Good Afternoon";
  } else {
    return "Good Evening";
  }
};

const options: Partial<{
  [key in OnboardingNodeGroups]: {
    label: string;
    icon: React.ReactNode;
  };
}> = {
  about_you: {
    label: "About You",
    icon: <AboutIcon />,
  },
  work_experience: {
    label: "Your work experience",
    icon: <WorkIcon />,
  },
  support_letters: {
    label: "Support Letters",
    icon: <EnvelopeClosedIcon />,
  },
  employer_documents: {
    label: "Employer Documents",
    icon: <EmployerDocumentsIcon />,
  },
};

const MissingInfoRow = (props: {
  label: string;
  icon: React.ReactNode;
  completed: number;
  total: number;
  rejected: number;
  onClick?: () => void;
  internalStatus?: ResultOf<
    typeof getOnboarding
  >["onboarding"]["groups"][0]["status"];
}) => {
  const { label, icon, completed, total, rejected, onClick, internalStatus } =
    props;

  return (
    <div className="w-full grid grid-cols-4 px-2 py-3 items-center border-b border-b-grey-600">
      <div className="flex flex-row items-center gap-2">
        {icon}
        <span className="text-sm text-neutral-600">{label}</span>
      </div>

      {internalStatus === "submitted_for_review" && (
        <div className="flex flex-row items-center gap-2 text-grey-300 text-[11px]">
          <InReviewIcon />
          Submitted for review
        </div>
      )}

      {internalStatus !== "submitted_for_review" && (
        <>
          <div className="flex flex-row items-center gap-2 text-grey-300 text-[11px]">
            <div className="w-[1.5rem] text-nowrap">
              {completed == 0 ? 0 : Math.round((completed / total) * 100)}%
            </div>
            <div className="w-full flex flex-row items-center gap-2">
              <DashboardProgressBar completed={completed} total={total} />
            </div>
          </div>

          <div className="flex flex-row items-center gap-2">
            {total - completed != 0 && (
              <Badge className="text-md text-grey-200 bg-grey-70 font-normal rounded-[6px]">
                Missing fields
                <span className="text-grey-400">{total - completed}</span>
              </Badge>
            )}
            {rejected > 0 && (
              <Badge className="text-md bg-grey-700 font-normal rounded-[6px] bg-negative/20 text-negative">
                <WarningIcon />
                Rejected fields
                <span className="text-negative/50">{rejected}</span>
              </Badge>
            )}
            {completed === total && rejected === 0 && (
              <div className="flex flex-row items-center gap-1 text-sm">
                <CheckCircledIcon className="text-positive w-[15px] h-[15px] bg-positive/10 rounded-full p-0.5" />
                Complete
              </div>
            )}
          </div>

          <div className="flex justify-end w-full">
            <Button variant="primary" onClick={onClick} className="w-[130px]">
              <div className="w-full flex flex-row items-center justify-between">
                <div className="text-left">Provide Info</div>
                <span className="text-grey-400 text-right">
                  {total - completed + rejected}
                </span>
              </div>
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

const MissingInformation = () => {
  const { progress, allOnboardingGroups } = useOnboardingData();
  const availableOptions = Object.keys(progress).filter(
    (x) => options[x as OnboardingNodeGroups] != null
  );
  const nav = useNavigate();

  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-col gap-1">
        <LabelText className="text-sm text-grey-100" as="span">
          Missing information
        </LabelText>
        <LabelText className="text-sm text-grey-300" as="span">
          In this taskboard, you can view all the fields from the onboarding
          that need to be completed.
        </LabelText>
      </div>

      <div className="flex flex-col px-1">
        {availableOptions.map((option) => {
          const opt = options[option as OnboardingNodeGroups];
          if (opt == null) return;

          const { label, icon } = opt;

          const progressGroup = progress[option as OnboardingNodeGroups];

          if (progressGroup == null) return;

          let totalCompleted = 0;
          let totalRejected = 0;
          let totalFields = 0;

          for (const nodeId in progressGroup.nodeProgress) {
            const nodeProgress = progressGroup.nodeProgress[nodeId];
            totalCompleted += nodeProgress.completedFields;
            totalRejected += nodeProgress.rejectedFieldKeys.length;
            totalFields += nodeProgress.totalFields;
          }

          const internalStatus = allOnboardingGroups.find(
            (x) => x.id === option
          )?.status;

          return (
            <MissingInfoRow
              key={option}
              label={label}
              icon={icon}
              completed={totalCompleted}
              total={totalFields}
              rejected={totalRejected}
              onClick={() => nav({ to: `/group/${option}` })}
              internalStatus={internalStatus}
            />
          );
        })}
      </div>
    </div>
  );
};

const ReviewCase = () => {
  const { caseStatus, visaClass } = useOnboardingData();

  return (
    <div className="w-[482px] rounded-lg bg-grey-200 p-3 gap-4 flex flex-row items-center">
      <div className="min-w-[172px] w-[172px] h-[90px] bg-[#666666] bg-opacity-[56%] rounded-sm flex flex-col items-center justify-center"></div>
      <div className="h-full w-full flex flex-col gap-1">
        <div className="w-full flex flex-row justify-between items-center">
          <div className="text-sm text-grey-400">Shared Jul 14, 3:20pm</div>
          <div className="text-xs rounded-[100px] border border-[#CDCFD3] border-opacity-[6%] bg-[#CDCFD3] bg-opacity-[12%] flex flex-row items-center py-1 px-2 font-[525] text-grey-500 gap-1">
            {caseStatus === "in_final_review"
              ? "Ready for Review"
              : "Submitted"}

            {caseStatus !== "in_final_review" && (
              <CheckCircledIcon className="w-3 h-3 text-grey-300" />
            )}
          </div>
        </div>

        <div className="text-md text-grey-800">
          {visaClass.replaceAll("_", "-").toLocaleUpperCase()} Visa Application
        </div>

        <div className="mt-auto">
          <Button variant="secondary" onClick={() => {}} className="px-2 py-1">
            <span className="font-medium">Review</span>
          </Button>
        </div>
      </div>
    </div>
  );
};

const validateReceiptNotice = (value: string) => {
  if (value.length !== 13) return false;
  const regex = /^(?:[A-Z]{3}|[A-Z]{2})\d{10}$/;
  return regex.test(value);
};

const ReceiptNotice = () => {
  const { receiptNotice } = useOnboardingData();

  const validNumber = validateReceiptNotice(receiptNotice);

  const missingReceiptNotice =
    receiptNotice == null ||
    receiptNotice.startsWith("[UNKNOWN") ||
    receiptNotice.length === 0;

  return (
    <div className="w-full h-full flex justify-center pt-16">
      <div className="w-1/3 flex flex-col gap-4">
        {missingReceiptNotice && (
          <>
            <img src="/icons/receipt-notice.png" className="w-[140px]" />
            <p className="text-grey-300 text-xs">
              USCIS will send the 13-character receipt number to follow your
              visa decision to your employer's email address. Your employer
              should then forward this information to{" "}
              <a
                href="mailto:notices@lighthousehq.com"
                className="font-[525] text-grey-200"
              >
                notices@lighthousehq.com
              </a>{" "}
              <br />
              <br />
              If you have already received the email with your case number, you
              can also forward it to the same email. This way, we can stay up to
              date with your status and keep you informed of any changes.
            </p>
          </>
        )}

        {!missingReceiptNotice && (
          <img src="/icons/pending.png" className="w-[140px]" />
        )}

        <div className="flex flex-col gap-2">
          <LabelText as="span" className="text-xs font-[460]">
            Your 13-character receipt number
          </LabelText>

          <div className="flex flex-row items-center relative">
            <Input
              value={receiptNotice}
              disabled
              placeholder="Forward receipt notice email to notices@lighthousehq.com"
              type="text"
              className="w-full"
            />

            {validNumber && (
              <CheckCircledIcon className="w-4 h-4 text-positive absolute right-3" />
            )}

            {!validNumber && (
              <ExclamationTriangleIcon className="w-4 h-4 text-negative absolute right-3" />
            )}
          </div>
        </div>

        {!missingReceiptNotice && (
          <p className="text-grey-300 text-xs">
            We've received your receipt notice. Here are the details for your
            awareness. From this receipt notice date, USCIS has {""}
            <span className="font-[525] text-grey-200">
              15 business days
            </span>{" "}
            to respond with an outcome. <br />
            <br />
            You can always check your case status at the USCIS online site here
            using your receipt notice number at{" "}
            <a
              href="https://egov.uscis.gov/"
              className="text-blue hover:underline"
            >
              https://egov.uscis.gov
            </a>
          </p>
        )}
      </div>
    </div>
  );
};

const Shipped = () => {
  const { trackingNumber } = useOnboardingData();
  return (
    <div className="w-full h-full flex justify-center pt-16">
      <div className="w-1/3 flex flex-col gap-4">
        <div className="flex w-full items-center justify-center">
          <img src="/icons/shipped.png" className="w-[163px]" />
        </div>

        {trackingNumber != null && (
          <p className="text-grey-300 text-xs text-center">
            Your visa application has been sent to the USCIS office. You can
            track its status on the carrier's website{" "}
            <a
              href={`https://www.fedex.com/wtrk/track/?tracknumbers=${trackingNumber}`}
              className="text-blue hover:underline"
            >
              {trackingNumber}
            </a>
          </p>
        )}

        {trackingNumber == null && (
          <p className="text-grey-300 text-xs text-center">
            Your visa application is enroute to the USCIS office. We will update
            this page when a tracking number is available.
          </p>
        )}
      </div>
    </div>
  );
};

const ReceiptNoticeCompany = () => {
  const { receiptNotice } = useOnboardingData();
  return (
    <div className="w-full h-full flex flex-col justify-center rounded-lg py-6 px-4 bg-grey-200 gap-6">
      <p className="text-grey-500 text-xs">
        USCIS will send a receipt notice to the employer representative's email
        address. Forward this email to{" "}
        <a href="mailto:notices@lighthousehq.com" className="text-[#6893F1]">
          notices@lighthousehq.com
        </a>{" "}
        so we can keep you informed of your status.
      </p>

      <div className="flex flex-col gap-2">
        <LabelText as="span" className="text-xs text-grey-500">
          Your 13-character receipt number
        </LabelText>

        <div className="rounded-md bg-grey-150 bg-opacity-[72%] py-0.5 px-3 h-[36px] text-grey-300 items-center flex text-sm">
          {receiptNotice == null ||
          receiptNotice.trim.length === 0 ||
          receiptNotice.includes("[UNKNOWN")
            ? "Forward receipt notice email to notices@lighthousehq.com"
            : receiptNotice}
        </div>
      </div>
    </div>
  );
};

const Approved = () => {
  const { visaClass } = useOnboardingData();
  const { userEntity } = useUserContext();

  return (
    <div className="w-full h-full bg-[url(/backgrounds/approved.png)] bg-right-bottom bg-no-repeat bg-cover">
      <div className="w-full h-full flex flex-col pt-[10%]">
        <div className="flex flex-col gap-2 items-center">
          <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M15.9993 29.3337C23.3631 29.3337 29.3327 23.3641 29.3327 16.0003C29.3327 8.63653 23.3631 2.66699 15.9993 2.66699C8.63555 2.66699 2.66602 8.63653 2.66602 16.0003C2.66602 23.3641 8.63555 29.3337 15.9993 29.3337Z"
              fill="#DEEDDC"
              stroke="#208011"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M11 17L14 20L21 13"
              stroke="#208011"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>

          <Heading align="center" className="font-[600] text-xl">
            Congratulations {userEntity?.firstName ?? "User"}, your <br />
            {visaClass.replaceAll("_", "-").toLocaleUpperCase()} application has
            been approved!
          </Heading>
          <span className="text-grey-300 text-sm">
            Check your email for next steps.
          </span>
        </div>
      </div>
    </div>
  );
};

const Denied = () => {
  return <div>Case is denied</div>;
};

const RFE = () => {
  return (
    <div className="w-full h-full flex justify-center pt-4 pb-12">
      <div className="w-[482px] flex flex-col gap-4">
        <h1 className="text-grey-200 text-md font-[525]">
          Unfortunately, USCIS has issued a Request for Evidence (RFE)
        </h1>
        <p className="text-xs text-grey-300">
          While this is not the outcome we hoped for, about 1/3 of cases
          nationally get RFEd and we will ensure we prepare a strong response to
          maximize chances of your approval. <br />
          In terms of immediate next steps, we're awaiting USCIS for a digital
          copy of the RFE via fax. <br /> <br />A paper copy will be mailed to
          your mailing address indicated on the I-907 in the next 1-2 weeks. If
          we do not receive the digital faxed copy in the next 24 hours, we'll
          share with you a template email to request a digital copy from USCIS
          which we've experienced is typically faster than the government's
          e-request service. <br /> <br />
          Once we receive a copy, we will review the challenges and share a case
          strategy for the response. We'll immediately get started on preparing
          a robust response. USCIS issues RFEs with up to 3 months to prepare
          and file a response. Depending on the severity of the RFE, I
          anticipate it will take us 1-3 weeks to prepare a response. Once
          submitted, USCIS has the same 15 business day premium processing
          window to revert with a final outcome. <br /> <br />
          For transparency, there are no additional RFE fees associated with
          your case, and in the worst case outcome of a denial, we will review
          and prepare a refiling swiftly. Our goal is to ensure we help you get
          to the finish line.
        </p>
        <ReviewCase />
      </div>
    </div>
  );
};

const DashboardContent = () => {
  const { caseStatus, onboardingId } = useOnboardingData();
  return (
    <div
      className={cn(
        "flex flex-col w-full h-full rounded-b-xl gap-8 px-8",
        caseStatus === "approved" && "p-0"
      )}
    >
      {["pending_documents", "in_progress"].includes(caseStatus ?? "") && (
        <>
          <MissingInformation />
          <LetterActions
            onboardingId={onboardingId}
            recipient="beneficiary"
            compact
          />
          <FormActions onboardingId={onboardingId} />
        </>
      )}

      {caseStatus === "in_final_review" && (
        <>
          <ReviewCase />
          <MissingInformation />
        </>
      )}
      {["submitted", "shipped"].includes(caseStatus ?? "") && (
        <>
          <Shipped />
        </>
      )}

      {["delivered", "in_review_uscis"].includes(caseStatus ?? "") && (
        <>
          <ReceiptNotice />
        </>
      )}

      {caseStatus === "approved" && <Approved />}
      {caseStatus === "denied" && <Denied />}
      {caseStatus === "rfe" && <RFE />}
    </div>
  );
};

export const CompanyApplicantDashboardContent = () => {
  const { caseStatus, onboardingId } = useOnboardingData();
  return (
    <>
      {["pending_documents", "in_progress"].includes(caseStatus ?? "") && (
        <>
          <LetterActions onboardingId={onboardingId} recipient="company" />
          <FormActions onboardingId={onboardingId} />
        </>
      )}

      {caseStatus === "in_final_review" && (
        <>
          <ReviewCase />
        </>
      )}

      {["submitted", "shipped", "delivered", "in_review_uscis"].includes(
        caseStatus ?? ""
      ) && (
        <>
          <ReceiptNoticeCompany />
        </>
      )}

      {/* TODO: Approved / Denied / RFE for Employer */}
      {caseStatus === "approved" && <></>}
      {caseStatus === "denied" && <></>}
      {caseStatus === "rfe" && <></>}
    </>
  );
};

const nicerVisaClassName: Record<string, string> = {
  o_1a_agent: "O-1A Agent",
  o_1a: "O-1A",
  TN: "TN",
  H_1B_TRANSFER: "H-1B Transfer",
  E_3: "E-3 Nonimmigrant",
  EB_1A: "EB-1A",
  EB_2_NIW: "EB-2 Nonimmigrant",
};

export const Dashboard = (props: { onboardingId: number }) => {
  const { visaClass, caseStatus } = useOnboardingData();

  const overrideUserId = useAdminOverrideUserId();
  const [{ data }] = useQuery({
    query: getDashboardData,
    variables: {
      id: props.onboardingId,
      overrideUserId: overrideUserId,
    },
  });

  return (
    <div className="w-full h-full flex flex-col bg-grey-800 rounded-xl gap-6 overflow-y-scroll">
      {caseStatus !== "approved" && (
        <div className="flex flex-col w-full bg-grey-700 rounded-t-xl">
          <div
            className="flex flex-col w-full h-full pt-4 gap-8 pl-8"
            style={{
              background:
                "linear-gradient(267.15deg, rgba(241, 241, 241, 0) 54.03%, #F1F1F1 88.14%), url('/backgrounds/user-dashboard.png')",
              backgroundBlendMode: "normal, color-burn",
              backgroundRepeat: "no-repeat, no-repeat",
              backgroundSize: "cover, cover",
            }}
          >
            <div className="flex flex-col gap-1">
              <Heading className="font-sans font-[500] text-[18px]">
                {timeGreeting()},{" "}
                {data?.context.userEntity?.firstName ?? "User"}
              </Heading>
              <LabelText className="text-sm text-grey-200" as="span">
                Here's an overview of your{" "}
                {nicerVisaClassName[visaClass] ??
                  visaClass.replace("_", "-").toLocaleUpperCase()}{" "}
                visa application.
              </LabelText>
            </div>

            <CaseStatusTimeline currentCaseStatus={caseStatus} scrollable />
          </div>
        </div>
      )}
      <DashboardContent />
    </div>
  );
};

export const CompanyDashboard = () => {
  return (
    <div className="w-full h-full flex flex-col bg-grey-800 rounded-xl overflow-y-scroll">
      <div className="flex flex-col w-full bg-grey-700 rounded-t-xl">
        <div className="flex flex-col w-full h-full py-8 gap-6 pl-8 bg-[url('/backgrounds/employer-dashboard.png')] bg-norepeat bg-cover">
          <div className="flex flex-col gap-2">
            <Heading className="font-[600] text-2xl">{timeGreeting()}</Heading>
            <LabelText className="text-sm text-grey-300" as="span">
              Here's an overview of the visa application progress for all your
              employees.
            </LabelText>
          </div>
        </div>
      </div>

      <div className="flex flex-col h-full rounded-b-xl">
        <CompanyStats />
        <CompanyTodoList />
      </div>
    </div>
  );
};
