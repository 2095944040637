import { CommentYMap } from "@/components/textEditorComments";
import { isEqual } from "@/lib/utils";
import { HocuspocusProvider } from "@hocuspocus/provider";
import { Mark } from "@tiptap/core";
import * as Y from "yjs";

declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    commentHighlight: {
      setCommentHighlight: (attributes: {
        id: string;
        internal: boolean;
      }) => ReturnType;
      setCommentHighlightResolvedById: (
        id: string,
        resolved: boolean
      ) => ReturnType;
      deleteCommentHighlightById: (id: string) => ReturnType;
      setCommentHighlightViewing: (id: string) => ReturnType;
    };
  }
}

type CommentHighlightOptions = {
  preview: boolean;
  provider: HocuspocusProvider;
  onMarksChange: (marks: string[] | null) => void;
  handleFocusComment: (id: string | null, scroll?: boolean) => void;
};

type CommentHighlightStorage = { allMarks: string[] | null };

const getMissingMarks = (a: string[], b: string[]) => {
  const missing = [];

  for (const mark of a) {
    if (!b.includes(mark)) {
      missing.push(mark);
    }
  }

  return missing;
};

export const CommentHighlight = Mark.create<
  CommentHighlightOptions,
  CommentHighlightStorage
>({
  name: "comment-highlight",
  priority: 1000,

  addStorage() {
    return {
      allMarks: null,
    };
  },

  addAttributes() {
    return {
      type: {
        renderHTML: () => {
          return {
            "data-type": this.name,
          };
        },
      },
      color: {
        renderHTML: (attributes) => {
          if (attributes.resolved === "true" || attributes.internal) {
            return {};
          }

          return {
            style: `background-color: #fef2d3`,
          };
        },
      },
      resolved: {
        default: "false",
        parseHTML: (element) =>
          element.getAttribute("data-resolved") ?? "false",
        renderHTML: (attributes) => {
          return {
            "data-resolved": attributes.resolved,
          };
        },
      },
      internal: {
        default: true,
        parseHTML: (element) =>
          element.getAttribute("data-internal") === "true",
        renderHTML: (attributes) => {
          return {
            "data-internal": attributes.internal,
          };
        },
      },
      id: {
        parseHTML: (element) => element.getAttribute("data-id") ?? "",
        renderHTML: (attributes) => {
          return {
            id: `comment-highlight-${attributes.id}`,
            "data-id": attributes.id,
          };
        },
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: "span",
        getAttrs: (element) =>
          element.getAttribute("data-type") === "comment-highlight" && null,
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ["span", HTMLAttributes, 0];
  },

  addCommands() {
    return {
      setCommentHighlight:
        (attributes) =>
        ({ commands }) => {
          return commands.setMark(this.name, attributes);
        },

      deleteCommentHighlightById:
        (id: string) =>
        ({ editor, dispatch, tr }) => {
          if (dispatch == null) return false;

          editor.state.doc.descendants((node, pos) => {
            const { marks } = node;

            marks.forEach((mark) => {
              if (mark.type.name === this.name && mark.attrs.id === id) {
                const transaction = tr.removeMark(
                  pos,
                  pos + node.nodeSize,
                  mark
                );
                dispatch(transaction);
                return true;
              }
            });
          });
          return false;
        },

      setCommentHighlightResolvedById:
        (id: string, resolved: boolean) =>
        ({ editor, dispatch, tr }) => {
          editor.state.doc.descendants((node, pos) => {
            if (dispatch == null) return false;

            const { marks } = node;

            marks.forEach((mark) => {
              if (mark.type.name === this.name && mark.attrs.id === id) {
                const transaction = tr.addMark(
                  pos,
                  pos + node.nodeSize,
                  editor.schema.marks[this.name].create({
                    ...mark.attrs,
                    resolved: resolved ? "true" : "false",
                  })
                );

                dispatch(transaction);

                return true;
              }
            });
          });
          return false;
        },
    };
  },

  onUpdate() {
    if (this.options.preview) return;

    const newMarks: string[] = [];

    this.editor.state.doc.descendants((node) => {
      node.marks.forEach((mark) => {
        if (
          mark.type.name === "comment-highlight" &&
          mark.attrs.resolved === "false"
        ) {
          newMarks.push(mark.attrs.id);
        }
      });
    });

    if (this.storage.allMarks == null) {
      this.storage.allMarks = newMarks;
      this.options.onMarksChange(newMarks);
      return;
    }

    if (isEqual(newMarks, this.storage.allMarks)) return;

    const addedMarks = getMissingMarks(newMarks, this.storage.allMarks);
    const removedMarks = getMissingMarks(this.storage.allMarks, newMarks);

    const comments: Y.Array<CommentYMap> =
      this.options.provider.document.getArray("comments");

    if (addedMarks.length === 0 && removedMarks.length === 0) {
      this.storage.allMarks = newMarks;
      this.options.onMarksChange(newMarks);
      return;
    }

    const marksAddedSuccessfully = [];

    for (let i = 0; i < comments.length; i++) {
      if (addedMarks.includes(comments.get(i).get("id") as string)) {
        comments.get(i).set("resolved", false);
        marksAddedSuccessfully.push(comments.get(i).get("id") as string);
        continue;
      }

      if (removedMarks.includes(comments.get(i).get("id") as string)) {
        comments.get(i).set("resolved", true);
      }
    }

    for (const mark of addedMarks) {
      if (!marksAddedSuccessfully.includes(mark)) {
        newMarks.splice(newMarks.indexOf(mark), 1);
        this.editor.commands.deleteCommentHighlightById(mark);
      }
    }

    this.storage.allMarks = newMarks;
    this.options.onMarksChange(newMarks);
  },

  onSelectionUpdate() {
    if (this.options.preview) return;

    if (!this.editor.isActive("comment-highlight")) {
      this.options.handleFocusComment(null);
      return;
    }

    const comment = this.editor.getAttributes("comment-highlight");
    if (comment.resolved === "true") return;

    this.options.handleFocusComment(comment.id, false);
  },
});
