export const ReviewIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_482_7170)">
      <path
        d="M11 2.5H13.5V5"
        stroke="#CDCFD3"
        strokeWidth="0.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5 13.5H2.5V11"
        stroke="#CDCFD3"
        strokeWidth="0.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.5 11V13.5H11"
        stroke="#CDCFD3"
        strokeWidth="0.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.5 5V2.5H5"
        stroke="#CDCFD3"
        strokeWidth="0.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11 5H5V11H11V5Z"
        fill="#666666"
        stroke="#CDCFD3"
        strokeWidth="0.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_482_7170">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
